
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import { environment } from '@/helpers/Environment'
import {
  apiErrorMessageOrRethrow,
  ExpectedErrors,
  getApiErrorEntity,
  isExpectedApiError,
  reserveTicketsExpectedErrors,
} from '@/errors/helpers'

@Component({
  name: 'EventDetailErrorMessages',
})
export default class extends Vue {
  @Prop()
  error: any

  @Prop()
  expectedErrors: string[]

  apiError: string | null = null
  unexpectedApiError: string | null = null
  apiTemplateError: string | null = null

  get errorContinueUrl(): string {
    const slug = environment.seller?.slug
    return slug ? `/${slug}` : '/'
  }

  @Watch('error', { immediate: true })
  onError(error) {
    this.apiError = null
    this.unexpectedApiError = null
    this.apiTemplateError = null

    if (!error || error.validationError) {
      return
    }

    const result = this.getApiErrorMessage(error, reserveTicketsExpectedErrors(this.expectedErrors))
    if (result) {
      const [type, message] = result
      if (type === 'template') {
        this.apiTemplateError = message
      } else if (type === 'expected') {
        this.apiError = message
      } else if (type === 'unexpected') {
        this.unexpectedApiError = message
      }
    }
  }

  getApiErrorMessage(
    error: any,
    expected: ExpectedErrors,
  ): ['template' | 'expected' | 'unexpected', string] | undefined {
    const message = apiErrorMessageOrRethrow(error, expected)
    const apiError = getApiErrorEntity(error)

    if (this.isTemplateError(apiError)) {
      return ['template', message]
    } else if (apiError && isExpectedApiError(apiError, expected)) {
      return ['expected', message]
    } else {
      return ['unexpected', message]
    }
  }

  /**
   * Only include trusted errors that contain intentional and secure HTML content.
   * API error messages may contain untrusted user content (e.g. email address, admit name)
   */
  isTemplateError(error: ApiErrorEntity | void) {
    // https://github.com/stqry/tix-api/blob/4f69a641a63deb02fe76cbf1fa9bcf92363c2778/cartmod/apply_actions.go#L192
    return error?._context === 'checkout_rules' && error?._code === 'rule_blocks_reserve'
  }
}
