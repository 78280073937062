
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { CalendarMonthData, CalendarPickerCallback, DayData } from './types'
import { TixDate } from '@/TixTime/TixDate'
import { findClosestEnabledDate, scrollButtonHandler } from './helpers'
import ScrollButton from './CalendarScrollButton.vue'
import CalendarMonth from './CalendarMonth.vue'
import TixLegend, { LegendProps } from '@/components/elements/TixLegend.vue'

@Component({
  name: 'CalendarPicker',
  components: { TixLegend, CalendarMonth, ScrollButton },
})
export default class extends Vue {
  @Prop({ required: true })
  onSelect: CalendarPickerCallback

  @Prop({ required: true })
  index: Dict<DayData>

  @Prop({ required: true })
  months: Dict<CalendarMonthData>

  @Prop({ required: true })
  venueTimezone: string

  @Prop({ required: true })
  displayPrices: boolean

  @Prop()
  capacityThreshold: number | false

  // Internal UI state.
  focusedDate: TixDate | null = null
  visibleMonths: Set<string> = new Set()
  enabledButtons = {
    prev: false,
    next: true,
  }

  created(): void {
    this.focusedDate = this.firstAvailableDate ?? null
    window.addEventListener('keydown', this.handleKeydown)
  }

  destroyed() {
    window.removeEventListener('keydown', this.handleKeydown)
  }

  mounted() {
    this.observeScrolling()
    this.scrollToMonth(this.focusedDate as TixDate)
  }

  private observeScrolling(): void {
    const handleScroll: IntersectionObserverCallback = (entries) => {
      for (const entry of entries) {
        const month = entry.target.getAttribute('data-id')
        if (entry.isIntersecting) {
          this.visibleMonths.add(month as string)
        } else {
          this.visibleMonths.delete(month as string)
        }
      }

      this.enabledButtons = {
        prev: !this.visibleMonths.has(this.firstMonth),
        next: !this.visibleMonths.has(this.lastMonth),
      }
    }

    // TODO Update DOM/TypeScript to a version that knows about delay and trackVisibility.
    const $wrapper = this.$refs.calendar as HTMLElement

    const observer = new IntersectionObserver(handleScroll, {
      root: $wrapper,
      rootMargin: '0px',
      threshold: 0.4,
      trackVisibility: true,
      delay: 300,
    } as IntersectionObserverInit)

    for (const monthId of this.monthIds) {
      observer.observe($wrapper.querySelector(`.picker-month[data-id="${monthId}"]`) as Element)
    }
  }

  handleScrollButtonPress(offset: number): void {
    scrollButtonHandler(this.visibleMonths, offset)
  }

  private handleKeydown(e: KeyboardEvent): void {
    const offset = this.arrowKeyOffset(e.code)
    if (offset) {
      e.preventDefault()
      const closest = findClosestEnabledDate(this.index, this.focusedDate!, offset)
      if (closest) {
        this.focusedDate = closest
      }
      // Scroll to the focussed date anyway, to bring it back into the viewport.
      this.scrollToMonth(this.focusedDate!)
    } else if (e.code === 'Enter') {
      e.preventDefault()
      this.onSelect(this.index[this.focusedDate!.date])
    }
  }

  private arrowKeyOffset(keyCode: KeyboardEvent['code']): number | undefined {
    const offsets = {
      ArrowUp: -7,
      ArrowRight: +1,
      ArrowDown: +7,
      ArrowLeft: -1,
    }
    return offsets[keyCode]
  }

  private async scrollToMonth(date: TixDate): Promise<void> {
    const calendarEl = this.$refs.calendar as HTMLElement
    const key = date.format('YYYY-MM')
    const month = calendarEl.querySelector(`.picker-month[data-id="${key}"]`)!

    this.$nextTick(() => {
      month.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      })
    })
  }

  get legendProps(): LegendProps {
    return {
      annotations: this.days.map((day) => day.annotations).flat(),
      areAnySoldOut: this.days.some((date) => date.status === 'sold_out'),
      arePricesDisplayed: this.displayPrices,
      hasPriceIncrease: false,
    }
  }

  get isSingleMonth(): boolean {
    return this.monthIds.length === 1
  }

  private get firstAvailableDate(): TixDate | void {
    return this.days.find((day) => day.enabled)?.date
  }

  private get days(): DayData[] {
    return Object.values(this.index)
  }

  private get monthIds(): string[] {
    return Object.keys(this.months)
  }

  private get firstMonth(): string {
    return this.monthIds[0]
  }

  private get lastMonth(): string {
    // Clone the array first; Vue does not protect against mutating the shared-result of computed properties.
    return [...this.monthIds].pop()!
  }
}
