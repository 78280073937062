import rawData from '@/data/country-region-data'
import type { Country } from 'country-region-data'

const data: Array<Country & { _lowerCode: string }> = rawData

const lowerCodes = new Set(data.map((d) => d._lowerCode))

export const countryOptions: StringOption[] = data.map(({ countryName, _lowerCode }) => ({
  label: countryName,
  value: _lowerCode,
}))

export const regionLabelKeys: Dict<'province'> = {
  ca: 'province',
}

export const regions = getRegions()

function getRegions(): Dict<StringOption[]> {
  const result = {}

  for (const country of data) {
    if (country.regions.length > 0) {
      result[country._lowerCode] = country.regions.map((region) => ({
        value: region.shortCode,
        label: region.name,
      }))
    }
  }

  return result
}

const countryAliases = {
  'united states': 'us',
  usa: 'us',
  canada: 'ca',
}

export function formatCountry(country?: string): string | undefined {
  if (country) {
    const lower = country.toLowerCase()
    if (lowerCodes.has(lower)) {
      return lower
    } else {
      return countryAliases[lower]
    }
  }
}
