
import { fetchEventDetails } from '@/api/Events'
import { getMembershipRules, MembershipRules } from '@/api/Membership'
import MobileFooterPortal from '@/components/cart/MobileFooterPortal.vue'
import ReserveMobileFooter from '@/components/cart/ReserveMobileFooter.vue'
import FloatingNavigateBack from '@/components/elements/FloatingNavigateBack.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { openDeleteCartOrCheckoutDialog } from '@/components/membership/DeleteCartOrCheckoutDialog.vue'
import MembershipForm from '@/components/membership/MembershipForm.vue'
import { apiErrorMessageOrRethrow, getApiErrorEntity } from '@/errors/helpers'
import { configYml, environment } from '@/helpers/Environment'
import { coverImageUrl, imageData } from '@/helpers/ImageHelpers'
import { indexItemsById } from '@/helpers/IndexHelpers'
import { fetchCartMod } from '@/helpers/PromoCodes'
import { getSummary } from '@/helpers/StringHelpers'
import type { LanguageStrings } from '@/language/types'
import { addMembershipToCart } from '@/state/Membership'
import store from '@/store/store'
import { Component, Vue, Watch } from 'vue-property-decorator'
import type { RawLocation, Route } from 'vue-router'
import { openDescriptionModal } from '@/modals/descriptionModal'
import { TixTime } from '@/TixTime/TixTime'
import type { DiscountedTG, EventDetails } from '@/api/types/processedEntities'
import type { ReserveTicketPayload } from '@/api/types/payloads'
import { sellerConfig } from '@/helpers/Config'

@Component({
  name: 'RedeemMembershipRoute',
  components: {
    MobileFooterPortal,
    ReserveMobileFooter,
    FloatingNavigateBack,
    MembershipForm,
    FormInput2,
  },
})
export default class extends Vue {
  // `event` is the membership event that has ticket groups matching the gift code.
  event: EventDetails | null = null
  // TODO Allow the Redeem GoM event to have its own image.
  // `defaultMembershipEvent` is the portal's default membership event which we use for getting an image to display.
  defaultMembershipEvent: EventDetails | null = null

  giftCode: string | null = null
  giftCodeMethod: 'query' | 'input' | null = null
  groups: DiscountedTG[] | null = null
  rules: MembershipRules | null = null

  fetchingGiftCode = false
  submitting = false
  loading = true
  autoRenew: boolean = false

  selectedLevel: string | null = null
  surveyAnswers: Dict<Primitive> = {}
  details: Dict<MembershipAdmitDetails[]> = {}
  countsByTTID: Dict<number> = {}

  noAvailableMemberships = false
  // Plain text error messages.
  reserveError: string | null = null
  codeError: string | null = null

  contexts = new Set(['redeem-gift-of-membership'])

  readonly formId = 'redeem-membership-form'

  t: LanguageStrings['redeemMembershipRoute']

  beforeRouteEnter(to: Route, from: Route, next: Function) {
    const eventID = sellerConfig('membership_event_id')
    if (eventID) {
      next()
    } else {
      return next({ name: 'membership' })
    }
  }

  created() {
    getMembershipRules().then((result) => {
      this.rules = result
    })

    const giftCode = this.$route.query['gift_code']
    if (giftCode) {
      if (giftCode.length === 10) {
        this.giftCode = giftCode
        this.fetchGiftCode().then(() => {
          if (!this.codeError) {
            this.giftCodeMethod = 'query'
          }
        })
      } else {
        this.codeError = 'You must enter a valid code'
      }
    }

    // TODO Show a image specific to Redeem Membership.
    // Only for showing the event image.
    const eventID = sellerConfig('membership_event_id')
    if (eventID) {
      fetchEventDetails(eventID)
        .then((event) => {
          this.defaultMembershipEvent = event
          this.loading = false
        })
        .catch((e) => apiErrorMessageOrRethrow(e, 'not_found'))
        .finally(() => (this.loading = false))
    } else {
      this.loading = false
    }

    const membership = store.getters['Cart/cartItems'].find((item) => item.isMembershipEvent)
    if (membership) {
      openDeleteCartOrCheckoutDialog({
        title: 'Redeem Membership',
        description: 'You need to take action on your current cart before you can redeem a membership.',
      })
    }
  }

  submitGiftCode() {
    this.fetchGiftCode().then(() => {
      if (!this.codeError) {
        this.giftCodeMethod = 'input'
      }
    })
  }

  fetchGiftCode(): Promise<void> {
    this.fetchingGiftCode = true

    return fetchCartMod(this.giftCode!, true)
      .then(this.handleGiftCodeResponse)
      .catch((error: Error) => {
        const apiError = getApiErrorEntity(error)
        if (apiError?._code === 'not_found') {
          this.codeError = 'You must enter a valid code'
        } else {
          this.codeError = apiErrorMessageOrRethrow(error)
        }
      })
      .finally(() => (this.fetchingGiftCode = false))
  }

  handleGiftCodeResponse(response: ApiResponse<'ticket_type' | 'code_lookup'>) {
    const ticketTypes = response.ticket_type._data

    if (this.isExpired(response.code_lookup)) {
      this.codeError = 'This code has expired'
    } else if (this.isExhausted(response.code_lookup)) {
      this.codeError = 'This code has already been redeemed'
    } else {
      const membershipTTs = ticketTypes.filter((type) => type.event_template_category === 'Membership')

      this.fetchEvent(membershipTTs).then((event) => {
        if (event) {
          this.event = event
          this.setDiscountedTicketGroups(event, membershipTTs)

          this.groups = (this.groups ?? []).map((group) => {
            return {
              ...group,
              summary: group.meta.redeem_gom_summary ? group.meta.redeem_gom_summary : group.summary,
              description: group.meta.redeem_gom_description ? group.meta.redeem_gom_description : group.description,
            }
          })
        }
      })
    }
  }

  isExpired(response: ApiResponseItem<CodeLookup>) {
    const codeLookup = response._data[0]
    const currentTime = new TixTime()
    const beforeStart = codeLookup.valid_from && !currentTime.isAfter(codeLookup.valid_from)
    const afterEnd = codeLookup.valid_to && !currentTime.isBefore(codeLookup.valid_to)
    return beforeStart || afterEnd
  }

  isExhausted(response: ApiResponseItem<CodeLookup>) {
    const codeLookup = response._data[0]
    return codeLookup.exhausted
  }

  fetchEvent(ticketTypes: TicketType[]): Promise<EventDetails | void> {
    const eventIds = new Set(ticketTypes.map((type) => type.event_template_id))
    if (eventIds.size === 1) {
      return fetchEventDetails(eventIds.keys().next().value)
    } else if (eventIds.size > 1 && window.tix.web?.membership_event_id) {
      return fetchEventDetails(window.tix.web.membership_event_id)
    } else {
      this.noAvailableMemberships = true
      return Promise.resolve()
    }
  }

  setDiscountedTicketGroups(event: EventDetails, membershipTicketTypes: TicketType[]) {
    const ticketTypesById: Dict<TicketType> = indexItemsById(membershipTicketTypes)

    this.groups = event.ticketGroups
      .filter((group) => ticketTypesById[group.types[0].id])
      .sort((a, b) => {
        const aType = ticketTypesById[a.types[0].id]
        const bType = ticketTypesById[b.types[0].id]
        if (aType.discounted_amount === bType.discounted_amount) {
          return Number(bType.currency_amount) - Number(aType.currency_amount)
        } else {
          return Number(aType.discounted_amount) - Number(bType.discounted_amount)
        }
      })
      // Only show groups of equal or higher rank to the main gifted membership.
      .filter((group, _, groups) => group._rank >= groups[0]._rank)
      // Only show the gifted membership and 2 upgrade options.
      .slice(0, 3)
      .map((group) => {
        return {
          ...group,
          price: Number(ticketTypesById[group.types[0].id].discounted_amount),
          originalPrice: Number(ticketTypesById[group.types[0].id].currency_amount),
        }
      })
    this.selectedLevel = this.groups[0].id
  }

  onSubmit(tickets: ReserveTicketPayload[]) {
    return Promise.resolve()
      .then(() => {
        return addMembershipToCart(
          this.event!,
          this.rules!,
          this.selectedLevel!,
          [this.giftCode],
          tickets,
          this.surveyAnswers,
          this.autoRenew,
        )
      })
      .then(() => {
        this.$store.commit('Cart/redeemMembership', true)
        this.$router.push(this.nextRoute)
      })
      .catch((error) => {
        if (error.validationError) {
          // Ignore validation errors and leave the promise resolved.
        } else {
          // API error messages do not include HTML.
          this.reserveError = apiErrorMessageOrRethrow(error, 'rule_blocks_reserve:checkout_rules')
        }
      })
      .finally(() => {
        this.submitting = false
      })
  }

  get nextRoute(): RawLocation {
    return environment.web.upsells ? '/events' : '/checkout'
  }

  get image() {
    return imageData(this.defaultMembershipEvent!, coverImageUrl)
  }

  get titleInsideContentColumn() {
    return configYml.titleInsideContentColumn
  }

  get title() {
    return this.languageStrings.title
  }

  get description(): string {
    return this.languageStrings.description
  }

  get summary(): string {
    return getSummary(this.description) ?? ''
  }

  get languageStrings():
    | LanguageStrings['redeemMembershipRoute']['input']
    | LanguageStrings['redeemMembershipRoute']['queryParam'] {
    return this.giftCodeMethod === 'query' ? this.t.queryParam : this.t.input
  }

  get reserveButtonLabels() {
    const language = this.$t('payButton') as LanguageStrings['payButton']
    return {
      desktop: language.desktop.proceedButtonLabel,
      mobile: language.mobile.proceedButtonLabel,
    }
  }

  @Watch('giftCode')
  onGiftCodeChange() {
    this.codeError = null
  }

  openDescriptionModal() {
    openDescriptionModal(this.autoP(this.description!))
  }
}
