import type { FreeCheckoutPayload, GiftCardPayload, PaymentPayload, StripePayload } from '@/api/types/payloads'
import type { PaymentTokens } from '@/checkout/helpers/processing'
import { isWalletPayment } from '@/checkout/helpers/wallets'
import type { PaymentMethodName } from '@/checkout/stripe/helpers'
import { defaultLedgerType, environment } from '@/helpers/Environment'
import type { PendingGiftCardPayment } from '@/store/Cart'

/**
 * Generates a list of API payment payloads to be used with payment and checkout endpoints;
 *
 * The API's payment and checkout endpoints include `cart/:id/pay`, `cart/:id/checkout` and variations for
 *   - my/%
 *   - %/guest_checkout
 *   - %/gift_checkout
 *
 * There are three different types of payloads, for payments by Stripe, gift card, and free carts.
 *
 * At least one payment payload is always returned. Currently the only combinations that may be returned are;
 *
 *   - 1 free checkout/payment payload.
 *   - 1 Stripe payment payload.
 *   - N gift card payment payloads.
 *   - N gift card payment payloads followed by a single Stripe payment payload.
 *
 * More combinations may be possible if/when;
 *   - support for splitting payment across multiple cards is added,
 *   - all payments are processed separately from checkout (i.e. the last item is always a free checkout payload.
 */
export function getPaymentPayloads(
  giftCardPayments: PendingGiftCardPayment[],
  stripeCharge: number,
  saveAndRenew: boolean,
  tokens: PaymentTokens,
  selectedPaymentType: PaymentMethodName,
): PaymentPayload[] {
  // Process all gift card payments before credit card.
  // Refunding gift card payments are simpler (all internal) if credit card payment fails.
  const result: PaymentPayload[] = giftCardPayments.filter((card) => card.paymentAmount > 0).map(giftCardPayment)

  if (stripeCharge > 0) {
    result.push(stripePayment(stripeCharge, tokens.stripe!, selectedPaymentType, saveAndRenew))
  }

  // Always return at least one payment. Checkout API requires one.
  if (result.length < 1) {
    result.push(freePayment())
  }

  if (tokens.captcha) {
    const last = result[result.length - 1]
    last.captcha = {
      provider: 'hcaptcha',
      token: tokens.captcha,
    }
  }

  return result
}

function giftCardPayment(card: PendingGiftCardPayment): GiftCardPayload {
  return {
    amount: card.paymentAmount.toFixed(2),
    gateway_id: 'ledger',
    gateway_data: {
      ledger_type: defaultLedgerType,
      ledger_number: card.cardNumber,
    },
  }
}

export function stripePayment(
  amount: number,
  tokenId: string,
  sourceType: PaymentMethodName,
  saveAndRenew: boolean,
): StripePayload {
  const slug = environment.portal.slug
  return {
    amount: amount.toFixed(2),
    gateway_id: 'stripe',
    gateway_data: {
      // TODO Document what the description is for. Stripe statements/reconciliation?
      description: `${slug} tix-web payment`,
      source: tokenId,
      save_card: saveAndRenew,
      source_type: isWalletPayment(sourceType) ? 'card' : sourceType,
    },
    auto_renew: saveAndRenew,
  }
}

function freePayment(): FreeCheckoutPayload {
  return {
    gateway_id: 'free',
    // TODO Still necessary?
    gateway_data: {},
  }
}
