import StripeWalletPayment from '@/checkout/stripe/StripeWalletPayment'
import { environment } from '@/helpers/Environment'
import { isLoggedIn } from '@/state/Authentication'
import { cartExpired, refreshStoredCart } from '@/state/Cart'
import store from '@/store/store'
import { loadStripe } from '@stripe/stripe-js'
import { getCookie } from 'tiny-cookie'

export function initializeStores() {
  const hasSessionCookie = getCookie('session_expires')
  if (isLoggedIn() && !hasSessionCookie) {
    // The session has expired. Clear all storage, but stay on the current URL.
    store.dispatch('clearAll')
  } else {
    initializeCartStore()
    initializeMemberStore()
  }
}

/**
 * Removes any known carts that have expired, are empty, or not pending (purchased/deleted elsewhere).
 * Seeds a Stripe wallet payment to check if digital wallets are supported before getting to checkout.
 */
export function initializeCartStore(): void {
  if (cartExpired()) {
    store.dispatch('Cart/clearAll')
  } else if (store.getters['Cart/cart']) {
    // Reload the cart, which also confirms it is still valid.
    refreshStoredCart()
  }

  loadStripe(environment.stripe.publishable_key).then((stripe) => {
    if (stripe) {
      // Check if the browser/device supports digital wallet payments upon instantiation of the Vue app.
      // It takes a couple of seconds on most browsers, several seconds on Edge (for macos, at least), and zero seconds
      // on Firefox (the only major browser to have no support for any digital wallet or browser payment methods).
      // Use seed values to initialize a mock Stripe wallet payment; $100 with no identity fields.
      const wallet = new StripeWalletPayment(stripe, 100, [])
      wallet.enabled.then((walletType) => {
        // This seeds the initial value of CheckoutRoute.supportedWallet so that it can render quickly with/without
        // digital wallet options, with neither a loading-state nor a flash of content (identity form).
        store.commit('Cart/wallet', walletType)
      })
    }
  })
}

/**
 * Initializes the members store in case of external authentication (MoS Boston).
 */
export function initializeMemberStore() {
  const hasSessionCookie = getCookie('session_expires')
  const identity = store.getters['Member/identity']

  if (hasSessionCookie && !identity) {
    store.dispatch('Member/load')
  }
}
