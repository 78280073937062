import type { LinkedTG } from '@/api/types/processedEntities'
import { csvToArray, extractWords } from '@/helpers/StringHelpers'

export interface SurveySpec {
  type: 'object'
  additionalProperties: false
  properties: Dict<SurveyQuestion>
  required?: string[]
}

export interface SurveyQuestion {
  title: string
  description?: string
  propertyOrder: number
  type?: string
  enum?: string[]
  minimum?: number
  maximum?: number
  contexts?: string
  format?: 'email' | 'date'

  // TODO Confirm if and how these are used.
  disabled?: true
}

export interface SurveyAnswerPayload {
  ticket_group_id: string
  data: Dict<Primitive>
}

export interface SurveySpecWithTicketGroupID {
  ticket_group_id: string
  spec: SurveySpec
}

export function additionalFields(properties: any[], ticketGroupId: string): any {
  const data = {}

  Object.entries(properties).forEach(([key, item]) => {
    if (item.value) {
      if (item.enum) {
        data[key] = item.value
      } else if (item.type === 'integer') {
        data[key] = Number(item.value)
      } else if (item.type === 'boolean') {
        data[key] = Boolean(item.value)
      } else {
        data[key] = item.value
      }
    }
  })

  return { data, ticket_group_id: ticketGroupId }
}

export function surveyQuestionEnabled(question: SurveyQuestion, currentContexts: string): boolean {
  return _surveyQuestionEnabled(question.contexts, `web ${currentContexts}`)
}

function _surveyQuestionEnabled(questionContexts: string | undefined, currentContexts: string): boolean {
  if (!questionContexts) {
    return true
  }

  // Turns context string into sets of strings, where any set matching
  // the context causes the context to apply.
  const specs = csvToArray(questionContexts)

  if (specs.length < 1) {
    throw new Error('Survey question contexts must contain more than just commas and whitespace.')
  }

  const contexts = new Set(extractWords(currentContexts))

  return specs.some((spec) => {
    const criteria = extractWords(spec)
    return criteria.every((criterion) => contexts.has(criterion))
  })
}

export function surveyPayload(
  surveyAnswers: Dict<Dict<Primitive>>,
  onlyInGroupIDs?: Set<string>,
): SurveyAnswerPayload[] | undefined {
  const answers = Object.entries(surveyAnswers)
  // SurveyAnswerPayload can't be an empty array or null otherwise the API will return an error.
  if (answers.length === 0) {
    return undefined
  } else {
    const result = answers
      .filter(([key]) => {
        return onlyInGroupIDs == null || onlyInGroupIDs.has(key)
      })
      .map(([key, value]) => ({
        ticket_group_id: key,
        data: value,
      }))

    return result.length > 0 ? result : undefined
  }
}

export function surveySpecsWithTicketGroupId(selectedGroups?: LinkedTG[]): SurveySpecWithTicketGroupID[] {
  if (!selectedGroups) {
    return []
  }

  return selectedGroups
    .filter((group) => group.additional_info_spec)
    .map((group) => {
      return {
        ticket_group_id: group.id,
        spec: group.additional_info_spec,
      }
    })
}
