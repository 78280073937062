import { stripePayment } from '@/checkout/helpers/payloads'
import { checkout, CheckoutResponse } from '@/checkout/helpers/processing'
import type { PaymentMethodName } from '@/checkout/stripe/helpers'
import TixStripeError from '@/checkout/stripe/TixStripeError'
import { logInTrackJS } from '@/errors/helpers'
import type { IdentityFormData } from '@/helpers/IdentityHelpers'
import type { PaymentIntentConfirmParams, Stripe } from '@stripe/stripe-js'
import type { ConfirmPaymentData } from '@stripe/stripe-js/types/stripe-js/payment-intents'

export function handlePaymentRedirectResponse(
  stripe: Stripe,
  cartId: string,
  clientSecret: string,
  sourceType: PaymentMethodName,
  options,
): Promise<CheckoutResponse> {
  return stripe.retrievePaymentIntent(clientSecret).then((response) => {
    if (response.error) {
      throw new TixStripeError(response.error)
    } else {
      const paymentIntent = response.paymentIntent
      if (['requires_capture', 'processing', 'succeeded'].includes(paymentIntent.status)) {
        // TODO Test this for other currencies
        // PaymentIntent amount is in cents/pence.
        const payload = stripePayment(paymentIntent.amount / 100, paymentIntent.id, sourceType, false)
        return checkout(cartId, payload, options)
      } else if (paymentIntent!.last_payment_error) {
        throw new TixStripeError(paymentIntent!.last_payment_error)
      } else {
        // Not sure if it is possible to end up here.
        logInTrackJS('Unexpected Payment Redirect Error', paymentIntent)
        throw new Error(paymentIntent!.status)
      }
    }
  })
}

export function getRedirectPaymentData(
  cartId: string,
  identityFormData: IdentityFormData,
  purchaserId?: string,
): ConfirmPaymentData {
  return {
    shipping: getShippingParams(identityFormData),
    return_url: `${location.origin}/checkout?cartId=${cartId}&purchaser=${purchaserId}`,
  }
}

function getShippingParams(identityFormData: IdentityFormData): PaymentIntentConfirmParams.Shipping | null {
  if (!identityFormData.address) {
    return null
  }

  return {
    name: `${identityFormData.first_name} ${identityFormData.last_name}`,
    address: {
      line1: identityFormData.address as string,
      city: identityFormData.city as string,
      state: identityFormData.state as string,
      country: identityFormData.country as string,
      postal_code: identityFormData.zip_code as string,
    },
  }
}
