import { environment } from '@/helpers/Environment'
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'

export const auth0LoginPath = '/auth0-login'

export const auth0Client = instantiateClient(environment.web.auth0_login)

async function instantiateClient(options): Promise<Auth0Client | void> {
  if (options && navigator.cookieEnabled) {
    // Lazy load auth0. Most tenants don't use it, but it makes up ~10% of the vendor bundle.
    const { default: createAuth0Client } = (await import('@auth0/auth0-spa-js')) as any
    // @see https://auth0.com/docs/quickstart/spa/vuejs/01-login#create-an-authentication-wrapper
    return await createAuth0Client({
      domain: options.domain,
      audience: options.audience,
      client_id: options.clientId,
      redirect_uri: window.location.origin + auth0LoginPath,
    })
  }
}
