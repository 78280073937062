
import { environment } from '@/helpers/Environment'
import { LanguageStrings } from '@/language/types'
import { Component, Vue } from 'vue-property-decorator'
import { sellerConfig } from '@/helpers/Config'

/**
 * This component just handles redirects from /membership to the correct route.
 */
@Component({ name: 'MembershipRoute' })
export default class extends Vue {
  t: LanguageStrings['membershipRoute']

  beforeRouteEnter(to, from, next) {
    const eventId = sellerConfig('membership_event_id')
    const action = to.params.action ?? ''
    const externalUrl = environment.web.external_urls?.membership

    if (externalUrl) {
      // Redirect to an external URL if supplied
      // Membership sub-paths /membership/gift and /membership/renew should also redirect
      // /membership/redeem is caught and handled separately by RedeemMembershipRoute
      const suffix = externalUrl.charAt(externalUrl.length - 1) === '/' ? '' : '/'
      window.location.href = externalUrl + suffix + action
    } else if (eventId) {
      return next({
        path: `/events/${eventId}/${action}`,
        query: to.query,
      })
    } else {
      return next()
    }
  }
}
