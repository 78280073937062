
import stories from '@/stories/index'
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'StoryViewerRoute' })
export default class extends Vue {
  get stories() {
    return stories
  }
}
