import { csvToArray } from '@/helpers/StringHelpers'
import store from '@/store/store'
import { Route } from 'vue-router'

export type CompletedOrdersResponse = ApiResponse<
  'ticket_order' | 'identity' | 'contact' | 'seller' | 'meta' | 'ticket' | 'ticket_group'
>
export const completedOrdersRequestEmbeds = csvToArray(
  'identity,contact,contact.identity,ticket_order.seller,seller.meta,ticket,ticket.ticket_group',
)

/**
 * Handle route-leave procedures via a global `afterEach` router hook instead of `beforeRouteLeave` (or
 * `beforeRouteEnter` on the next page) because `afterEach` hooks are triggered _after_ navigation is confirmed.
 *
 * This is important so that clearing the cart does not interfere with state before the router has fully confirmed
 * leaving the /checkout route. Otherwise <CheckoutRoute> will think the cart has been emptied and navigate to the
 * homepage.
 *
 * @see https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
 */
export function afterCheckoutRoute(to: Route, from: Route) {
  // Clear state on;
  //   1. successful checkout.
  //   2. Abandoning a payment link (e.g. direct checkout).
  //   3. Abandoning checkout from edit order payment.
  if (to.name === 'checkout/complete' || to.name === 'checkout/pending' || from.params.cartId || from.params.orderId) {
    // Clear everything except authentication state and user.
    store.dispatch('clearAll', 'Member')
    window.tixAppState = {}
  }
}
