
import Subtitle from '@/components/elements/Subtitle.vue'
import { imageData, teaserImageUrl } from '@/helpers/ImageHelpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { EventDetails } from '@/api/types/processedEntities'

@Component({
  name: 'EventListingItem',
  components: { Subtitle },
})
export default class extends Vue {
  @Prop()
  event: EventDetails

  @Prop()
  path: string

  get image() {
    return imageData(this.event, teaserImageUrl)
  }

  get linkTitle() {
    return this.$t('linkTitle', { name: this.event.name })
  }
}
