import clickOutside from '@/app/directives/click-outside'
import HomeLink from '@/components/elements/HomeLink.vue'
import TixMessage from '@/components/elements/TixMessage.vue'
import TixTemplate from '@/components/elements/TixTemplate.vue'
import SectionHeader from '@/components/forms/SectionHeader.vue'
import SvgIcon from '@/components/icons/SvgIcon.vue'
import { environment } from '@/helpers/Environment'
import HelpersMixin from '@/plugins/HelpersMixin'
import PortalVue from 'portal-vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import LoginLink from '../components/elements/LoginLink.vue'
import MembershipLink from '../components/elements/MembershipLink.vue'
import TenantContent from '../components/elements/TenantContent'
import Loader from '../components/Loader.vue'
import Modals from '../plugins/Modals'
import autofocus from './directives/autofocus'
import focus from './directives/focus'
import sameAs from './directives/same-as'
import scrollIntoView from './directives/scroll-into-view'
import autoParagraph from './filters/autoParagraph'
import router from './Router'

Vue.use(Modals)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(PortalVue)
Vue.mixin(HelpersMixin)

Vue.component('Loader', Loader)
Vue.component('TenantContent', TenantContent)
Vue.component('HomeLink', HomeLink)
Vue.component('LoginLink', LoginLink)
Vue.component('MembershipLink', MembershipLink)
Vue.component('TixMessage', TixMessage)
Vue.component('SvgIcon', SvgIcon)
Vue.component('TixTemplate', TixTemplate)
Vue.component('SectionHeader', SectionHeader)

// Renders an HTML comment node, which clears any slot fallback content.
// @see https://github.com/vuejs/vue/issues/7829
// @see https://codepen.io/sirlancelot/pen/oqxzMq?editors=1010
Vue.component('Empty', { render: (h) => h() })

// Automatically focus an element when it is inserted into the DOM.
Vue.directive('autofocus', autofocus)
Vue.directive('focus', focus)
Vue.directive('same-as', sameAs)
Vue.directive('scroll-into-viewport', scrollIntoView)
Vue.directive('click-outside', clickOutside)

// Vue 3 will not support filters.
Vue.prototype.autoP = autoParagraph

// Make config variables available in all templates.
Vue.prototype.$portalString = environment.portalStrings

export { Vue, router }
