
import FormInput2 from '@/components/forms/FormInput2.vue'
import { indexItemsById } from '@/helpers/IndexHelpers'
import type { LanguageStrings } from '@/language/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import type { EventDetails } from '@/api/types/processedEntities'

type AdmitDetailsConfig = 'required' | 'optional' | 'disabled'

interface Field {
  label: string
  key: string
  ticketTypeId: string
  index: number
  required: boolean
  email: AdmitDetailsConfig
}

@Component({
  name: 'AdmitDetailsFields',
  components: { FormInput2 },
})
export default class extends Vue {
  @Prop({ required: true })
  quantities: TicketTypeQuantities

  @Prop({ required: true })
  event: EventDetails

  @Prop({ required: true })
  value: Dict<EventAdmitDetails[]>

  internal: Dict<EventAdmitDetails[]> = {}

  t: LanguageStrings['admitDetailsFields']

  get fields(): Field[] {
    const types = indexItemsById(this.event.ticketTypes)
    const nameConfig: Dict<AdmitDetailsConfig> = {}
    const emailConfig: Dict<AdmitDetailsConfig> = {}
    const eventMeta = this.event.meta
    for (const group of this.event.ticketGroups) {
      const groupMeta = group.meta
      nameConfig[group.id] = (groupMeta.admit_names || eventMeta.admit_names || 'disabled') as AdmitDetailsConfig
      emailConfig[group.id] = (groupMeta.admit_emails || eventMeta.admit_emails || 'disabled') as AdmitDetailsConfig
    }

    const result: Field[] = []

    for (const typeId in this.quantities) {
      const quantity = this.quantities[typeId].quantity
      const ticketType = types[typeId]
      const config = nameConfig[ticketType.ticket_group_id]

      if (config !== 'disabled' && quantity) {
        for (let i = 0; i < quantity; i++) {
          const ttName = ticketType.name
          const number = i + 1
          result.push({
            index: i,
            label: this.$t('admitDetailsFields.label', { ttName, number }) as string,
            key: `${ticketType.id}-${i}`,
            ticketTypeId: ticketType.id,
            required: config === 'required',
            email: emailConfig[ticketType.ticket_group_id],
          })
        }
      }
    }
    return result
  }

  @Watch('internal', { deep: true })
  onNamesChange() {
    this.$emit('input', this.internal)
  }

  @Watch('quantities', { deep: true, immediate: true })
  initNames() {
    for (const ttId in this.quantities) {
      const names: EventAdmitDetails[] = []
      const quantity = this.quantities[ttId].quantity

      for (let i = 0; i < quantity; i++) {
        names[i] = this.value[ttId]?.[i] ?? { name: '' }
      }

      Vue.set(this.internal, ttId, names)
    }
  }

  nameLabel(email: AdmitDetailsConfig, label: string) {
    if (email !== 'disabled') {
      return 'Full Name'
    } else {
      return `${label} Full Name`
    }
  }
}
