export default class NotFoundError extends Error {
  notFoundError = true
  resourceType: string

  constructor(resourceType: string, ...args) {
    // @ts-ignore
    super(`Resource ${resourceType} not found.`, ...args)
    this.resourceType = resourceType
  }
}
