import { API, ApiArgs } from '@/api/API'
import { firstEntity, toLinkedGroups } from '@/api/Helpers'
import type { EventDetails, LinkedTG, TicketGroupsAndTypesResponse } from '@/api/types/processedEntities'
import { TixTime } from '@/TixTime/TixTime'
import type { Env } from '@/types/Env'
import type { Session } from '@/types/Sessions'
import deepmerge from 'deepmerge'

export type LinkedSessionTG = LinkedTG & SessionTG

export function getSessionTicketGroupsAndTypes(id: string, tgIDs: string[]): Promise<LinkedSessionTG[]> {
  const path = `event_session/${id}`

  const args: ApiArgs = {
    embed: 'ticket_group,ticket_type,ticket_group.meta',
    query: {
      'ticket_group.id._in': tgIDs.join(','),
      'ticket_group.hidden_type._in': 'public_browsable,public_member_only,public_link_only',
    },
  }

  // It is easier to filter out entities before linking them together to avoid leaks
  return API.getCached<'event_session' | 'ticket_group' | 'ticket_type' | 'meta'>(path, args)
    .then(filterUnavailable)
    .then(toLinkedGroups) as Promise<LinkedSessionTG[]>
}

export function getSession(id: string): Promise<{ session: EventSession; venue: Venue }> {
  const path = `event_session/${id}`
  return API.getCached<'event_session' | 'venue'>(path, { embed: 'venue' }).then((response) => {
    const [session, venue]: [EventSession, Venue] = firstEntity(response, 'event_session', 'venue')
    return {
      session,
      venue,
    }
  })
}

function filterUnavailable(original: TicketGroupsAndTypesResponse): TicketGroupsAndTypesResponse {
  const overwrites: DeepPartial<TicketGroupsAndTypesResponse> = {
    ticket_type: {
      _data: original.ticket_type._data.filter((type) => type.available),
    },
    ticket_group: {
      _data: (original.ticket_group._data as SessionTG[])
        .filter((group) => group.available)
        .filter((group) => !group.sales_end || new TixTime().isBefore(group.sales_end))
        .filter((group) => !group.sales_start || new TixTime().isAfter(group.sales_start)),
    },
  }

  // Configures deepmerge to merge objects and overwrite arrays, instead of merging both.
  // @see https://www.npmjs.com/package/deepmerge#arraymerge-example-overwrite-target-array
  const arrayMerge = (destination, source) => source
  return deepmerge(original, overwrites, { arrayMerge }) as TicketGroupsAndTypesResponse
}

export function formatSessionList(sessions: Session[]): string {
  return sessions.map((session) => session.startTime.format('LT')).join(', ')
}

export function normalizeSession(session: EventSession, venue: Venue): Session {
  const { capacity, used_capacity, oversell_capacity, end_datetime, start_datetime } = session
  const tz = venue.timezone

  return {
    ...session,

    startTime: new TixTime(start_datetime, tz),
    endTime: new TixTime(end_datetime, tz),

    // Oversell capacity allows any remaining ticket(s) to be sold together with up to N "over-sold" tickets.
    // This helps prevent sessions from being undersold, when single tickets are difficult to sell.
    availableCapacity: handleUnlimitedCapacity(capacity) + oversell_capacity - used_capacity,
  }
}

export function normalizeSessions(sessions: EventSession[], venue: Venue): Session[] {
  return sessions.map((session) => normalizeSession(session, venue))
}

export function handleUnlimitedCapacity(capacity: number): number {
  // Unlimited capacity is represented by a capacity of -1.
  return capacity === -1 ? Infinity : capacity
}

export function hideSoldOutSessions(event: EventDetails, env: Env): boolean {
  return event.config.web?.ecommerce?.hide_sold_out_sessions ?? env.web.hide_sold_out_sessions ?? false
}
