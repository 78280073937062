
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { EventWithConfig } from '@/api/types/processedEntities'

@Component({ name: 'Subtitle' })
export default class extends Vue {
  @Prop()
  event: EventWithConfig

  get subtitle() {
    return this.event?.subtitle
  }
}
