
import ApplyPromoCodes from '@/checkout/components/ApplyPromoCodes.vue'
import type { WalletType } from '@/checkout/helpers/wallets'
import ExpiryTimer from '@/components/cart/ExpiryTimer.vue'
import Price from '@/components/elements/Price.vue'
import { onSubmit, type PayButtonContext, timeTillExpiry } from '@/helpers/CartHelpers'
import { formatCurrency } from '@/helpers/Currency'
import { formatCartItemDateTime } from '@/helpers/Date'
import type { LanguageStrings } from '@/language/types'
import { CartItem, isTimedItem, TimedItem } from '@/store/CartItem'
import store from '@/store/store'
import type { ComponentOptions } from '@/types/ComponentOptions'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PayButton from './PayButton.vue'
import RemoveCartItemOverlay from './RemoveCartItemOverlay.vue'
import StripeWalletPayment from '@/checkout/stripe/StripeWalletPayment'
import type { PaymentMethodName } from '@/checkout/stripe/helpers'
import type { TixTimeDuration } from '@/TixTime/Duration'
import type { PendingGiftCardPayment } from '@/store/Cart'
import { theme } from '@/helpers/Environment'
import { cartIsModifiable } from '@/helpers/CartPromoCodeHelpers'

@Component({
  name: 'CartWidget',
  components: {
    ExpiryTimer,
    ApplyPromoCodes,
    Price,
    RemoveCartItemOverlay,
    PayButton,
  },
  computed: mapGetters({
    memberIdentity: 'Member/identity',
    cart: 'Cart/cart',
    cartItems: 'Cart/cartItems',
    cartMods: 'Cart/cartMods',
    faceValue: 'Cart/faceValue',
    totalPromoDiscounts: 'Cart/totalPromoDiscounts',
    totalFixedFees: 'Cart/totalFixedFees',
    totalPercentFees: 'Cart/totalPercentFees',
    totalPayments: 'Cart/totalPayments',
    paymentDue: 'Cart/paymentDue',
    pendingGiftCardPayments: 'Cart/pendingGiftCardPayments',
    paymentDueByCreditCard: 'Cart/paymentDueByCreditCard',
  }),
  methods: {
    formatCurrency,
  },
})
export default class extends Vue {
  t: LanguageStrings['cartWidget']
  opt: ComponentOptions['cartWidget']

  @Prop({ default: false })
  submitDisabled: boolean

  @Prop({ default: 'default' })
  context: PayButtonContext

  @Prop()
  selectedPaymentType: PaymentMethodName

  @Prop()
  supportedWallet: WalletType

  @Prop({ default: false })
  submitting: boolean

  @Prop({ default: false })
  isEmbedded: boolean

  @Prop()
  wallet: StripeWalletPayment | null

  // Let TypeScript know about the getters.
  // Unfortunately vuex-class doesn't work with modules
  // @see https://github.com/ktsn/vuex-class
  pendingGiftCardPayments: PendingGiftCardPayment[]
  pendingRemoveCartItemId: string | null = null
  cart
  cartItems: CartItem[]
  cartMods: CartMod[]
  faceValue
  totalPayments
  paymentDue
  paymentDueByCreditCard
  totalFixedFees
  totalPercentFees
  totalPromoDiscounts

  expiresIn: TixTimeDuration | null = null
  expiryInterval: ReturnType<typeof setTimeout>

  distanceTopViewport = 0

  created() {
    this.setExpiry()

    this.expiryInterval = setInterval(this.setExpiry, 1000)
  }

  mounted() {
    this.setDistanceTopViewport()
    window.addEventListener('scroll', this.setDistanceTopViewport)
  }

  setDistanceTopViewport() {
    const cart = this.$refs.cart as HTMLElement

    if (!this.isEmbedded) {
      const rect = cart.getBoundingClientRect()
      // Minimum value of 20 as the .aside has `top: 20px`.
      this.distanceTopViewport = Math.max(rect.top, 20)
    }
  }

  @Watch('distanceTopViewport')
  setMaxHeight() {
    const cart = this.$refs.cart as HTMLElement
    // This is to make sure that cart doesn't go shorter than this value in
    // order to guarantee that an appropriate real estate is given to cart
    // items.
    const minHeight = this.opt.minHeight ?? '500px'
    // Give it a 20px margin on the bottom.
    // .aside has `position: sticky` and `top: 20px` ensuring there is a 20px margin at the top.
    cart.style.maxHeight = `max(${minHeight}, calc(100vh - ${this.distanceTopViewport + 20}px))`
  }

  destroyed() {
    clearInterval(this.expiryInterval)
    window.removeEventListener('scroll', this.setDistanceTopViewport)
  }

  get empty() {
    return this.cartItems.length < 1
  }

  get promoCodeApplyEnabled() {
    return this.isModifiable && this.paymentDue > 0 && this.totalPayments === 0
  }

  get showTotals(): boolean {
    return this.paymentDueByCreditCard !== 0 || this.faceValue !== 0
  }

  get discounts() {
    // Hide checkout rules that block checkout but don't discount the value.
    // Show checkout rules that don't block checkout or offer a discount.
    // They probably provide other benefits, such as extended two-year membership.
    return this.cartMods.filter((mod) => !(mod.blocking_checkout && mod.discount === '0'))
  }

  setExpiry() {
    const expiry = store.getters['Cart/localExpiry']
    if (expiry) {
      this.expiresIn = timeTillExpiry(expiry)
    } else {
      this.expiresIn = null
    }
  }

  get items(): CartItem[] {
    return this.cartItems.map((item) => {
      if (item.isMembershipEvent) {
        const types = item.types.filter((type) => type.totalPrice > 0)
        return {
          ...item,
          // Membership cart items show the ticket group name instead of the event name
          name: types.length > 0 ? types[0].group.name : item.name,
          types,
        }
      } else {
        return {
          ...item,
          types: item.types.map((type) => ({
            ...type,
            name: this.opt.showTicketGroupName ? `${type.group.name} - ${type.name}` : type.name,
          })),
        }
      }
    })
  }

  get ticketFees(): Array<{ id: string; name: string; value: number }> {
    return store.getters['Cart/feeTickets'].map(({ ticket, type }) => ({
      id: ticket.id,
      value: parseFloat(ticket.face_value),
      name: type.name,
    }))
  }

  get hasUpsells() {
    return this.items.some((item) => !item.isAnchor)
  }

  itemDateTime(item: CartItem): string | null {
    if (!isTimedItem(item)) {
      return null
    }

    const dateTimeFormat = this.opt.dateTimeFormat ?? 'ddd, MMM D, YYYY [at] h:mm A'
    const dateOnlyFormat = this.opt.dateOnlyFormat ?? 'ddd, MMM D, YYYY'
    const timeOnlyFormat = this.opt.timeOnlyFormat ?? 'h:mm A'
    return formatCartItemDateTime(item as TimedItem, {
      dateTimeFormat,
      dateOnlyFormat,
      timeOnlyFormat,
    })
  }

  removeCartItem(item: CartItem) {
    this.pendingRemoveCartItemId = this.getCartItemId(item)
  }

  getCartItemId(item: CartItem) {
    const sessionId = (item as TimedItem).sessionId
    return sessionId ? `${item.event.id},${sessionId}` : item.event.id
  }

  onSubmit() {
    onSubmit(this.context, this.$router, () => this.$emit('submit'))
  }

  get darkTheme(): boolean {
    return theme?.cartThemeMode === 'dark' || theme?.themeMode === 'dark'
  }

  get isModifiable(): boolean {
    return cartIsModifiable()
  }
}
