import { TixTimeDuration } from '@/TixTime/Duration'
import type { TixTimePrecision, TTStringFormat } from '@/TixTime/helpers'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'

/**
 * Wraps Moment.js to prevent accidental mutations and make it easier to replace.
 *
 * @warn Prefer TixDate for dates that need no time nor timezone.
 *
 * TODO Replace moment with a more modern datetime library.
 * TODO Replace moment.tz(this.#value, 'UTC') with a helper. Maybe this.#moment()
 */
export class TixTime {
  private date: Moment
  timezone: string
  private isTixTime = true

  // TODO change constructor to only accept ISO string, Date or TixTime objects.
  constructor(date?: Date | Moment | string | number | null, timezone?: string) {
    // TODO this is to allow operations on time values only
    // perhaps it should have a spearate class?
    // match hh:mm and hh:mm:ss
    if (date && typeof date === 'string' && date.match(/^\d{2}:\d{2}(:\d{2})?$/)) {
      date = `1970-01-01 ${date}`
    }

    if (timezone) {
      this.date = moment.tz(date || undefined, timezone)
      this.timezone = timezone
    } else {
      if (!date) {
        this.date = moment()
      } else {
        this.date = moment(date)
      }
    }
  }

  add(amount: number, unit: TixTimePrecision) {
    const date = moment(this.date).add(amount, unit)
    return new TixTime(date.toISOString(), this.timezone)
  }

  subtract(amount: number, unit: TixTimePrecision): TixTime {
    const date = moment(this.date).subtract(amount, unit)
    return new TixTime(date.toISOString(), this.timezone)
  }

  getDayOfWeek(): number {
    return this.date.day()
  }

  setDayOfWeek(day: number): TixTime {
    const date = moment(this.date).day(day)
    return new TixTime(date.toISOString(), this.timezone)
  }

  startOfDay(): TixTime {
    const date = moment(this.date).startOf('day')
    return new TixTime(date.toISOString(), this.timezone)
  }

  endOfDay(): TixTime {
    const date = moment(this.date).endOf('day')
    return new TixTime(date.toISOString(), this.timezone)
  }

  startOfMonth(): TixTime {
    const date = moment(this.date).startOf('month')
    return new TixTime(date.toISOString(), this.timezone)
  }

  endOfMonth(): TixTime {
    const date = moment(this.date).endOf('month')
    return new TixTime(date.toISOString(), this.timezone)
  }

  daysInMonth(): number {
    return this.date.daysInMonth()
  }

  endOfYear(): TixTime {
    const date = moment(this.date).endOf('year')
    return new TixTime(date.toISOString(), this.timezone)
  }

  isBefore(date: TixTime | string): boolean {
    date = this.ensureTixTimeType(date)
    return this.date.isBefore(date.date)
  }

  isAfter(date: TixTime | string): boolean {
    date = this.ensureTixTimeType(date)
    return this.date.isAfter(date.date)
  }

  isSame(date: TixTime | string, precision: TixTimePrecision): boolean {
    date = this.ensureTixTimeType(date)
    return this.date.isSame(date.date, precision)
  }

  // TODO cover with Unit Tests

  isSameOrBefore(date: TixTime | string, precision: TixTimePrecision): boolean {
    date = this.ensureTixTimeType(date)
    return this.date.isSameOrBefore(date.date, precision)
  }

  isBetween(
    start: TixTime | string,
    end: TixTime | string,
    precision?: TixTimePrecision,
    inclusivity?: '()' | '[)' | '(]' | '[]',
  ): boolean {
    start = this.ensureTixTimeType(start)
    end = this.ensureTixTimeType(end)

    return this.date.isBetween(start.date, end.date, precision, inclusivity)
  }

  durationTo(date: TixTime): TixTimeDuration {
    return new TixTimeDuration(date.date.diff(this.date))
  }

  // These will probably apply mostly-consistently across most JS date-time libraries.
  momentFormats: Record<TTStringFormat, string> = {
    LT: 'h:mm A',
    LONG_DATE: 'ddd, MMM D, YYYY',
    LONGER_DATE: 'dddd, MMMM D, YYYY',
    LONG_DATE_TIME: 'ddd, MMM D, YYYY [at] h:mm A',
    TIME: 'HH:mm',
    LT_WITH_ZONE: 'h:mm A zz',
    DATE: 'YYYY-MM-DD',
    military: 'HHmm',
  }

  // Should we consider separating to different methods here
  format(format: TTStringFormat = 'iso'): string {
    if (format === 'iso') {
      return this.date.toISOString()
    } else if (this.momentFormats[format]) {
      return this.date.format(this.momentFormats[format])
    }
    // fallback catch all for unspecified formats
    return this.date.format(format)
  }

  /**
   * Decimal Hour of Day is the time of day expressed as a number of hours from midnight,
   * including the minutes as the fraction.
   *
   * For example:
   * 10     is 10am
   * 11.5   is 11:30am
   * 14     is 2pm
   * 15.25  is 3:15pm
   * 23+1/3 is 11:20pm
   */
  asDecimal(): number {
    const hour = this.date.format('H')
    const minute = this.date.format('mm')
    return Number(hour) + Number(minute) / 60
  }

  asMinutesSinceMidnight(): number {
    const hour = this.date.format('H')
    const minute = this.date.format('mm')
    return Number(hour) * 60 + Number(minute)
  }

  // This possibly cannot be UNIT Tested, as the valueOf depend on the current timezone
  asTimeValue(): number {
    return this.date.valueOf()
  }

  asDateObject(): Date {
    return this.date.toDate()
  }

  utcOffset(): number {
    return this.date.utcOffset()
  }

  private ensureTixTimeType(date: TixTime | string): TixTime {
    if (typeof date === 'string') {
      return new TixTime(date)
    }
    return date
  }
}
