import { DirectiveOptions } from 'vue'

const autofocus: DirectiveOptions = {
  inserted(el, { value }) {
    if (value) {
      el.focus()
    }
  },
}

export default autofocus
