import type { MembershipRules } from '@/api/Membership'
import type { ReserveTicketPayload } from '@/api/types/payloads'
import type { EventDetails } from '@/api/types/processedEntities'
import { mapEntries } from '@/helpers/DictHelpers'
import type { CartChanges } from '@/helpers/Reserve'
import { SurveyAnswerPayload, surveyPayload } from '@/helpers/SurveyHelpers'

// API expects first and last names with a non-breaking space (`\xa0`).
export const nameDelimiter = `\xa0`

export function ticketsPayload(
  rules: MembershipRules,
  selectedLevel: string,
  countsByTTID: Dict<number>,
  names: Dict<MembershipAdmitDetails[]>,
): ReserveTicketPayload[] {
  // Get a list of reservable tickets for each ticket type.
  return rulesForSelectedLevel(rules, selectedLevel).flatMap((rule) => {
    const selected = countsByTTID[rule.ticket_type_id]
    const requiredQuantity = Math.max(selected, rule.min)
    return reservableTicketsForTicketType(rule.ticket_type_id, requiredQuantity, names)
  })
}

export function cartParams(
  event: EventDetails,
  selectedLevel: string,
  surveyAnswers: Dict<Primitive>,
  codes: string[],
): CartChanges {
  return {
    additionalInfo: getSurveyPayload(event, selectedLevel, surveyAnswers),
    postReservePromoCodes: { codes },
  }
}

function rulesForSelectedLevel(rules: MembershipRules, selectedLevel: string): MembershipRuleEntity[] {
  const types = rules.indexedTypes
  return rules.rules
    .filter((role) => role.ticket_group_id === selectedLevel)
    .filter((role) => types[role.ticket_type_id] != null)
}

/**
 * Factory for reservable tickets.
 */
export function reservableTicket(typeId: string, name: string, email: string): ReserveTicketPayload {
  return {
    ticket_type_id: typeId,
    admit_name: name,
    admit_email: email,
  }
}

function reservableTicketsForTicketType(
  typeId: string,
  minimumRequiredQuantity: number,
  names: Dict<MembershipAdmitDetails[]>,
): ReserveTicketPayload[] {
  const result: ReserveTicketPayload[] = []

  for (const { first, last, email } of trimmedDetails(names)[typeId] || []) {
    // Named tickets first.
    result.push(reservableTicket(typeId, first + nameDelimiter + last, email))
  }

  for (let i = result.length; i < minimumRequiredQuantity; i++) {
    // More unnamed tickets up to the specified quantity.
    result.push(reservableTicket(typeId, '', ''))
  }

  return result
}

function trimmedDetails(names: Dict<MembershipAdmitDetails[]>) {
  return mapEntries(names, (names) =>
    names
      .filter((name) => !name.disabled)
      .map(({ first, last, email, disabled }) => ({
        first: first?.trim() ?? '',
        last: last?.trim() ?? '',
        email: email?.trim() ?? '',
      })),
  )
}

function getSurveyPayload(
  event: EventDetails,
  selectedLevel: string,
  surveyAnswers: Dict<Primitive>,
): SurveyAnswerPayload[] | undefined {
  if (!surveySpec(event, selectedLevel)) {
    return undefined
  } else {
    const selected = selectedGroup(event, selectedLevel)
    return surveyPayload({ [selected!.id]: surveyAnswers })
  }
}

export function surveySpec(event: EventDetails, selectedLevel: string) {
  return selectedGroup(event, selectedLevel)?.additional_info_spec
}

export function selectedGroup(event: EventDetails, selectedLevel: string) {
  return event.ticketGroups.find((group) => group.id === selectedLevel)
}

export function identifiedRoles(
  rules: MembershipRules,
  selectedLevel: string,
): Array<{
  id: string
  name: string
  type: TicketType
  requiredCount: number
  optionalCount: number
  emailRequired: MembershipAdmitDetailsConfig
}> {
  const types = rules.indexedTypes
  return rulesForSelectedLevel(rules, selectedLevel)
    .filter((role) => role.named !== 'no')
    .map(({ id, ticket_type_id, party_role, named, emailed, max, min }) => {
      return {
        id: id,
        name: party_role,
        type: types[ticket_type_id],
        requiredCount: named === 'yes' ? min : 0,
        optionalCount: named === 'optional' ? max : max - min,
        emailRequired: emailed,
      }
    })
    .sort((a, b) => a.type._rank - b.type._rank)
}

export function anonymousRoles(
  rules: MembershipRules,
  selectedLevel: string,
): Array<{
  id: string
  name: string
  type: TicketType
  max: number
  min: number
}> {
  const types = rules.indexedTypes
  return rulesForSelectedLevel(rules, selectedLevel)
    .filter((role) => role.named === 'no')
    .map((role) => ({
      id: role.id,
      name: role.party_role,
      type: types[role.ticket_type_id],
      // -1 is used to indicate "unlimited". But we only allow 10 for memberships purchased
      // via web/eCommerce in this scenario.
      max: role.max < 0 ? 10 : role.max,
      min: role.min,
    }))
    .sort((a, b) => a.type._rank - b.type._rank)
}
