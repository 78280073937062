
import { Component, Prop, Vue } from 'vue-property-decorator'
import { unique } from '@/helpers/ArrayHelpers'
import { showAdvertisedFees } from '@/helpers/Fees'
import { currencySymbol } from '@/helpers/Currency'
import type { LanguageStrings } from '@/language/types'
import { languageItem } from '@/language/helpers'

export interface LegendProps {
  annotations: MessageAction[]
  // The `Sold out` legend annotation is only relevant for <CalendarPicker>
  areAnySoldOut: boolean
  arePricesDisplayed: boolean
  hasPriceIncrease: boolean
}

@Component({ name: 'TixLegend' })
export default class TixLegend extends Vue {
  @Prop({ required: true })
  annotations: MessageAction[]

  @Prop({ required: true })
  areAnySoldOut: boolean

  @Prop({ required: true })
  arePricesDisplayed: boolean

  @Prop({ required: true })
  hasPriceIncrease: boolean

  t: LanguageStrings['tixLegend']

  get messageActions(): MessageAction[] {
    return unique(this.annotations)
  }

  get taxesAndFeesMessage(): string {
    if (!this.arePricesDisplayed) return ''
    return showAdvertisedFees() ? this.t.priceIncludesTax : this.t.priceExcludesTax
  }

  get currencySymbol() {
    return currencySymbol()
  }

  get hasItems() {
    return this.messageActions.length > 0 || this.areAnySoldOut || this.taxesAndFeesMessage || this.hasPriceIncrease
  }

  get soldOutLabel() {
    return languageItem('availabilityStatus').soldOut
  }
}
