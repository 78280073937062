
import { getLogoExternalUrl } from '@/helpers/Environment'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  get homeLinkAttributes(): {
    is: string
    [key: string]: string
  } {
    const externalUrl = getLogoExternalUrl()
    if (externalUrl) {
      return { is: 'a', href: externalUrl }
    } else {
      return { is: 'router-link', to: '/' }
    }
  }
}
