
import {
  allowMemberReserveBeyondTerm,
  currentMembership,
  isCurrentMember,
  membershipOnDate,
} from '@/state/Membership'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TixTime } from '@/TixTime/TixTime'
import type { TTStringFormat } from '@/TixTime/helpers'
import type { EventDetails } from '@/api/types/processedEntities'

@Component({ name: 'RenewMembershipMessage' })
export default class extends Vue {
  @Prop({ required: true })
  event: EventDetails

  @Prop({ required: true })
  // TODO Use TixDate instead.
  dateToCheck: TixTime

  get eventHasMembersTickets(): boolean {
    return (
      this.event.hidden_type === 'public_member_only' ||
      this.event.ticketGroups.some((group) => group.hidden_type === 'public_member_only')
    )
  }

  get membershipExpiredByDate(): boolean {
    if (isCurrentMember()) {
      if (allowMemberReserveBeyondTerm()) {
        return false
      } else {
        return membershipOnDate(this.dateToCheck) === undefined
      }
    } else {
      return false
    }
  }

  get getMembershipRenewalMessage(): string | null {
    if (this.eventHasMembersTickets && this.membershipExpiredByDate) {
      const current = currentMembership()
      const dateFormat = this.$t('dateFormat.medium') as TTStringFormat
      const ticketDate = this.dateToCheck.format(dateFormat)
      if (current) {
        const expiryDate = current.validTo.format(dateFormat)
        return this.expiresInMessage(expiryDate, ticketDate)
      } else {
        return this.expiredMessage(ticketDate)
      }
    } else {
      return null
    }
  }

  expiresInMessage(expiryDate: string, ticketDate: string) {
    return this.$t('renewMembershipMessage.expiresIn', {
      expiryDate,
      ticketDate,
    }) as string
  }

  expiredMessage(ticketDate: string) {
    return this.$t('renewMembershipMessage.expired', {
      ticketDate,
    }) as string
  }
}
