
import CartWidget from '@/components/cart/CartWidget.vue'
import Price from '@/components/elements/Price.vue'
import { LanguageStrings } from '@/language/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { watchOnce } from '@/helpers/ComponentHelpers'

@Component({
  name: 'MobileCartWidget',
  components: {
    CartWidget,
    Price,
  },
})
export default class MobileCartWidget extends Vue {
  t: LanguageStrings['mobileCartWidget']

  resizeObserver: ResizeObserver

  @Prop({ default: false })
  show: boolean

  mounted() {
    const footerEl = this.$refs.footer as HTMLElement
    const style = document.body.style

    watchOnce(this, 'show').then(() => {
      const cartHeaderEl = this.$el.querySelector('.mobile-cart .modal-header') as HTMLElement
      style.setProperty('--mobile-cart-header-height', `${cartHeaderEl.offsetHeight}px`)
      style.setProperty('--mobile-cart-footer-height', `${footerEl.offsetHeight}px`)
    })

    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        const height = footerEl.offsetHeight
        // When the element is hidden, the height is 0,
        // so we don't need to update the css prop
        if (height > 0) {
          style.setProperty('--mobile-cart-footer-height', `${height}px`)
        }
      })

      this.resizeObserver.observe(footerEl)
    }
  }

  get hideCheckoutButton() {
    return this.$route.name === 'checkout'
  }

  onCheckout() {
    this.$modals.close()
    this.$router.push('/checkout')
  }

  onMoreEvents() {
    this.$modals.close()
    this.$router.push('/events')
  }
}
