import MobileCartWidget from '@/components/cart/MobileCartWidget.vue'
import { languageItem } from '@/language/helpers'
import { openModal } from '@/modals/helpers/api'
import { Vue } from 'vue-property-decorator'

export function openCartModal() {
  openModal({
    name: 'mobile-cart',
    component: MobileCartWidget,
    title: languageItem('cartWidget').title,
  })
}

export function closeCartModal() {
  Vue.prototype.$modals.close({ name: 'mobile-cart' })
}
