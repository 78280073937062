
import FormInput2 from '@/components/forms/FormInput2.vue'
import { environment } from '@/helpers/Environment'
import { csvToArray, stripWhitespace } from '@/helpers/StringHelpers'
import type { LanguageStrings } from '@/language/types'
import type { ComponentOptions } from '@/types/ComponentOptions'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import type { LinkedTG } from '@/api/types/processedEntities'

interface CouponFieldGroup {
  id: string
  name: string
  description: string
  pattern: string
  keys: string[]
}

@Component({
  name: 'CitypassCoupons',
  components: { FormInput2 },
})
export default class extends Vue {
  @Prop({ required: true })
  ticketGroups: LinkedTG[]

  @Prop({ required: true })
  quantities: TicketTypeQuantities

  @Prop({ required: true })
  error: string | null

  @Prop()
  submitting: boolean

  // Checkbox state.
  checkboxChecked = false

  // User-input state.
  internal: Dictionary = {}

  errors: Dictionary = {}

  t: LanguageStrings['citypassCoupons']
  opt: ComponentOptions['citypassCoupons']

  validate(error: string, inputEl: HTMLInputElement, pattern: string, key: string) {
    // Let built-in validation handle empty values.
    if (inputEl.value.length > 0) {
      const code = stripWhitespace(inputEl.value)
      if (code.match(new RegExp(pattern))) {
        Vue.set(this.errors, key, '')
      } else {
        Vue.set(this.errors, key, 'Does not match the expected format. Please check your CityPASS code.')
      }
    }
  }

  @Watch('checkboxChecked')
  @Watch('quantities', { deep: true })
  @Watch('internal', { deep: true })
  @Emit('input')
  emitCouponCodes() {
    // Only return coupon codes for the current selection of ticket types and quantities,
    // even if there are more stored in this.internal from earlier.
    return this.currentKeys.map((key) => {
      const value = this.internal[key]
      if (value) {
        return stripWhitespace(value)
      }
    })
  }

  /**
   * Keys for all tickets that require a CityPASS coupon based on current selection of ticket types and quantities.
   */
  private get currentKeys() {
    const result: string[] = []

    if (this.checkboxChecked) {
      for (const group of this.couponFieldGroups) {
        result.push(...group.keys)
      }
    }

    return result
  }

  get couponFieldGroups(): CouponFieldGroup[] {
    const result: CouponFieldGroup[] = []

    for (const group of this.ticketGroups) {
      for (const type of group.types) {
        const quantity = this.quantities[type.id]?.quantity
        if (quantity) {
          result.push(this.getCouponFieldGroup(type, quantity))
        }
      }
    }

    return result
  }

  /**
   * Collects config and generates keys for coupon fields for a ticket type.
   */
  private getCouponFieldGroup({ id, name, description }: TicketType, quantity: number): CouponFieldGroup {
    const keys: string[] = []

    for (let index = 0; index < quantity; index++) {
      keys.push(`${id}.${index}`)
    }

    const config = environment.config.citypass_code_groups!
    const pattern = this.isChildTicket(name) ? config.child_regexp : config.adult_regexp

    return { id, name, description, keys, pattern }
  }

  /**
   * Determines if child CityPASS coupons should be used for a ticket type.
   */
  private isChildTicket(ticketTypeName: string): boolean {
    const name = ticketTypeName.toLowerCase()
    return this.childKeywords.some((keyword) => name.includes(keyword))
  }

  private get childKeywords(): string[] {
    const config = environment.config.citypass_code_groups?.child_ticket_type_keywords
    if (config != undefined) return config.map((item) => item.toLowerCase())
    // TODO Move config in tenants/*/config.yml to CMS and remove this.
    if (this.opt.childKeywords) return csvToArray(this.opt.childKeywords)
    return ['child']
  }
}
