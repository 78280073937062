
import FormInput2 from '@/components/forms/FormInput2.vue'
import { toKebabCase } from '@/helpers/StringHelpers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TixStepper from '@/components/forms/TixStepper.vue'

@Component({
  name: 'UnnamedMembers',
  components: { TixStepper, FormInput2 },
})
export default class extends Vue {
  @Prop()
  value: number

  @Prop({ required: true })
  name: string

  @Prop({ required: true })
  type: TicketType

  @Prop({ required: true })
  min: number

  @Prop({ required: true })
  max: number

  internal: number | null = null

  created() {
    if (this.value == null || this.value < this.min) {
      this.internal = this.min
    } else {
      this.internal = this.value
    }
  }

  @Watch('internal')
  emit() {
    this.$emit('input', this.internal)
  }

  get label() {
    const summary = this.type.summary.trim()
    if (summary.length) {
      return summary
    }

    // TODO Remove once descriptions have been migrated to summaries. @see https://trello.com/c/evRy8rmT
    const description = this.type.description.trim()
    if (description.length) {
      return description
    }

    const role = toKebabCase(this.name)
    const key = `memberRoleLabels.${role}`
    const label = this.$te(key) ? this.$tc(key, 2) : this.type.name
    return this.$t('unnamedMembers.label', { role_name: label.toLowerCase() }) as string
  }

  get nextIncrement() {
    return this.internal! < this.max ? 1 : 0
  }

  get nextDecrement() {
    return this.internal! > this.min ? 1 : 0
  }

  get countLabel() {
    return this.$tc('unnamedMembers.countLabel', this.internal ?? 0, { name: this.type.name })
  }
}
