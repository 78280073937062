export default [
  {
    countryName: 'Afghanistan',
    countryShortCode: 'AF',
    regions: [],
    _lowerCode: 'af',
  },
  {
    countryName: 'Åland Islands',
    countryShortCode: 'AX',
    regions: [],
    _lowerCode: 'ax',
  },
  {
    countryName: 'Albania',
    countryShortCode: 'AL',
    regions: [],
    _lowerCode: 'al',
  },
  {
    countryName: 'Algeria',
    countryShortCode: 'DZ',
    regions: [],
    _lowerCode: 'dz',
  },
  {
    countryName: 'American Samoa',
    countryShortCode: 'AS',
    regions: [],
    _lowerCode: 'as',
  },
  {
    countryName: 'Andorra',
    countryShortCode: 'AD',
    regions: [],
    _lowerCode: 'ad',
  },
  {
    countryName: 'Angola',
    countryShortCode: 'AO',
    regions: [],
    _lowerCode: 'ao',
  },
  {
    countryName: 'Anguilla',
    countryShortCode: 'AI',
    regions: [],
    _lowerCode: 'ai',
  },
  {
    countryName: 'Antarctica',
    countryShortCode: 'AQ',
    regions: [],
    _lowerCode: 'aq',
  },
  {
    countryName: 'Antigua and Barbuda',
    countryShortCode: 'AG',
    regions: [],
    _lowerCode: 'ag',
  },
  {
    countryName: 'Argentina',
    countryShortCode: 'AR',
    regions: [],
    _lowerCode: 'ar',
  },
  {
    countryName: 'Armenia',
    countryShortCode: 'AM',
    regions: [],
    _lowerCode: 'am',
  },
  {
    countryName: 'Aruba',
    countryShortCode: 'AW',
    regions: [],
    _lowerCode: 'aw',
  },
  {
    countryName: 'Australia',
    countryShortCode: 'AU',
    regions: [
      {
        name: 'Australian Capital Territory',
        shortCode: 'ACT',
      },
      {
        name: 'New South Wales',
        shortCode: 'NSW',
      },
      {
        name: 'Northern Territory',
        shortCode: 'NT',
      },
      {
        name: 'Queensland',
        shortCode: 'QLD',
      },
      {
        name: 'South Australia',
        shortCode: 'SA',
      },
      {
        name: 'Tasmania',
        shortCode: 'TAS',
      },
      {
        name: 'Victoria',
        shortCode: 'VIC',
      },
      {
        name: 'Western Australia',
        shortCode: 'WA',
      },
    ],
    _lowerCode: 'au',
  },
  {
    countryName: 'Austria',
    countryShortCode: 'AT',
    regions: [],
    _lowerCode: 'at',
  },
  {
    countryName: 'Azerbaijan',
    countryShortCode: 'AZ',
    regions: [],
    _lowerCode: 'az',
  },
  {
    countryName: 'Bahamas',
    countryShortCode: 'BS',
    regions: [],
    _lowerCode: 'bs',
  },
  {
    countryName: 'Bahrain',
    countryShortCode: 'BH',
    regions: [],
    _lowerCode: 'bh',
  },
  {
    countryName: 'Bangladesh',
    countryShortCode: 'BD',
    regions: [],
    _lowerCode: 'bd',
  },
  {
    countryName: 'Barbados',
    countryShortCode: 'BB',
    regions: [],
    _lowerCode: 'bb',
  },
  {
    countryName: 'Belarus',
    countryShortCode: 'BY',
    regions: [],
    _lowerCode: 'by',
  },
  {
    countryName: 'Belgium',
    countryShortCode: 'BE',
    regions: [],
    _lowerCode: 'be',
  },
  {
    countryName: 'Belize',
    countryShortCode: 'BZ',
    regions: [],
    _lowerCode: 'bz',
  },
  {
    countryName: 'Benin',
    countryShortCode: 'BJ',
    regions: [],
    _lowerCode: 'bj',
  },
  {
    countryName: 'Bermuda',
    countryShortCode: 'BM',
    regions: [],
    _lowerCode: 'bm',
  },
  {
    countryName: 'Bhutan',
    countryShortCode: 'BT',
    regions: [],
    _lowerCode: 'bt',
  },
  {
    countryName: 'Bolivia',
    countryShortCode: 'BO',
    regions: [],
    _lowerCode: 'bo',
  },
  {
    countryName: 'Bonaire, Sint Eustatius and Saba',
    countryShortCode: 'BQ',
    regions: [],
    _lowerCode: 'bq',
  },
  {
    countryName: 'Bosnia and Herzegovina',
    countryShortCode: 'BA',
    regions: [],
    _lowerCode: 'ba',
  },
  {
    countryName: 'Botswana',
    countryShortCode: 'BW',
    regions: [],
    _lowerCode: 'bw',
  },
  {
    countryName: 'Bouvet Island',
    countryShortCode: 'BV',
    regions: [],
    _lowerCode: 'bv',
  },
  {
    countryName: 'Brazil',
    countryShortCode: 'BR',
    regions: [],
    _lowerCode: 'br',
  },
  {
    countryName: 'British Indian Ocean Territory',
    countryShortCode: 'IO',
    regions: [],
    _lowerCode: 'io',
  },
  {
    countryName: 'Brunei Darussalam',
    countryShortCode: 'BN',
    regions: [],
    _lowerCode: 'bn',
  },
  {
    countryName: 'Bulgaria',
    countryShortCode: 'BG',
    regions: [],
    _lowerCode: 'bg',
  },
  {
    countryName: 'Burkina Faso',
    countryShortCode: 'BF',
    regions: [],
    _lowerCode: 'bf',
  },
  {
    countryName: 'Burundi',
    countryShortCode: 'BI',
    regions: [],
    _lowerCode: 'bi',
  },
  {
    countryName: 'Cambodia',
    countryShortCode: 'KH',
    regions: [],
    _lowerCode: 'kh',
  },
  {
    countryName: 'Cameroon',
    countryShortCode: 'CM',
    regions: [],
    _lowerCode: 'cm',
  },
  {
    countryName: 'Canada',
    countryShortCode: 'CA',
    regions: [
      {
        name: 'Alberta',
        shortCode: 'AB',
      },
      {
        name: 'British Columbia',
        shortCode: 'BC',
      },
      {
        name: 'Manitoba',
        shortCode: 'MB',
      },
      {
        name: 'New Brunswick',
        shortCode: 'NB',
      },
      {
        name: 'Newfoundland and Labrador',
        shortCode: 'NL',
      },
      {
        name: 'Northwest Territories',
        shortCode: 'NT',
      },
      {
        name: 'Nova Scotia',
        shortCode: 'NS',
      },
      {
        name: 'Nunavut',
        shortCode: 'NU',
      },
      {
        name: 'Ontario',
        shortCode: 'ON',
      },
      {
        name: 'Prince Edward Island',
        shortCode: 'PE',
      },
      {
        name: 'Quebec',
        shortCode: 'QC',
      },
      {
        name: 'Saskatchewan',
        shortCode: 'SK',
      },
      {
        name: 'Yukon',
        shortCode: 'YT',
      },
    ],
    _lowerCode: 'ca',
  },
  {
    countryName: 'Cape Verde',
    countryShortCode: 'CV',
    regions: [],
    _lowerCode: 'cv',
  },
  {
    countryName: 'Cayman Islands',
    countryShortCode: 'KY',
    regions: [],
    _lowerCode: 'ky',
  },
  {
    countryName: 'Central African Republic',
    countryShortCode: 'CF',
    regions: [],
    _lowerCode: 'cf',
  },
  {
    countryName: 'Chad',
    countryShortCode: 'TD',
    regions: [],
    _lowerCode: 'td',
  },
  {
    countryName: 'Chile',
    countryShortCode: 'CL',
    regions: [],
    _lowerCode: 'cl',
  },
  {
    countryName: 'China',
    countryShortCode: 'CN',
    regions: [],
    _lowerCode: 'cn',
  },
  {
    countryName: 'Christmas Island',
    countryShortCode: 'CX',
    regions: [],
    _lowerCode: 'cx',
  },
  {
    countryName: 'Cocos (Keeling) Islands',
    countryShortCode: 'CC',
    regions: [],
    _lowerCode: 'cc',
  },
  {
    countryName: 'Colombia',
    countryShortCode: 'CO',
    regions: [],
    _lowerCode: 'co',
  },
  {
    countryName: 'Comoros',
    countryShortCode: 'KM',
    regions: [],
    _lowerCode: 'km',
  },
  {
    countryName: 'Congo, Republic of the (Brazzaville)',
    countryShortCode: 'CG',
    regions: [],
    _lowerCode: 'cg',
  },
  {
    countryName: 'Congo, the Democratic Republic of the (Kinshasa)',
    countryShortCode: 'CD',
    regions: [],
    _lowerCode: 'cd',
  },
  {
    countryName: 'Cook Islands',
    countryShortCode: 'CK',
    regions: [],
    _lowerCode: 'ck',
  },
  {
    countryName: 'Costa Rica',
    countryShortCode: 'CR',
    regions: [],
    _lowerCode: 'cr',
  },
  {
    countryName: "Côte d'Ivoire, Republic of",
    countryShortCode: 'CI',
    regions: [],
    _lowerCode: 'ci',
  },
  {
    countryName: 'Croatia',
    countryShortCode: 'HR',
    regions: [],
    _lowerCode: 'hr',
  },
  {
    countryName: 'Cuba',
    countryShortCode: 'CU',
    regions: [],
    _lowerCode: 'cu',
  },
  {
    countryName: 'Curaçao',
    countryShortCode: 'CW',
    regions: [],
    _lowerCode: 'cw',
  },
  {
    countryName: 'Cyprus',
    countryShortCode: 'CY',
    regions: [],
    _lowerCode: 'cy',
  },
  {
    countryName: 'Czech Republic',
    countryShortCode: 'CZ',
    regions: [],
    _lowerCode: 'cz',
  },
  {
    countryName: 'Denmark',
    countryShortCode: 'DK',
    regions: [],
    _lowerCode: 'dk',
  },
  {
    countryName: 'Djibouti',
    countryShortCode: 'DJ',
    regions: [],
    _lowerCode: 'dj',
  },
  {
    countryName: 'Dominica',
    countryShortCode: 'DM',
    regions: [],
    _lowerCode: 'dm',
  },
  {
    countryName: 'Dominican Republic',
    countryShortCode: 'DO',
    regions: [],
    _lowerCode: 'do',
  },
  {
    countryName: 'Ecuador',
    countryShortCode: 'EC',
    regions: [],
    _lowerCode: 'ec',
  },
  {
    countryName: 'Egypt',
    countryShortCode: 'EG',
    regions: [],
    _lowerCode: 'eg',
  },
  {
    countryName: 'El Salvador',
    countryShortCode: 'SV',
    regions: [],
    _lowerCode: 'sv',
  },
  {
    countryName: 'Equatorial Guinea',
    countryShortCode: 'GQ',
    regions: [],
    _lowerCode: 'gq',
  },
  {
    countryName: 'Eritrea',
    countryShortCode: 'ER',
    regions: [],
    _lowerCode: 'er',
  },
  {
    countryName: 'Estonia',
    countryShortCode: 'EE',
    regions: [],
    _lowerCode: 'ee',
  },
  {
    countryName: 'Ethiopia',
    countryShortCode: 'ET',
    regions: [],
    _lowerCode: 'et',
  },
  {
    countryName: 'Falkland Islands (Islas Malvinas)',
    countryShortCode: 'FK',
    regions: [],
    _lowerCode: 'fk',
  },
  {
    countryName: 'Faroe Islands',
    countryShortCode: 'FO',
    regions: [],
    _lowerCode: 'fo',
  },
  {
    countryName: 'Fiji',
    countryShortCode: 'FJ',
    regions: [],
    _lowerCode: 'fj',
  },
  {
    countryName: 'Finland',
    countryShortCode: 'FI',
    regions: [],
    _lowerCode: 'fi',
  },
  {
    countryName: 'France',
    countryShortCode: 'FR',
    regions: [],
    _lowerCode: 'fr',
  },
  {
    countryName: 'French Guiana',
    countryShortCode: 'GF',
    regions: [],
    _lowerCode: 'gf',
  },
  {
    countryName: 'French Polynesia',
    countryShortCode: 'PF',
    regions: [],
    _lowerCode: 'pf',
  },
  {
    countryName: 'French Southern and Antarctic Lands',
    countryShortCode: 'TF',
    regions: [],
    _lowerCode: 'tf',
  },
  {
    countryName: 'Gabon',
    countryShortCode: 'GA',
    regions: [],
    _lowerCode: 'ga',
  },
  {
    countryName: 'Gambia, The',
    countryShortCode: 'GM',
    regions: [],
    _lowerCode: 'gm',
  },
  {
    countryName: 'Georgia',
    countryShortCode: 'GE',
    regions: [],
    _lowerCode: 'ge',
  },
  {
    countryName: 'Germany',
    countryShortCode: 'DE',
    regions: [],
    _lowerCode: 'de',
  },
  {
    countryName: 'Ghana',
    countryShortCode: 'GH',
    regions: [],
    _lowerCode: 'gh',
  },
  {
    countryName: 'Gibraltar',
    countryShortCode: 'GI',
    regions: [],
    _lowerCode: 'gi',
  },
  {
    countryName: 'Greece',
    countryShortCode: 'GR',
    regions: [],
    _lowerCode: 'gr',
  },
  {
    countryName: 'Greenland',
    countryShortCode: 'GL',
    regions: [],
    _lowerCode: 'gl',
  },
  {
    countryName: 'Grenada',
    countryShortCode: 'GD',
    regions: [],
    _lowerCode: 'gd',
  },
  {
    countryName: 'Guadeloupe',
    countryShortCode: 'GP',
    regions: [],
    _lowerCode: 'gp',
  },
  {
    countryName: 'Guam',
    countryShortCode: 'GU',
    regions: [],
    _lowerCode: 'gu',
  },
  {
    countryName: 'Guatemala',
    countryShortCode: 'GT',
    regions: [],
    _lowerCode: 'gt',
  },
  {
    countryName: 'Guernsey',
    countryShortCode: 'GG',
    regions: [],
    _lowerCode: 'gg',
  },
  {
    countryName: 'Guinea',
    countryShortCode: 'GN',
    regions: [],
    _lowerCode: 'gn',
  },
  {
    countryName: 'Guinea-Bissau',
    countryShortCode: 'GW',
    regions: [],
    _lowerCode: 'gw',
  },
  {
    countryName: 'Guyana',
    countryShortCode: 'GY',
    regions: [],
    _lowerCode: 'gy',
  },
  {
    countryName: 'Haiti',
    countryShortCode: 'HT',
    regions: [],
    _lowerCode: 'ht',
  },
  {
    countryName: 'Heard Island and McDonald Islands',
    countryShortCode: 'HM',
    regions: [],
    _lowerCode: 'hm',
  },
  {
    countryName: 'Holy See (Vatican City)',
    countryShortCode: 'VA',
    regions: [],
    _lowerCode: 'va',
  },
  {
    countryName: 'Honduras',
    countryShortCode: 'HN',
    regions: [],
    _lowerCode: 'hn',
  },
  {
    countryName: 'Hong Kong',
    countryShortCode: 'HK',
    regions: [],
    _lowerCode: 'hk',
  },
  {
    countryName: 'Hungary',
    countryShortCode: 'HU',
    regions: [],
    _lowerCode: 'hu',
  },
  {
    countryName: 'Iceland',
    countryShortCode: 'IS',
    regions: [],
    _lowerCode: 'is',
  },
  {
    countryName: 'India',
    countryShortCode: 'IN',
    regions: [],
    _lowerCode: 'in',
  },
  {
    countryName: 'Indonesia',
    countryShortCode: 'ID',
    regions: [],
    _lowerCode: 'id',
  },
  {
    countryName: 'Iran, Islamic Republic of',
    countryShortCode: 'IR',
    regions: [],
    _lowerCode: 'ir',
  },
  {
    countryName: 'Iraq',
    countryShortCode: 'IQ',
    regions: [],
    _lowerCode: 'iq',
  },
  {
    countryName: 'Ireland',
    countryShortCode: 'IE',
    regions: [],
    _lowerCode: 'ie',
  },
  {
    countryName: 'Isle of Man',
    countryShortCode: 'IM',
    regions: [],
    _lowerCode: 'im',
  },
  {
    countryName: 'Israel',
    countryShortCode: 'IL',
    regions: [],
    _lowerCode: 'il',
  },
  {
    countryName: 'Italy',
    countryShortCode: 'IT',
    regions: [],
    _lowerCode: 'it',
  },
  {
    countryName: 'Jamaica',
    countryShortCode: 'JM',
    regions: [],
    _lowerCode: 'jm',
  },
  {
    countryName: 'Japan',
    countryShortCode: 'JP',
    regions: [],
    _lowerCode: 'jp',
  },
  {
    countryName: 'Jersey',
    countryShortCode: 'JE',
    regions: [],
    _lowerCode: 'je',
  },
  {
    countryName: 'Jordan',
    countryShortCode: 'JO',
    regions: [],
    _lowerCode: 'jo',
  },
  {
    countryName: 'Kazakhstan',
    countryShortCode: 'KZ',
    regions: [],
    _lowerCode: 'kz',
  },
  {
    countryName: 'Kenya',
    countryShortCode: 'KE',
    regions: [],
    _lowerCode: 'ke',
  },
  {
    countryName: 'Kiribati',
    countryShortCode: 'KI',
    regions: [],
    _lowerCode: 'ki',
  },
  {
    countryName: "Korea, Democratic People's Republic of",
    countryShortCode: 'KP',
    regions: [],
    _lowerCode: 'kp',
  },
  {
    countryName: 'Korea, Republic of',
    countryShortCode: 'KR',
    regions: [],
    _lowerCode: 'kr',
  },
  {
    countryName: 'Kuwait',
    countryShortCode: 'KW',
    regions: [],
    _lowerCode: 'kw',
  },
  {
    countryName: 'Kyrgyzstan',
    countryShortCode: 'KG',
    regions: [],
    _lowerCode: 'kg',
  },
  {
    countryName: 'Laos',
    countryShortCode: 'LA',
    regions: [],
    _lowerCode: 'la',
  },
  {
    countryName: 'Latvia',
    countryShortCode: 'LV',
    regions: [],
    _lowerCode: 'lv',
  },
  {
    countryName: 'Lebanon',
    countryShortCode: 'LB',
    regions: [],
    _lowerCode: 'lb',
  },
  {
    countryName: 'Lesotho',
    countryShortCode: 'LS',
    regions: [],
    _lowerCode: 'ls',
  },
  {
    countryName: 'Liberia',
    countryShortCode: 'LR',
    regions: [],
    _lowerCode: 'lr',
  },
  {
    countryName: 'Libya',
    countryShortCode: 'LY',
    regions: [],
    _lowerCode: 'ly',
  },
  {
    countryName: 'Liechtenstein',
    countryShortCode: 'LI',
    regions: [],
    _lowerCode: 'li',
  },
  {
    countryName: 'Lithuania',
    countryShortCode: 'LT',
    regions: [],
    _lowerCode: 'lt',
  },
  {
    countryName: 'Luxembourg',
    countryShortCode: 'LU',
    regions: [],
    _lowerCode: 'lu',
  },
  {
    countryName: 'Macao',
    countryShortCode: 'MO',
    regions: [],
    _lowerCode: 'mo',
  },
  {
    countryName: 'Macedonia, Republic of',
    countryShortCode: 'MK',
    regions: [],
    _lowerCode: 'mk',
  },
  {
    countryName: 'Madagascar',
    countryShortCode: 'MG',
    regions: [],
    _lowerCode: 'mg',
  },
  {
    countryName: 'Malawi',
    countryShortCode: 'MW',
    regions: [],
    _lowerCode: 'mw',
  },
  {
    countryName: 'Malaysia',
    countryShortCode: 'MY',
    regions: [],
    _lowerCode: 'my',
  },
  {
    countryName: 'Maldives',
    countryShortCode: 'MV',
    regions: [],
    _lowerCode: 'mv',
  },
  {
    countryName: 'Mali',
    countryShortCode: 'ML',
    regions: [],
    _lowerCode: 'ml',
  },
  {
    countryName: 'Malta',
    countryShortCode: 'MT',
    regions: [],
    _lowerCode: 'mt',
  },
  {
    countryName: 'Marshall Islands',
    countryShortCode: 'MH',
    regions: [],
    _lowerCode: 'mh',
  },
  {
    countryName: 'Martinique',
    countryShortCode: 'MQ',
    regions: [],
    _lowerCode: 'mq',
  },
  {
    countryName: 'Mauritania',
    countryShortCode: 'MR',
    regions: [],
    _lowerCode: 'mr',
  },
  {
    countryName: 'Mauritius',
    countryShortCode: 'MU',
    regions: [],
    _lowerCode: 'mu',
  },
  {
    countryName: 'Mayotte',
    countryShortCode: 'YT',
    regions: [],
    _lowerCode: 'yt',
  },
  {
    countryName: 'Mexico',
    countryShortCode: 'MX',
    regions: [],
    _lowerCode: 'mx',
  },
  {
    countryName: 'Micronesia, Federated States of',
    countryShortCode: 'FM',
    regions: [],
    _lowerCode: 'fm',
  },
  {
    countryName: 'Moldova',
    countryShortCode: 'MD',
    regions: [],
    _lowerCode: 'md',
  },
  {
    countryName: 'Monaco',
    countryShortCode: 'MC',
    regions: [],
    _lowerCode: 'mc',
  },
  {
    countryName: 'Mongolia',
    countryShortCode: 'MN',
    regions: [],
    _lowerCode: 'mn',
  },
  {
    countryName: 'Montenegro',
    countryShortCode: 'ME',
    regions: [],
    _lowerCode: 'me',
  },
  {
    countryName: 'Montserrat',
    countryShortCode: 'MS',
    regions: [],
    _lowerCode: 'ms',
  },
  {
    countryName: 'Morocco',
    countryShortCode: 'MA',
    regions: [],
    _lowerCode: 'ma',
  },
  {
    countryName: 'Mozambique',
    countryShortCode: 'MZ',
    regions: [],
    _lowerCode: 'mz',
  },
  {
    countryName: 'Myanmar',
    countryShortCode: 'MM',
    regions: [],
    _lowerCode: 'mm',
  },
  {
    countryName: 'Namibia',
    countryShortCode: 'NA',
    regions: [],
    _lowerCode: 'na',
  },
  {
    countryName: 'Nauru',
    countryShortCode: 'NR',
    regions: [],
    _lowerCode: 'nr',
  },
  {
    countryName: 'Nepal',
    countryShortCode: 'NP',
    regions: [],
    _lowerCode: 'np',
  },
  {
    countryName: 'Netherlands',
    countryShortCode: 'NL',
    regions: [],
    _lowerCode: 'nl',
  },
  {
    countryName: 'New Caledonia',
    countryShortCode: 'NC',
    regions: [],
    _lowerCode: 'nc',
  },
  {
    countryName: 'New Zealand',
    countryShortCode: 'NZ',
    regions: [],
    _lowerCode: 'nz',
  },
  {
    countryName: 'Nicaragua',
    countryShortCode: 'NI',
    regions: [],
    _lowerCode: 'ni',
  },
  {
    countryName: 'Niger',
    countryShortCode: 'NE',
    regions: [],
    _lowerCode: 'ne',
  },
  {
    countryName: 'Nigeria',
    countryShortCode: 'NG',
    regions: [],
    _lowerCode: 'ng',
  },
  {
    countryName: 'Niue',
    countryShortCode: 'NU',
    regions: [],
    _lowerCode: 'nu',
  },
  {
    countryName: 'Norfolk Island',
    countryShortCode: 'NF',
    regions: [],
    _lowerCode: 'nf',
  },
  {
    countryName: 'Northern Mariana Islands',
    countryShortCode: 'MP',
    regions: [],
    _lowerCode: 'mp',
  },
  {
    countryName: 'Norway',
    countryShortCode: 'NO',
    regions: [],
    _lowerCode: 'no',
  },
  {
    countryName: 'Oman',
    countryShortCode: 'OM',
    regions: [],
    _lowerCode: 'om',
  },
  {
    countryName: 'Pakistan',
    countryShortCode: 'PK',
    regions: [],
    _lowerCode: 'pk',
  },
  {
    countryName: 'Palau',
    countryShortCode: 'PW',
    regions: [],
    _lowerCode: 'pw',
  },
  {
    countryName: 'Palestine, State of',
    countryShortCode: 'PS',
    regions: [],
    _lowerCode: 'ps',
  },
  {
    countryName: 'Panama',
    countryShortCode: 'PA',
    regions: [],
    _lowerCode: 'pa',
  },
  {
    countryName: 'Papua New Guinea',
    countryShortCode: 'PG',
    regions: [],
    _lowerCode: 'pg',
  },
  {
    countryName: 'Paraguay',
    countryShortCode: 'PY',
    regions: [],
    _lowerCode: 'py',
  },
  {
    countryName: 'Peru',
    countryShortCode: 'PE',
    regions: [],
    _lowerCode: 'pe',
  },
  {
    countryName: 'Philippines',
    countryShortCode: 'PH',
    regions: [],
    _lowerCode: 'ph',
  },
  {
    countryName: 'Pitcairn',
    countryShortCode: 'PN',
    regions: [],
    _lowerCode: 'pn',
  },
  {
    countryName: 'Poland',
    countryShortCode: 'PL',
    regions: [],
    _lowerCode: 'pl',
  },
  {
    countryName: 'Portugal',
    countryShortCode: 'PT',
    regions: [],
    _lowerCode: 'pt',
  },
  {
    countryName: 'Puerto Rico',
    countryShortCode: 'PR',
    regions: [],
    _lowerCode: 'pr',
  },
  {
    countryName: 'Qatar',
    countryShortCode: 'QA',
    regions: [],
    _lowerCode: 'qa',
  },
  {
    countryName: 'Réunion',
    countryShortCode: 'RE',
    regions: [],
    _lowerCode: 're',
  },
  {
    countryName: 'Romania',
    countryShortCode: 'RO',
    regions: [],
    _lowerCode: 'ro',
  },
  {
    countryName: 'Russian Federation',
    countryShortCode: 'RU',
    regions: [],
    _lowerCode: 'ru',
  },
  {
    countryName: 'Rwanda',
    countryShortCode: 'RW',
    regions: [],
    _lowerCode: 'rw',
  },
  {
    countryName: 'Saint Barthélemy',
    countryShortCode: 'BL',
    regions: [],
    _lowerCode: 'bl',
  },
  {
    countryName: 'Saint Helena, Ascension and Tristan da Cunha',
    countryShortCode: 'SH',
    regions: [],
    _lowerCode: 'sh',
  },
  {
    countryName: 'Saint Kitts and Nevis',
    countryShortCode: 'KN',
    regions: [],
    _lowerCode: 'kn',
  },
  {
    countryName: 'Saint Lucia',
    countryShortCode: 'LC',
    regions: [],
    _lowerCode: 'lc',
  },
  {
    countryName: 'Saint Martin',
    countryShortCode: 'MF',
    regions: [],
    _lowerCode: 'mf',
  },
  {
    countryName: 'Saint Pierre and Miquelon',
    countryShortCode: 'PM',
    regions: [],
    _lowerCode: 'pm',
  },
  {
    countryName: 'Saint Vincent and the Grenadines',
    countryShortCode: 'VC',
    regions: [],
    _lowerCode: 'vc',
  },
  {
    countryName: 'Samoa',
    countryShortCode: 'WS',
    regions: [],
    _lowerCode: 'ws',
  },
  {
    countryName: 'San Marino',
    countryShortCode: 'SM',
    regions: [],
    _lowerCode: 'sm',
  },
  {
    countryName: 'Sao Tome and Principe',
    countryShortCode: 'ST',
    regions: [],
    _lowerCode: 'st',
  },
  {
    countryName: 'Saudi Arabia',
    countryShortCode: 'SA',
    regions: [],
    _lowerCode: 'sa',
  },
  {
    countryName: 'Senegal',
    countryShortCode: 'SN',
    regions: [],
    _lowerCode: 'sn',
  },
  {
    countryName: 'Serbia',
    countryShortCode: 'RS',
    regions: [],
    _lowerCode: 'rs',
  },
  {
    countryName: 'Seychelles',
    countryShortCode: 'SC',
    regions: [],
    _lowerCode: 'sc',
  },
  {
    countryName: 'Sierra Leone',
    countryShortCode: 'SL',
    regions: [],
    _lowerCode: 'sl',
  },
  {
    countryName: 'Singapore',
    countryShortCode: 'SG',
    regions: [],
    _lowerCode: 'sg',
  },
  {
    countryName: 'Sint Maarten (Dutch part)',
    countryShortCode: 'SX',
    regions: [],
    _lowerCode: 'sx',
  },
  {
    countryName: 'Slovakia',
    countryShortCode: 'SK',
    regions: [],
    _lowerCode: 'sk',
  },
  {
    countryName: 'Slovenia',
    countryShortCode: 'SI',
    regions: [],
    _lowerCode: 'si',
  },
  {
    countryName: 'Solomon Islands',
    countryShortCode: 'SB',
    regions: [],
    _lowerCode: 'sb',
  },
  {
    countryName: 'Somalia',
    countryShortCode: 'SO',
    regions: [],
    _lowerCode: 'so',
  },
  {
    countryName: 'South Africa',
    countryShortCode: 'ZA',
    regions: [],
    _lowerCode: 'za',
  },
  {
    countryName: 'South Georgia and South Sandwich Islands',
    countryShortCode: 'GS',
    regions: [],
    _lowerCode: 'gs',
  },
  {
    countryName: 'South Sudan',
    countryShortCode: 'SS',
    regions: [],
    _lowerCode: 'ss',
  },
  {
    countryName: 'Spain',
    countryShortCode: 'ES',
    regions: [],
    _lowerCode: 'es',
  },
  {
    countryName: 'Sri Lanka',
    countryShortCode: 'LK',
    regions: [],
    _lowerCode: 'lk',
  },
  {
    countryName: 'Sudan',
    countryShortCode: 'SD',
    regions: [],
    _lowerCode: 'sd',
  },
  {
    countryName: 'Suriname',
    countryShortCode: 'SR',
    regions: [],
    _lowerCode: 'sr',
  },
  {
    countryName: 'Swaziland',
    countryShortCode: 'SZ',
    regions: [],
    _lowerCode: 'sz',
  },
  {
    countryName: 'Sweden',
    countryShortCode: 'SE',
    regions: [],
    _lowerCode: 'se',
  },
  {
    countryName: 'Switzerland',
    countryShortCode: 'CH',
    regions: [],
    _lowerCode: 'ch',
  },
  {
    countryName: 'Syrian Arab Republic',
    countryShortCode: 'SY',
    regions: [],
    _lowerCode: 'sy',
  },
  {
    countryName: 'Taiwan',
    countryShortCode: 'TW',
    regions: [],
    _lowerCode: 'tw',
  },
  {
    countryName: 'Tajikistan',
    countryShortCode: 'TJ',
    regions: [],
    _lowerCode: 'tj',
  },
  {
    countryName: 'Tanzania, United Republic of',
    countryShortCode: 'TZ',
    regions: [],
    _lowerCode: 'tz',
  },
  {
    countryName: 'Thailand',
    countryShortCode: 'TH',
    regions: [],
    _lowerCode: 'th',
  },
  {
    countryName: 'Timor-Leste',
    countryShortCode: 'TL',
    regions: [],
    _lowerCode: 'tl',
  },
  {
    countryName: 'Togo',
    countryShortCode: 'TG',
    regions: [],
    _lowerCode: 'tg',
  },
  {
    countryName: 'Tokelau',
    countryShortCode: 'TK',
    regions: [],
    _lowerCode: 'tk',
  },
  {
    countryName: 'Tonga',
    countryShortCode: 'TO',
    regions: [],
    _lowerCode: 'to',
  },
  {
    countryName: 'Trinidad and Tobago',
    countryShortCode: 'TT',
    regions: [],
    _lowerCode: 'tt',
  },
  {
    countryName: 'Tunisia',
    countryShortCode: 'TN',
    regions: [],
    _lowerCode: 'tn',
  },
  {
    countryName: 'Turkey',
    countryShortCode: 'TR',
    regions: [],
    _lowerCode: 'tr',
  },
  {
    countryName: 'Turkmenistan',
    countryShortCode: 'TM',
    regions: [],
    _lowerCode: 'tm',
  },
  {
    countryName: 'Turks and Caicos Islands',
    countryShortCode: 'TC',
    regions: [],
    _lowerCode: 'tc',
  },
  {
    countryName: 'Tuvalu',
    countryShortCode: 'TV',
    regions: [],
    _lowerCode: 'tv',
  },
  {
    countryName: 'Uganda',
    countryShortCode: 'UG',
    regions: [],
    _lowerCode: 'ug',
  },
  {
    countryName: 'Ukraine',
    countryShortCode: 'UA',
    regions: [],
    _lowerCode: 'ua',
  },
  {
    countryName: 'United Arab Emirates',
    countryShortCode: 'AE',
    regions: [],
    _lowerCode: 'ae',
  },
  {
    countryName: 'United Kingdom',
    countryShortCode: 'GB',
    regions: [],
    _lowerCode: 'gb',
  },
  {
    countryName: 'United States',
    countryShortCode: 'US',
    regions: [
      {
        name: 'Alabama',
        shortCode: 'AL',
      },
      {
        name: 'Alaska',
        shortCode: 'AK',
      },
      {
        name: 'American Samoa',
        shortCode: 'AS',
      },
      {
        name: 'Arizona',
        shortCode: 'AZ',
      },
      {
        name: 'Arkansas',
        shortCode: 'AR',
      },
      {
        name: 'California',
        shortCode: 'CA',
      },
      {
        name: 'Colorado',
        shortCode: 'CO',
      },
      {
        name: 'Connecticut',
        shortCode: 'CT',
      },
      {
        name: 'Delaware',
        shortCode: 'DE',
      },
      {
        name: 'District of Columbia',
        shortCode: 'DC',
      },
      {
        name: 'Micronesia',
        shortCode: 'FM',
      },
      {
        name: 'Florida',
        shortCode: 'FL',
      },
      {
        name: 'Georgia',
        shortCode: 'GA',
      },
      {
        name: 'Guam',
        shortCode: 'GU',
      },
      {
        name: 'Hawaii',
        shortCode: 'HI',
      },
      {
        name: 'Idaho',
        shortCode: 'ID',
      },
      {
        name: 'Illinois',
        shortCode: 'IL',
      },
      {
        name: 'Indiana',
        shortCode: 'IN',
      },
      {
        name: 'Iowa',
        shortCode: 'IA',
      },
      {
        name: 'Kansas',
        shortCode: 'KS',
      },
      {
        name: 'Kentucky',
        shortCode: 'KY',
      },
      {
        name: 'Louisiana',
        shortCode: 'LA',
      },
      {
        name: 'Maine',
        shortCode: 'ME',
      },
      {
        name: 'Marshall Islands',
        shortCode: 'MH',
      },
      {
        name: 'Maryland',
        shortCode: 'MD',
      },
      {
        name: 'Massachusetts',
        shortCode: 'MA',
      },
      {
        name: 'Michigan',
        shortCode: 'MI',
      },
      {
        name: 'Minnesota',
        shortCode: 'MN',
      },
      {
        name: 'Mississippi',
        shortCode: 'MS',
      },
      {
        name: 'Missouri',
        shortCode: 'MO',
      },
      {
        name: 'Montana',
        shortCode: 'MT',
      },
      {
        name: 'Nebraska',
        shortCode: 'NE',
      },
      {
        name: 'Nevada',
        shortCode: 'NV',
      },
      {
        name: 'New Hampshire',
        shortCode: 'NH',
      },
      {
        name: 'New Jersey',
        shortCode: 'NJ',
      },
      {
        name: 'New Mexico',
        shortCode: 'NM',
      },
      {
        name: 'New York',
        shortCode: 'NY',
      },
      {
        name: 'North Carolina',
        shortCode: 'NC',
      },
      {
        name: 'North Dakota',
        shortCode: 'ND',
      },
      {
        name: 'Northern Mariana Islands',
        shortCode: 'MP',
      },
      {
        name: 'Ohio',
        shortCode: 'OH',
      },
      {
        name: 'Oklahoma',
        shortCode: 'OK',
      },
      {
        name: 'Oregon',
        shortCode: 'OR',
      },
      {
        name: 'Palau',
        shortCode: 'PW',
      },
      {
        name: 'Pennsylvania',
        shortCode: 'PA',
      },
      {
        name: 'Puerto Rico',
        shortCode: 'PR',
      },
      {
        name: 'Rhode Island',
        shortCode: 'RI',
      },
      {
        name: 'South Carolina',
        shortCode: 'SC',
      },
      {
        name: 'South Dakota',
        shortCode: 'SD',
      },
      {
        name: 'Tennessee',
        shortCode: 'TN',
      },
      {
        name: 'Texas',
        shortCode: 'TX',
      },
      {
        name: 'Utah',
        shortCode: 'UT',
      },
      {
        name: 'Vermont',
        shortCode: 'VT',
      },
      {
        name: 'Virgin Islands',
        shortCode: 'VI',
      },
      {
        name: 'Virginia',
        shortCode: 'VA',
      },
      {
        name: 'Washington',
        shortCode: 'WA',
      },
      {
        name: 'West Virginia',
        shortCode: 'WV',
      },
      {
        name: 'Wisconsin',
        shortCode: 'WI',
      },
      {
        name: 'Wyoming',
        shortCode: 'WY',
      },
      {
        name: 'Armed Forces Americas',
        shortCode: 'AA',
      },
      {
        name: 'Armed Forces Europe, Canada, Africa and Middle East',
        shortCode: 'AE',
      },
      {
        name: 'Armed Forces Pacific',
        shortCode: 'AP',
      },
    ],
    _lowerCode: 'us',
  },
  {
    countryName: 'United States Minor Outlying Islands',
    countryShortCode: 'UM',
    regions: [],
    _lowerCode: 'um',
  },
  {
    countryName: 'Uruguay',
    countryShortCode: 'UY',
    regions: [],
    _lowerCode: 'uy',
  },
  {
    countryName: 'Uzbekistan',
    countryShortCode: 'UZ',
    regions: [],
    _lowerCode: 'uz',
  },
  {
    countryName: 'Vanuatu',
    countryShortCode: 'VU',
    regions: [],
    _lowerCode: 'vu',
  },
  {
    countryName: 'Venezuela, Bolivarian Republic of',
    countryShortCode: 'VE',
    regions: [],
    _lowerCode: 've',
  },
  {
    countryName: 'Vietnam',
    countryShortCode: 'VN',
    regions: [],
    _lowerCode: 'vn',
  },
  {
    countryName: 'Virgin Islands, British',
    countryShortCode: 'VG',
    regions: [],
    _lowerCode: 'vg',
  },
  {
    countryName: 'Virgin Islands, U.S.',
    countryShortCode: 'VI',
    regions: [],
    _lowerCode: 'vi',
  },
  {
    countryName: 'Wallis and Futuna',
    countryShortCode: 'WF',
    regions: [],
    _lowerCode: 'wf',
  },
  {
    countryName: 'Western Sahara',
    countryShortCode: 'EH',
    regions: [],
    _lowerCode: 'eh',
  },
  {
    countryName: 'Yemen',
    countryShortCode: 'YE',
    regions: [],
    _lowerCode: 'ye',
  },
  {
    countryName: 'Zambia',
    countryShortCode: 'ZM',
    regions: [],
    _lowerCode: 'zm',
  },
  {
    countryName: 'Zimbabwe',
    countryShortCode: 'ZW',
    regions: [],
    _lowerCode: 'zw',
  },
]
