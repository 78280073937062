
import Price from '@/components/elements/Price.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import TixStepper from '@/components/forms/TixStepper.vue'
import { currencySymbol, formatCurrency } from '@/helpers/Currency'
import { languageItem } from '@/language/helpers'
import { useFlexiblePrice } from '@/helpers/TicketType'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { openDescriptionModal } from '@/modals/descriptionModal'
import { getTotalFees } from '@/helpers/Fees'
import type { LinkedTT } from '@/api/types/processedEntities'

@Component({
  name: 'TicketTypeSteppers',
  components: { Price, FormInput2, TixStepper },
})
export default class extends Vue {
  @Prop({ required: true })
  ticketType: LinkedTT

  @Prop({ required: true })
  value: QuantityAndPrice

  @Prop({ required: true })
  remainingCapacity: number

  /**
   * Must be at least one.
   */
  @Prop({ required: true })
  minQuantity: number

  @Prop({ default: false })
  exactlyOneTicket: boolean

  @Prop()
  soldOut: boolean

  @Prop({ default: false })
  giftOfMembership: boolean

  @Prop({ default: false })
  showAdvertisedFees: boolean

  selectedPrice: string | null = null
  priceValidationError: string | null = null

  l = languageItem('cartWidget')

  // Used for variable pricing tickets.
  get price(): number {
    return parseFloat(this.ticketType.currency_amount)
  }

  // Used for regular priced tickets.
  get priceWithFees(): number {
    return parseFloat(this.ticketType.currency_amount) + this.totalFees
  }

  get discountedPrice(): number | null {
    const discount = this.ticketType.discounted_amount
    if (discount != null) {
      return parseFloat(discount) + this.totalFees
    } else {
      return null
    }
  }

  get fees(): OutsideFee[] {
    const fees = this.ticketType.outside_fees
    if (this.showAdvertisedFees && fees?.length) {
      return fees
    }

    return []
  }

  get totalFees() {
    return getTotalFees(this.fees)
  }

  get formattedFees(): { fee_fixed_outside: string | null; fee_percent_outside: string | null } | null {
    if (this.totalFees === 0) {
      return null
    }

    const fees = {
      fee_fixed_outside: 0,
      fee_percent_outside: 0,
    }

    for (const fee of this.fees) {
      fees[fee.name] += parseFloat(fee.fee)
    }

    return {
      fee_fixed_outside: this.formatFee(fees.fee_fixed_outside, this.l.fixedFees),
      fee_percent_outside: this.formatFee(fees.fee_percent_outside, this.l.percentFees),
    }
  }

  formatFee(total, name): string | null {
    if (!total) return null
    return `${formatCurrency(total)} ${name}`
  }

  get fixedFees(): string | null {
    return this.formattedFees?.fee_fixed_outside ?? null
  }

  get percentFees(): string | null {
    return this.formattedFees?.fee_percent_outside ?? null
  }

  get hasDiscount(): boolean {
    return this.discountedPrice !== null
  }

  get useFlexiblePrice(): boolean {
    return useFlexiblePrice(this.ticketType)
  }

  onPriceValidation(error) {
    this.priceValidationError = error ? 'Value entered is outside min/max' : null
  }

  @Watch('value', { deep: true, immediate: true })
  onValueChange(value) {
    this.selectedPrice = value.price != null ? value.price : this.price
  }

  @Watch('selectedPrice')
  changePrice(inputAmount: string) {
    const value: TicketTypeIdAndPrice = {
      ticketTypeId: this.ticketType.id,
      price: inputAmount,
    }
    this.$emit('priceInput', value)
  }

  get nextIncrement(): number {
    if (this.remainingCapacity <= 0) {
      return 0
    } else if (this.value.quantity === 0) {
      return this.minQuantity
    } else {
      return 1
    }
  }

  get nextDecrement(): number {
    if (this.value.quantity === 0 || this.exactlyOneTicket) {
      return 0
    } else if (this.value.quantity === this.minQuantity) {
      return this.minQuantity
    } else {
      return 1
    }
  }

  get summary(): string | null {
    const { summary, description } = this.ticketType
    if (summary) {
      return summary
    } else if (description) {
      return description
    }
    return null
  }

  get description(): string | null {
    const { summary, description } = this.ticketType
    if (summary && description) {
      return description
    }
    return null
  }

  openDescriptionModal() {
    const isTicketTypeOfGom = this.giftOfMembership && this.ticketType.group?.handler === 'codes'
    const title = isTicketTypeOfGom ? `${this.ticketType.name} benefits` : ''

    openDescriptionModal(this.description!, title)
  }

  get buttonLabel(): string {
    return this.giftOfMembership ? (this.$t('membershipLevel.showMoreLabel') as string) : 'More'
  }

  get countLabel(): string {
    return this.$tc('ticketTypeSteppers.countLabel', this.value.quantity, { name: this.ticketType.name })
  }

  get currencySymbol() {
    return currencySymbol()
  }
}
