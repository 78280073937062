import { config } from '@/helpers/Environment'
import { TixTime } from '@/TixTime/TixTime'

export function areAnonymousOrderEditsEnabled() {
  const rules = config.self_edit_rules
  return rules?.enabled && rules.anon_editing
}

/**
 *
 * Both horizons are positive values - the larger of the 2 will be the time before the event after which you are unable to reschedule
 *
 * Both horizons are negative values - the smaller of the 2 will be the time after the event before which you are able to reschedule
 *
 * 1 positive & 1 negative value - the positive value will be the time after the event before which you are able to reschedule
 *
 * 24 hours and 3 days - can edit the event until 3 days before starting
 * -24 hours and -2 days - can edit the event up to 24 hours after starting
 * 24 hours and -7 days - can edit the event until 24 hours before stating
 * -24 hours and 0 days - can edit the event until it starts
 * 24 hours and 0 days - can edit the event up to 24 hours after starting
 *
 * See https://github.com/stqry/tix-api/blob/main/service/helpers.go#L53-L63
 */
export function isPastEditHorizon(start: string, timezone: string): boolean {
  const rules = config.self_edit_rules!
  const days = rules.horizon_days || 0
  const hours = rules.horizon_hours || 0

  const eventStart = new TixTime(start, timezone)
  const now = new TixTime(null, timezone)

  const dayHorizon = now.add(days, 'days').startOfDay()
  const hourHorizon = now.add(hours, 'hours')

  return eventStart.isBefore(dayHorizon) || eventStart.isBefore(hourHorizon)
}
