import { portal } from '@/helpers/Environment'
import { stripTrustedHTML } from '@/helpers/StringHelpers'
import { removeCookie } from 'tiny-cookie'

// TODO Create MathHelpers.ts for this and other functions?
export function sum(values: number[]) {
  return values.reduce((total, value) => total + value, 0)
}

export function removeParentCookie(cookieName) {
  removeCookie(cookieName)

  // Remove the cookie from all possible higher domains. Slice off the first
  // segment, which would be covered by the removeCookie above.
  const parts = window.location.hostname.split('.').slice(1)

  for (let i = 0; i < parts.length - 1; i++) {
    removeCookie(cookieName, { domain: parts.slice(i).join('.') })
  }
}

export function isMembershipEvent(event: EventTemplate, tickets: Ticket[]): boolean {
  return event.category === 'Membership' && tickets.some((tg) => tg.handler === 'membership')
}

export function debounce(func: Function, delay = 100) {
  let timer: number
  return function (event) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, delay, event)
  }
}

// TODO Move to shared lib ObjectHelpers.ts
export function setObjectPropertyByPath(object: object, path: string, value: any) {
  return path.split('.').reduce((o, p, i) => (o[p] = path.split('.').length === ++i ? value : o[p] || {}), object)
}

// TODO Move to shared lib ObjectHelpers.ts
export function getObjectPropertyByPath(object: object, path: string, defaultValue?: any): any {
  return path.split('.').reduce((o, p) => o?.[p] ?? defaultValue, object)
}

export function setDocumentTitle(title: string) {
  const parts = [stripTrustedHTML(title), '—', portal.name]
  document.title = parts.join(' ')
}

export function resetDocumentTitle() {
  document.title = portal.name
}
