
import FormInput2 from '@/components/forms/FormInput2.vue'
import { changeMemberDetailsInstructions } from '@/language/helpers'
import { memberHasNoEmailAddress } from '@/helpers/RequireMemberEmailAddress'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'

@Component({
  name: 'SetEmailAddressRoute',
  components: { FormInput2 },
})
export default class extends Vue {
  email = ''
  confirm = ''
  error: string | null = null

  previousRoute: Route

  beforeRouteEnter(to, from, next) {
    if (memberHasNoEmailAddress()) {
      next((vm) => (vm.previousRoute = from || { path: '/' }))
    } else {
      // User might have navigated here manually.
      next('/')
    }
  }

  handleSubmit() {
    this.error = this.validationErrorMessage

    if (!this.error) {
      this.apiRequest()
        .then(() => {
          this.$router.push({ ...this.previousRoute })
        })
        .catch((error) => {
          if (error.validationError) {
            this.error = error.message
          } else {
            throw error
          }
        })
    }
  }

  get validationErrorMessage() {
    const [, host] = this.email.split('@')
    if (host.split('.').length <= 1) {
      return 'Email address is not valid.'
    } else if (this.email !== this.confirm) {
      return 'Email addresses must match.'
    } else {
      // Form inputs are valid.
      return null
    }
  }

  apiRequest() {
    return store.dispatch('Member/updateUser', { email: this.email }).catch((error) => {
      if (error.response.status === 409) {
        const contactInstructions = changeMemberDetailsInstructions()
        throw {
          validationError: true,
          message: `Another user account is using <em>${this.email}</em>. Please contact us if you need to merge multiple user accounts. ${contactInstructions}`,
        }
      } else {
        // TODO Surface the server API message?
        // this.error = error.response.data._data[0]._description
        throw error
      }
    })
  }
}
