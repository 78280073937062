import type { DirectiveOptions } from 'vue'
import { Vue } from 'vue-property-decorator'

const focus: DirectiveOptions = {
  inserted: function (el, binding) {
    if (binding.value) {
      el.focus()
    }
  },
  update: function (el, binding) {
    if (binding.value) {
      Vue.nextTick(function () {
        el.focus()
      })
    }
  },
}

export default focus
