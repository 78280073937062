
import BooleanRadios from '@/components/forms/BooleanRadios.vue'
import AdmitFields from '@/components/membership/AdmitFields.vue'
import { toKebabCase, toSentenceCase } from '@/helpers/StringHelpers'
import type { LanguageStrings } from '@/language/types'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * Renders part of a membership form for named members for one membership rule.
 *
 * Membership rules have a min and max quantity of members. Members can be configured to be named or unnamed.
 * If named, they can be configured to require an email address, allow an optional email address, or have no email
 * address. So there is a large matrix of possible configurations depending on the following variables;
 *
 *   - 3 variations; min is 0, 1, or 2+
 *   - 3 variations; difference between min and max is 0, 1, or 2+
 *   - 4 variations;
 *     - anonymous/unnamed
 *     - named with no email address
 *     - named with optional email address
 *     - named with required email
 *
 * That gives 36 permutations (4x3x3), excluding variations in label/name.
 *
 * There may be membership roles with `named` configured to `optional`. However, this is interpreted as `required`.
 *
 * Some of those result in no form being rendered such as;
 * - min 0, max 0
 * - Unnamed member, min 1, max 1 (or any configuration where min == max)
 *
 * Some result in the same form being rendered, such as unnamed members with
 *   - min 4 and max 6
 *   - min 0 and max 2
 *
 * In both cases there is no form rendered for the required unnamed members, and there can be 0-2 additional members.
 *
 * Anonymous/unnamed members are handled by <UnnamedMembers> component. That is considerably more simple.
 *
 * @see <MembershipForm>
 */
@Component({
  name: 'NamedMembers',
  components: { BooleanRadios, AdmitFields },
})
export default class extends Vue {
  @Prop({ required: true, default: () => [] })
  value: MembershipAdmitDetails[]

  @Prop({ required: true })
  name: string

  @Prop({ required: true })
  type: TicketType

  @Prop({ required: true })
  requiredCount: number

  @Prop({ required: true })
  optionalCount: number

  @Prop({ default: 0 })
  currentCount: number

  @Prop({ required: true })
  emailRequired: MembershipAdmitDetailsConfig

  @Prop({ default: () => new Set() })
  contexts: Set<string>

  nameSingleOptional: boolean | null = null
  optionalSelectedCount: number = 0

  requiredDetails: MembershipAdmitDetails[] = []
  optionalDetails: MembershipAdmitDetails[] = []

  t: LanguageStrings['namedMembers']

  created() {
    // Deep copy this rather than just using this.value to avoid mutating a prop.
    const details = this.value.map((value) => ({ ...value }))
    this.requiredDetails = details.slice(0, this.requiredCount)
    this.optionalDetails = details.slice(this.requiredCount, this.requiredCount + this.optionalCount)
    this.optionalSelectedCount = Math.max(0, this.currentCount - this.requiredCount)
    this.nameSingleOptional = this.optionalSelectedCount === 1
  }

  @Emit('input')
  emit(): MembershipAdmitDetails[] {
    const result = [...this.requiredDetails]
    this.optionalDetails.forEach((details, index) => {
      result.push({
        ...details,
        disabled: index >= this.count,
      })
    })
    return result.map((details) => ({
      ...details,
      email: this.emailRequired !== 'no' ? details.email : undefined,
    }))
  }

  @Watch('nameSingleOptional')
  @Watch('optionalSelectedCount')
  optionalDetailsVisibilityChanged() {
    // Re-emit current values if optional details visibility changes.
    this.emit()
  }

  @Watch('type.id')
  typeChanged() {
    // Re-emit the current value if the Ticket Type changes.
    // This makes it easy for the parent component to maintain current values,
    // even when binding to a dictionary entry keyed by the ticket type ID.
    this.emit()
  }

  get count(): number {
    if (this.optionalCount > 1) {
      return this.optionalSelectedCount
    } else {
      return this.nameSingleOptional ? 1 : 0
    }
  }

  get singular(): string {
    return this.label(1)
  }

  get requiredLabel() {
    const summary = this.type.summary.trim()
    if (summary.length) {
      return summary
    }

    const singular = toSentenceCase(this.singular)
    return this.requiredCount > 1 ? `Please provide the names for each ${this.singular}.` : `${singular} name`
  }

  get optionalLabel() {
    const summary = this.type.summary.trim()
    if (this.requiredCount === 0 && summary.length) {
      return summary
    }

    return this.$t(this.labelKey, { role_name: this.roleName })
  }

  get roleName() {
    if (this.optionalCount === 1) {
      return this.singular
    } else {
      return this.label(2)
    }
  }

  get labelKey() {
    if (this.optionalCount === 1) {
      return this.requiredCount > 0 ? 'namedMembers.singleOptionalLabelAnother' : 'namedMembers.singleOptionalLabel'
    } else {
      return this.requiredCount > 0 ? 'namedMembers.multipleOptionalLabelMore' : 'namedMembers.multipleOptionalLabel'
    }
  }

  get roleClassName() {
    return toKebabCase(this.name)
  }

  label(count) {
    const key = `memberRoleLabels.${this.roleClassName}`
    return this.$te(key) ? this.$tc(key, count) : this.type.name
  }
}
