
import LoginForm from '@/components/forms/LoginForm.vue'
import { environment } from '@/helpers/Environment'
import { csvToArray } from '@/helpers/StringHelpers'
import { LanguageStrings } from '@/language/types'
import store from '@/store/store'
import { Component, Vue, Watch } from 'vue-property-decorator'

/**
 * This is used as a homepage to pre-sell memberships.
 */
@Component({
  name: 'HomeRoute',
  components: { LoginForm },
})
export default class extends Vue {
  t: LanguageStrings['homeRoute']

  beforeRouteEnter(to, from, next) {
    if (environment.web.membership_presell) {
      next()
    } else {
      const admissionEventId = environment.web.admission_event_id ?? ''
      next(`/events/${admissionEventId}`)
    }
  }

  created() {
    if (this.user) {
      this.proceed()
    }
  }

  get user() {
    return store.getters['Member/user']
  }

  get position(): Dict<string> {
    const positions = csvToArray('first, second, third')
    const config = environment.web.homepage_3cta_order
    return Object.fromEntries(
      csvToArray(config || 'login, join, guest').map((name, index) => [name, positions[index]]),
    )
  }

  proceed() {
    // Routes to /events if the admission_event_id is not set.
    const admissionEventId = environment.web.admission_event_id ?? ''
    this.$router.push('/events/' + admissionEventId)
  }

  @Watch('user')
  onLogin(value) {
    if (value) {
      this.proceed()
    }
  }
}
