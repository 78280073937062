
import { indexBy, indexItemsById } from '@/helpers/IndexHelpers'
import { getTicketGroupColor } from '@/helpers/TicketGroupsColors'
import type { AssignedSeat } from '@/seats/assignments'
import { ttToTGQuantities } from '@/seats/assignments'
import { initializeSeats, TTQuantities } from '@/seats/helpers'
import NeighboringSeatQueue from '@/seats/NeighboringSeatQueue'
import type Seat from '@/seats/Seat'
import type { Selection } from 'd3-selection'
import type { Transition } from 'd3-transition'
import type { ZoomBehavior } from 'd3-zoom'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { LinkedTG } from '@/api/types/processedEntities'

const maxZoom = 8
const minZoom = 1

@Component({ name: 'SeatMap' })
export default class SeatMap extends Vue {
  @Prop({ required: true })
  mapData: MapGeometry | null

  @Prop({ required: true })
  seatData: SeatEntity[]

  @Prop({ required: true })
  groups: LinkedTG[]

  @Prop({ required: true })
  filter: string | null

  @Prop({ required: true })
  quantities: TTQuantities

  @Prop({ required: true })
  value: AssignedSeat[] | null

  seats: Seat[] = []
  zoomBehavior: ZoomBehavior<Element, unknown> | null = null
  view: Selection<Element, unknown, any, any> | null = null
  currentZoom: number = 0
  d3: any = {}
  hoveredSeat: Seat | null = null
  assignedSeats: AssignedSeat[] | null = null
  pieKeys: string[]

  created() {
    // Asynchronously load d3 imports
    import('@/seats/D3').then(({ select, zoom, zoomTransform }) => {
      this.d3.select = select
      this.d3.zoom = zoom
      this.d3.zoomTransform = zoomTransform
      this.onLoad()
    })

    this.initializeSeats()
  }

  initializeSeats() {
    // The order here is critical.
    this.seats = initializeSeats(this.seatData, ttToTGQuantities(this.quantities, this.groups))
    this.pieKeys = this.getPieKeys()
    if (this.value) {
      this.assignedSeats = this.restoreSeatSelection(this.value) ?? null
      this.$forceUpdate()
    }
  }

  private restoreSeatSelection(oldSelection: AssignedSeat[]): AssignedSeat[] | void {
    // Index the new Seat instances by their name.
    const index = indexBy('name', this.seats)

    // Iterate the old selected Seat instances.
    for (const old of oldSelection) {
      // Find the new Seat instance by its name and select it.
      const seat = index[old.seat.name]
      if (seat.state === 'available') {
        const result = seat.select()
        if (result) {
          // The selection is complete. Quantities were probably reduced since the seats were last selected.
          return result
        }
      }
    }
  }

  get pieCharts(): Dictionary {
    const result = {}

    if (this.pieKeys) {
      const groups = indexItemsById(this.groups)
      // Size of the raster file that will be generated
      // Double the radius to get the diameter, multiply to support zoom, double again to support retina
      const size = this.largestSeatRadius * 2 * maxZoom * 2
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')!
      context.canvas.width = size
      context.canvas.height = size

      for (const chart of this.pieKeys) {
        context.clearRect(0, 0, size, size)
        const tgIDs = chart.split(',')
        // A start angle of 0 would start drawing at the 3 o'clock position, this sets the start angle to 6 o'clock position
        let startAngle = Math.PI * 0.5
        // Radian units are used to draw arcs on Canvas. A full circle is 2 * PI radians
        const sliceSize = (2 * Math.PI) / tgIDs.length

        for (const tgID of tgIDs) {
          const color = getTicketGroupColor(groups[tgID])
          const endAngle = startAngle + sliceSize
          this.drawSegment(context, startAngle, endAngle, color)
          startAngle = endAngle
        }

        result[chart] = canvas.toDataURL()
      }
    }

    return result
  }

  get largestSeatRadius(): number {
    const radii = this.seats.map((seat) => seat.radius)
    return Math.max(...radii)
  }

  drawSegment(ctx, start, end, color) {
    const center = ctx.canvas.width / 2
    ctx.fillStyle = color
    ctx.beginPath()
    ctx.moveTo(center, center)
    ctx.arc(center, center, center, start, end)
    ctx.closePath()
    ctx.fill()
  }

  onLoad() {
    if (this.$refs.map) {
      // Initialize d3 view
      this.view = this.d3.select('svg.map')

      // Initialize zoom behavior
      this.zoomBehavior = this.d3.zoom().on('zoom', this.onZoom)

      if (!this.view || !this.zoomBehavior) return

      // Zoom min/max levels
      this.zoomBehavior.scaleExtent([minZoom, maxZoom])

      // Drag min/max levels
      const margin = 50
      const worldEdge: [[number, number], [number, number]] = [
        [-margin, -margin],
        [this.mapData!.width + margin, this.mapData!.height + margin],
      ]
      this.zoomBehavior.translateExtent(worldEdge)

      this.view.call(this.zoomBehavior)

      this.view.on('dblclick.zoom', null)
    } else {
      throw new Error('Seat map element not found')
    }
  }

  getPieKeys(): string[] {
    // Loop active seats and find which color combinations are used
    const result: Set<string> = new Set()

    for (const { pieKey } of this.seats) {
      result.add(pieKey)
    }

    // Ignore unavailable pieKeys
    result.delete('unavailable')

    return Array.from(result)
  }

  onZoom({ transform }) {
    // d3 stores the current zoom level as 'k'.
    this.currentZoom = this.d3.zoomTransform(this.view!.node() as any).k
    this.view!.select('g').attr('transform', transform)
  }

  onSeatClick(seat: Seat) {
    this.hoveredSeat = null
    // Ignore clicks on booked seats.
    // Booked seats use the same template element (`<image>`) as available seats (but with reduced opacity).
    if (seat.state === 'available') {
      this.assignedSeats = this.automaticallySelectNeighbouringSeats(seat) ?? null
    }
  }

  private automaticallySelectNeighbouringSeats(first: Seat): AssignedSeat[] | void {
    const queue = new NeighboringSeatQueue()

    let seat: Seat | undefined = first
    while (seat) {
      queue.add(seat.next)
      queue.add(seat.prev)
      // console.log('Selecting', seat.name)
      // console.log('Queue', queue.items.map(s => s.name))
      const result = seat.select()
      if (result) {
        // Selection is complete.
        return result
      }
      seat = queue.next
    }
  }

  deselectSeat(seat: Seat) {
    // Rerender when a seat is deselected.
    // It is too slow to watch all the Seat instances on maps with more than 1000 seats.
    this.$forceUpdate()
    seat.deselect()
    this.assignedSeats = null
  }

  adjustZoom(amount) {
    // Use the `Transition` type so that TypeScript imports @types/d3-transition and discovers
    // additional methods on d3-selection's `Selection` type.
    const transition: Transition<Element, unknown, any, any> = this.view!.transition()
    transition.call(this.zoomBehavior!.scaleBy, amount)
  }

  zoomIn() {
    this.adjustZoom(2)
  }

  zoomOut() {
    this.adjustZoom(0.75)
  }

  get canZoomIn() {
    return this.currentZoom < maxZoom
  }

  get canZoomOut() {
    return this.currentZoom > minZoom
  }

  get background(): string {
    return `<g>${this.mapData!.other_elements}</g>`
  }

  get mapLegend() {
    // Select the pattern with the most colors
    const sortedGroups = this.pieKeys!.sort((a, b) => b.split(',').length - a.split(',').length)
    const exampleChart = this.pieCharts[sortedGroups[0]]

    return [
      {
        className: 'available',
        label: 'Available',
        image: exampleChart,
      },
      {
        className: 'booked',
        label: 'Sold out',
        image: exampleChart,
      },
      {
        className: 'unavailable',
        label: 'Unavailable',
      },
      {
        className: 'selected',
        label: 'Selected',
      },
    ]
  }

  get seatRadius() {
    // Currently this only supports maps where all seats have the same radius
    return this.seats[0].radius
  }

  get seatDiameter() {
    return this.seatRadius * 2
  }

  onMouseEnter(seat) {
    if (seat.state === 'available') {
      this.hoveredSeat = seat
    }
  }

  onMouseLeave() {
    this.hoveredSeat = null
  }

  confirmSelection() {
    this.$emit('input', [...this.assignedSeats!])
  }

  showPieChart(seat: Seat): boolean {
    if (seat.state === 'available' || seat.state === 'booked') {
      if (this.filter) {
        return seat.tgSet.has(this.filter)
      } else {
        return true
      }
    } else {
      return false
    }
  }

  getPieDataUrl(seat: Seat): string {
    const key = this.filter ? this.filter : seat.pieKey
    return this.pieCharts[key]
  }
}
