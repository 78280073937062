
import RadioItems from '@/components/forms/RadioItems.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'RadioGroup',
  components: { RadioItems },
})
export default class extends Vue {
  @Prop({ required: true })
  options: RadioGroupOption[]

  @Prop()
  label: string

  // Initial value property.
  @Prop({ default: null })
  value: Primitive | null

  @Prop({ default: false })
  optional: boolean

  @Prop()
  error: string

  @Prop()
  itemClassName: string

  @Prop()
  disabled: boolean

  @Prop()
  showMoreLabel: string

  errorInternal: string | null = null

  created() {
    this.errorInternal = this.error || null
    if (this.options.length === 1 && !this.optional) {
      this.$emit('input', this.options[0].value)
    }
  }

  onInput(value) {
    this.$emit('input', value)
    this.validate()
  }

  validate() {
    const radioGroup = this.$refs.radioGroup as HTMLDivElement
    const invalidField = radioGroup.querySelector(':invalid') as HTMLInputElement
    this.errorInternal = invalidField ? invalidField.validationMessage : null
  }
}
