
import type { WalletType } from '@/checkout/helpers/wallets'
import StripeWalletPayment from '@/checkout/stripe/StripeWalletPayment'
import MobileCartButton from '@/components/elements/MobileCartButton.vue'
import Price from '@/components/elements/Price.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PayButton from './PayButton.vue'
import type { PayButtonContext } from '@/helpers/CartHelpers'
import { onSubmit } from '@/helpers/CartHelpers'
import { closeCartModal } from '@/modals/cartModal'
import type { PaymentMethodName } from '@/checkout/stripe/helpers'

@Component({
  components: { MobileCartButton, Price, PayButton },
  computed: mapGetters({
    paymentDueByCreditCard: 'Cart/paymentDueByCreditCard',
  }),
})
export default class CheckoutMobileFooter extends Vue {
  @Prop()
  context: PayButtonContext

  @Prop({ default: false })
  submitDisabled: boolean

  @Prop({ default: false })
  submitting: boolean

  @Prop()
  supportedWallet: WalletType

  @Prop()
  selectedPaymentType: PaymentMethodName

  @Prop()
  wallet: StripeWalletPayment | null

  get hideCartAndTotal() {
    return this.$store.state.Cart.redeemMembership && this.$store.getters['Cart/paymentDueByCreditCard'] === 0
  }

  onSubmit() {
    closeCartModal()
    onSubmit(this.context, this.$router, () => this.$emit('submit'))
  }
}
