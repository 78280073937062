
import RadioGroup from '@/components/forms/RadioGroup.vue'
import { environment } from '@/helpers/Environment'
import { findCurrency } from '@/helpers/Currency'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Price from '@/components/elements/Price.vue'
import type { AnnotatedSession } from '@/types/Sessions'
import type { SessionPickerTabsConfig } from '@/api/types/processedEntities'
import TixTabs from '@/components/elements/TixTabs.vue'
import { getSessionGroups, SessionsGroup, toTixTab } from '@/helpers/SessionGroups'
import type { TixTab } from '@/types/TixTab'
import type { Badge } from '@/calendar/types'
import AvailabilityStatus from '@/components/elements/AvailabilityStatus.vue'
import AnnotationBadges from '@/components/elements/AnnotationBadges.vue'

interface Item {
  session: AnnotatedSession
  badges: Badge[]
  label: string
}

@Component({
  name: 'SelectAvailableSession',
  components: { TixTabs, Price, RadioGroup, AvailabilityStatus, AnnotationBadges },
})
export default class extends Vue {
  @Prop({ required: true })
  sessions: AnnotatedSession[]

  @Prop()
  value: AnnotatedSession

  @Prop()
  showPrices: boolean

  @Prop()
  capacityThreshold: number | false

  @Prop()
  tabsConfig: SessionPickerTabsConfig

  selectedTabId: string | null = null

  created() {
    const firstAvailableTab = this.sessionGroups?.find((t) => t.unavailableReason == undefined)
    if (firstAvailableTab) {
      this.selectedTabId = firstAvailableTab.id
    }
  }

  get displayedSessions(): Item[] {
    const selectedTab = this.sessionGroups?.find((t) => t.id === this.selectedTabId)
    const sessions = selectedTab ? selectedTab.sessions : this.sessions
    return sessions.map((session) => this.process(session))
  }

  get selected(): Item | void {
    // Process the input AnnotatedSession into an `Item` that is compatible with the template.
    return this.value ? this.process(this.value) : undefined
  }

  onSelect(session: AnnotatedSession) {
    if (!session.sold_out) this.$emit('input', session)
  }

  @Watch('value')
  onValueUpdate(value: AnnotatedSession) {
    if (value && this.displayedSessions.length > 1) {
      this.$nextTick(() => {
        this.$el.scrollIntoView({ behavior: 'smooth' })
      })
    }
  }

  private process(session: AnnotatedSession): Item {
    return {
      session,
      label: session.startTime.format('h:mm A'),
      badges: this.badges(session),
    }
  }

  private badges(session: AnnotatedSession): Badge[] {
    const result: Badge[] = []

    if (session.priceIncrease && session.priceIncrease > 0) {
      result.push({
        symbol: findCurrency(environment.portal.default_currency_code)?.symbol,
        style: 'increase',
      })
    }

    for (const annotation of session.annotations) {
      result.push({
        style: annotation.style,
      })
    }

    return result
  }

  get sessionGroups(): SessionsGroup[] | null {
    // TODO Only enable tabs if config is valid;
    //   - first & second names are not empty, even after trimming whitespace.
    //   - thresholds are valid times; 0000 to 2400, but not e.g. 1260, which does not exist
    //   - if second threshold is set;
    //     - third name is not empty
    //     - first threshold is before second threshold
    if (this.tabsConfig?.enabled) return getSessionGroups(this.sessions, this.tabsConfig)
    else return null
  }

  get tabs(): TixTab[] | null {
    if (this.sessionGroups) return this.sessionGroups.map(toTixTab)
    else return null
  }
}
