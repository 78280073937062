import type { AssignedSeat } from '@/seats/assignments'
import type { TGID } from '@/seats/helpers'
import type SeatSelection from '@/seats/SeatSelection'
import Vue from 'vue'

export type SeatState = 'selected' | 'available' | 'unavailable' | 'booked'

// Extend Vue to handle any reactivity efficiently.
export default class Seat extends Vue {
  next?: Seat
  prev?: Seat
  #selected = false
  readonly tgSet: Set<TGID>
  readonly #tgs: TGID[]
  readonly #data: SeatEntity
  readonly #selection: SeatSelection

  constructor(data: SeatEntity, selection: SeatSelection) {
    super()
    this.#data = data
    this.#selection = selection
    const tgs = data.ticket_groups.split(',').filter((tg) => this.#selection.available.has(tg))
    // The order is important when building a key for the seat's ticket-group pie chart (an SVG pattern ID).
    // The API sorts the IDs already, but might cease doing it in the future, intentionally, or otherwise.
    // @see https://github.com/stqry/tix-api/blob/5b700ee76606da3f08a6315b5044df5cc3c82f91/seated/seat_template_modify.go#L156...L157
    // Sort them again here to be safe.
    // TODO Use insertion sort (linear in best case scenario) instead of JavaScript's built-in merge sort (N-log-N in best case scenario).
    // @see https://stackoverflow.com/a/236534/17093226

    this.#tgs = tgs.sort()
    this.tgSet = new Set(this.#tgs)
  }

  get name(): string {
    return this.#data.seat
  }

  get templateID(): string {
    return this.#data.seat_template_id
  }

  get x(): number {
    return this.#data.x
  }

  get y(): number {
    return this.#data.y
  }

  get radius() {
    return this.#data.r
  }

  get pieKey(): string {
    if (this.state === 'available' || this.state === 'booked') {
      return this.#tgs.join(',')
    } else {
      return 'unavailable'
    }
  }

  get state(): SeatState {
    if (this.#selected) {
      return 'selected'
    } else if (this.#tgs.some((tg) => this.#selection.available.has(tg))) {
      return this.#data.state
    } else {
      return 'unavailable'
    }
  }

  select(): void | AssignedSeat[] {
    const seatAssignments = this.#selection.add(this)
    this.#selected = true
    return seatAssignments
  }

  deselect() {
    const seatAssignments = this.#selection.remove(this)
    this.#selected = false
    return seatAssignments
  }
}
