
import FormInput2 from '@/components/forms/FormInput2.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { openModal } from '@/modals/helpers/api'
import CalendarPicker from '@/calendar/CalendarPicker.vue'
import EventBus from '@/helpers/EventBus'
import type { CalendarMonthData, DayData } from '@/calendar/types'
import { TixTime } from '@/TixTime/TixTime'
import { TixDate } from '@/TixTime/TixDate'
import type { EventDetails } from '@/api/types/processedEntities'
import DateSelector from '@/components/elements/DateSelector.vue'
import type { LanguageStrings } from '@/language/types'
import type { CalendarApiResponse } from '@/api/calendar'
import { calendarDatesIndex, getMonths, processCalendarApiResponse } from '@/calendar/helpers'
import { capacityThreshold } from '@/helpers/AvailabilityHelpers'

/**
 * TixDate does not support times nor timezones, so the scope for timezone bugs in <SelectDate> is small.
 *
 * TODO Use TixDate in <ReserveQuantityFirst> and <ReserveDateFirst> so that <SelectDate> does not have to convert its
 * value to a TixTime when emitting.
 *
 * TODO Use TixDate in getDateAnnotations() and its helper functions too.
 */
@Component({
  name: 'SelectDate',
  components: { DateSelector, FormInput2, CalendarPicker },
})
export default class extends Vue {
  @Prop({ required: true })
  value: TixTime | null

  @Prop({ required: true })
  apiResponse: CalendarApiResponse

  @Prop({ required: true })
  event: EventDetails

  @Prop({ required: true })
  showPrices: boolean

  isCalendarPickerOpen = false

  readonly calendarPickerModalID = 'calendar-picker-modal'

  readonly maxButtons = 8

  readonly t: LanguageStrings['selectDate']

  mounted() {
    EventBus.$on('closed:modal', () => {
      // Focus on the more-dates button after closing the modal so that screen readers stay in the reserve flow.
      // TODO An onClose handler is specified in showMoreDates(). Are both necessary? Should they do the same things?
      this.focusMoreDatesButton()
    })

    if (window.Cypress) {
      // Create a backdoor for Cypress tests to select a date easily, since it is not possible to click outside the viewport.
      window.__selectDate = (value: string) => {
        const day = this.index[value]
        if (day?.enabled) {
          this.handleOnDateSelect(day)
        } else {
          /* eslint-disable no-console */
          console.error('The date specified via window.__selectDate() is not available')
        }
      }
    }
  }

  destroyed() {
    // Close the calendar modal when the browser's back button is pressed.
    if (this.isCalendarPickerOpen) {
      EventBus.$emit('close:modal')
    }
  }

  get dayData(): DayData[] {
    return processCalendarApiResponse(this.apiResponse, this.event)
  }

  get availableDays(): DayData[] {
    return this.dayData.filter((day) => day.enabled)
  }

  get firstSeveralAvailableDays(): DayData[] {
    return this.availableDays.slice(0, this.maxButtons)
  }

  get index(): Dict<DayData> {
    return calendarDatesIndex(this.dayData)
  }

  get months(): Dict<CalendarMonthData> {
    // <SelectDate> lives in the app for longer than most of its descendant components. Prefer to process/derive state
    // here so that descendant components do not have to re-calculate it.
    return getMonths(this.index)
  }

  get selectedDate(): TixDate | undefined {
    return this.value ? new TixDate(this.value.format('DATE')) : undefined
  }

  get tz(): string {
    return this.event.venue.timezone
  }

  showMoreDates() {
    this.isCalendarPickerOpen = true

    openModal({
      name: 'date-picker-modal',
      title: 'Choose date',
      component: CalendarPicker,
      onClose: () => (this.isCalendarPickerOpen = false),
      props: {
        id: this.calendarPickerModalID,
        selected: this.selectedDate,
        onSelect: this.handleOnDateSelect,
        index: this.index,
        months: this.months,
        venueTimezone: this.tz,
        displayPrices: this.showPrices,
        capacityThreshold: this.capacityThreshold,
      },
      size: 'lg',
      closeable: true,
      mobile: 'drawer',
    })
  }

  handleOnDateSelect(day: DayData) {
    EventBus.$emit('close:modal')
    this.selectDay(day)
  }

  selectDay(day: DayData) {
    this.$emit('input', day ? new TixTime(day.date.date, this.tz) : null)
    // TODO There are two calls to focusMoreDatesButton(). Are both necessary?
    // @see mounted()
    this.focusMoreDatesButton()
  }

  get selectedDay(): DayData | undefined {
    const value = this.selectedDate
    if (value) {
      return this.index[value.date]
    }
    return undefined
  }

  get capacityThreshold() {
    return capacityThreshold(this.dayData)
  }

  get verbalAnnouncement() {
    if (!this.value) return ''
    const formatted = this.value.format('LONGER_DATE')
    return `You have selected ${formatted}`
  }

  private focusMoreDatesButton(): void {
    this.$nextTick(() => {
      const button = this.$refs.moreDatesButton as HTMLButtonElement
      button?.focus()
    })
  }
}
