import { i18n } from '@/app/I18n'

export function promoCodeErrorMessage(value: string, error: any, input: HTMLInputElement): string {
  if (error.validationError && !input.validity.valid) {
    return input.validationMessage
  } else if (error._code) {
    return promoCodeApiErrorMessage(value, error)
  } else {
    return i18n.t('applyPromoCodes.unknownErrorMessage') as string
  }
}

function promoCodeApiErrorMessage(value: string, error: ApiErrorEntity) {
  const tVars = {
    code: value,
    message: error?._description,
  }
  const key = apiErrorMessageKey(error)
  return i18n.t(key, tVars) as string
}

function apiErrorMessageKey(error: ApiErrorEntity) {
  const key = `applyPromoCodes.apiErrorMessages.${error._code}`
  if (i18n.te(key)) {
    return key
  } else {
    return error._description ? 'applyPromoCodes.apiErrorMessage' : 'applyPromoCodes.defaultApiErrorMessage'
  }
}
