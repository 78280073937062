
import Bus from '@/helpers/EventBus'
import { closeCartModal, openCartModal } from '@/modals/cartModal'
import { formatTicketCount } from '@/helpers/StringHelpers'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  components: {},
  computed: mapGetters({
    ticketCount: 'Cart/ticketCount',
  }),
})
export default class MobileCartButton extends Vue {
  // Vuex getters
  ticketCount: number
  open: boolean = false

  created() {
    Bus.$on('opened:modal', ({ options }) => {
      if (options.name === 'mobile-cart') {
        this.open = true
      }
    })

    Bus.$on('closed:modal', ({ instance }) => {
      if (instance.name === 'mobile-cart') {
        this.open = false
      }
    })
  }

  get displayTicketCount(): string {
    return formatTicketCount(this.ticketCount)
  }

  toggleCart() {
    if (this.open) {
      closeCartModal()
    } else {
      openCartModal()
    }
  }
}
