
import type { SVGIconName } from '@/types/ConfigYaml'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Message content can provided four ways. In order of precedence:
 *
 * 1. template; Supports global Vue components, like <login-link>, <membership-link> and <router-link>.
 * 1. html; Supports HTML, but no Vue components.
 * 1. plain; Sanitizes content by encoding value to HTML entities.
 * 1. RECOMMENDED: Slot; The simplest and most elegant method. It supports all of the above. It only works in
 *    hard-coded templates, so it is not suitable for dynamic or database-managed content.
 */
@Component({ name: 'TixMessage' })
export default class extends Vue {
  @Prop()
  plain: string | null

  @Prop()
  html: string | null

  @Prop()
  template: string | null

  @Prop({ default: 'info' })
  type: 'warning' | 'success' | 'error' | 'info' | 'promo' | 'user'

  get iconName(): SVGIconName {
    const map = {
      warning: 'ExclamationIcon',
      success: 'CheckmarkIcon',
      error: 'ExclamationIcon',
      promo: 'StarIcon',
      user: 'UserIcon',
    }
    return map[this.type] ?? 'InfoIcon'
  }

  get ariaLabel(): string {
    return `${this.type} alert`
  }
}
