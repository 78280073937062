import { logInTrackJS } from '@/errors/helpers'
import { languageItem } from '@/language/helpers'
import { openCartExpiryModal } from '@/modals/cartExpiryModal'
import { cartExpired } from '@/state/Cart'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'

// This Vue component is only necessary to access root Vue component to enable/set the modal.
@Component({})
export default class extends Vue {
  created() {
    const language = this.$t('cartExpire')
    // Check the expiry every second and show an error modal if it has expired.
    timer = setInterval(() => checkCart(this.$root, language), 1000)
  }
}

// Export the clear function so that checkout procedure can clear the timer.
export function clearTimer() {
  clearInterval(timer)
}

// This singleton is private to this ES module.
let timer: ReturnType<typeof setTimeout>

function checkCart(root: Vue, language) {
  // Has the cart expired?
  // Is there already an active error modal open?
  if (cartExpired() && !root.expiryModalOpen) {
    // How does this happen?
    // 1. If you have a cart for buying tickets on 1 tab and manage order open in another tab.
    if (root.$route.name && ['manage', 'reschedule'].includes(root.$route.name)) {
      logInTrackJS('Manage Order Cart Expiry', {
        cart: store.getters['Cart/cart'],
        localExpiry: store.getters['Cart/localExpiry'],
      })
    }

    const language = languageItem('cartExpire')
    root.expiryModalOpen = true
    store.commit('clearUpsellsChain')
    openCartExpiryModal(language.title, () => {
      root.expiryModalOpen = false

      if (root.$route.name === 'reschedule') {
        root.$router.push(`/manage/${root.$route.params.orderId}`)
      } else if (root.$route.name === 'manage') {
        root.$router.go(0)
      }

      return store.dispatch('Cart/clearAll')
    })
  }
}
