
import MembersMenu from '@/components/membership/MembersMenu.vue'
import { environment } from '@/helpers/Environment'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import type { LogoURLs } from '@/helpers/ImageHelpers'
import { getLogoUrls } from '@/helpers/ImageHelpers'
import EventBus from '@/helpers/EventBus'

@Component({
  name: 'Header',
  components: { MembersMenu },
})
export default class extends Vue {
  logoUrls: LogoURLs

  get user() {
    return store.getters['Member/user']
  }

  created() {
    const sellerLogo = environment.sellerMeta?.logo
    if (sellerLogo) {
      this.logoUrls = typeof sellerLogo === 'object' ? sellerLogo : { large: sellerLogo }
    } else {
      this.logoUrls = getLogoUrls(environment.portal.slug)
    }

    if (window.Cypress) {
      EventBus.$on('theme changed', ({ logos }: { logos: LogoURLs }) => {
        this.logoUrls = logos
        this.$forceUpdate()
      })
    }
  }

  mounted() {
    // add event listener to elements other than those within the menu.
    const body = document.body
    body.addEventListener('click', (event) => {
      const memberMenu: Element = this.$refs.memberMenu as Element
      if (memberMenu && memberMenu.hasAttribute('open')) {
        // this excludes the buttons so the menu can close when an action is triggered.
        const isMemberMenuChild = Array.from(memberMenu.querySelectorAll('*:not(button)')).some(
          (x) => x === event.target,
        )
        if (!isMemberMenuChild) {
          this.closeMemberMenu()
        }
      }
    })
  }

  get portalName() {
    return environment.portal.name
  }

  closeMemberMenu() {
    const memberMenu: Element = this.$refs.memberMenu as Element
    memberMenu.removeAttribute('open')
  }
}
