
import AddressInput from '@/components/forms/AddressInput.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { AutocompletedAddressData, customFieldsDefaults, IdentityFormData } from '@/helpers/IdentityHelpers'
import { indexBy } from '@/helpers/IndexHelpers'
import * as getCountryISO3 from 'country-iso-2-to-3'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  addressFieldNames,
  alterAddressFieldsForAutocomplete,
  getAddressAutocompleteApiKey,
  supportedCountries as addressAutocompleteSupportedCountries,
} from '../../helpers/AddressHelpers'
import { formInputPlaceholderLanguage } from '@/helpers/StringHelpers'
import type { FormInputField } from '@/components/forms/types'

@Component({
  name: 'IdentityForm',
  components: { AddressInput, FormInput2 },
})
export default class extends Vue {
  // Input values are ignored because we should never give out PII.
  @Prop({ required: true })
  value: IdentityFormData

  @Prop({ default: () => [] })
  fields: FormInputField[]

  defaults: IdentityFormData = customFieldsDefaults()
  answers: IdentityFormData = this.defaults
  inputModeManual: boolean = false
  autocompletedAddressData: AutocompletedAddressData | null = null

  get fieldsDict(): Dict<FormInputField> {
    return indexBy('key', this.fields)
  }

  @Watch('autocompletedAddressData')
  setAddressAnswers(data: AutocompletedAddressData) {
    const setAnswer = (key: string, value?: string) => {
      if (this.fieldsDict[key] && value !== undefined) {
        Vue.set(this.answers, key, value)
      }
    }
    setAnswer('address', data.address)
    setAnswer('city', data.city)
    setAnswer('state', data.state)
    setAnswer('region', data.state)
    setAnswer('zip_code', data.zipCode)

    if (data.zipCode) {
      this.zipCodeBlurred()
    }
  }

  get addressAutocompleteSupported(): boolean {
    const config = getAddressAutocompleteApiKey()
    if (config && this.fieldsDict.address && this.fieldsDict.country) {
      // TODO Offer for all countries?
      return addressAutocompleteSupportedCountries.has(this.countrySelected)
    } else {
      return false
    }
  }

  get shouldAddressFieldBeRequired(): boolean {
    if (this.addressInputMode === 'manual') {
      return this.fieldsDict['address']?.required
    } else {
      return addressFieldNames.some((name) => this.fieldsDict[name]?.required)
    }
  }

  get addressInputMode(): 'autocomplete' | 'manual' {
    return this.addressAutocompleteSupported ? (this.inputModeManual ? 'manual' : 'autocomplete') : 'manual'
  }

  get displayFields(): FormInputField[] {
    const fields =
      this.addressInputMode === 'autocomplete' ? alterAddressFieldsForAutocomplete(this.fields) : this.fields

    return fields.map((field) => ({
      ...field,
      placeholderLanguage: formInputPlaceholderLanguage(field),
    }))
  }

  get countrySelected() {
    // `getCountryISO3()` needs to input to be uppercase
    return getCountryISO3(((this.answers.country as string) ?? '').toUpperCase())
  }

  onBlur(e) {
    // Checkout applies discounts based on post code for some tenants.
    if (e.target.name === 'zip_code') {
      this.zipCodeBlurred()
    }
  }

  zipCodeBlurred() {
    const zipCode = this.answers.zip_code as string
    if (zipCode?.length > 0) {
      this.$emit('onBlurZipCode', zipCode)
    }
  }

  @Watch('fields')
  onFieldsChange() {
    const answers = this.answersAfterFieldsChange(this.fields)

    const hasAnswerWithNoField = Object.keys(this.answers).some((key) => !this.fieldsDict[key])
    const hasDifferentAmountOfAnswers =
      Object.values(answers).filter((answer) => answer).length !==
      Object.values(this.answers).filter((answer) => answer).length

    if (hasDifferentAmountOfAnswers || hasAnswerWithNoField) {
      this.answers = answers
    }
  }

  answersAfterFieldsChange(fields: FormInputField[]): IdentityFormData {
    const answers = {}
    for (const field of fields) {
      // Answers are set to the defaults on initialisation. If a field is removed from the identity form and
      // then re-added then the default value will have been lost, so set it if there is no answer.
      const answer = this.answers[field.key] ?? this.defaults[field.key]
      if (!field.disabled && this.hasValidOptionValue(field, answer)) {
        answers[field.key] = answer
      }
    }
    return answers
  }

  hasValidOptionValue(field: FormInputField, answer?: string | boolean) {
    const options = field.options
    return !options || options.some((option) => option.value === answer)
  }

  @Watch('answers', { deep: true })
  onChange(data: IdentityFormData) {
    // There may be initial default values for fields that are not enabled.
    // Only emit values that are enabled.
    const result = {}
    for (const element of this.fields) {
      // Ignore confirmation fields like emailConfirm and passwordConfirm.
      if (!element.disabled && !element.mustEqual && !element.mustEqualCaseInsensitive) {
        result[element.key!] = data[element.key!]
      }
    }

    this.$emit('input', result)
  }
}
