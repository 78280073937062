import type { EventWithConfig } from '@/api/types/processedEntities'
import { unique } from '@/helpers/ArrayHelpers'
import type { Location, RawLocation, Route } from 'vue-router'

export function metadataKeys(query: Dictionary): string[] {
  return prefixedKeys('meta.', query)
}

export function prefixedKeys(prefix: string, query: Dictionary): string[] {
  return Object.keys(query).filter((name) => name.startsWith(prefix))
}

export type NormalizedReserveParamValue = 'false' | 'true' | 'warn' | 'strict'

/**
 * Filter predicate for matching an event to metadata filters.
 *
 * TODO Remove this. OMSI is probably the only customer that knows it exists.
 * TODO Move to query params for server-side filtering?
 *
 * TODO Unit tests.
 * TODO Make matches case-insensitive?
 * TODO Support filter for metadata exists with any value. E.g. `?meta.foo`         matches any value as long as metadata key exists.
 * TODO Support multiple possible matching values.         E.g. `?meta.foo=bar,baz` matches 'bar' or 'baz'
 *      This syntax would prohibit exact matching for values with the separator in them.
 *      Alternatively;                                     E.g. `?meta.foo=bar&meta.foo=baz`
 * TODO Support filter for value contains N.               E.g. `?meta.foo~=bar`    matches 'bar' and 'hi bart'
 * TODO Support wildcards or maybe regex?                  E.g. `?meta.foo=bar%`    matches 'bar' and 'bart', but not 'low bar'
 */
export function eventHasMetadataValues(event: EventWithConfig, query: Dictionary): boolean {
  const prefix = 'meta.'
  return metadataKeys(query)
    .map((key) => key.substring(prefix.length))
    .every((key) => event.meta[key] === query[prefix + key])
}

// TODO Unit test coverage.
export function alterQueryParam(current: Route, changes: Dict<string | null>): Location {
  const params = Object.entries({ ...current.query, ...changes }).filter(([, value]) => value != null)

  return {
    path: current.path,
    query: Object.fromEntries(params) as Dict<string>,
    hash: current.hash,
  }
}

export interface QueryParamFilter {
  value: string | null
  label: string
  location: Location
}

export function orderIdsFromQuery(orderIds: any): string[] {
  // orderIds can be either orderIds=1,2 or orderIds=1,orderIds=2
  let orderIdsDictionary: string[] = orderIds

  if (typeof orderIds === 'string') {
    orderIdsDictionary = orderIds.split(',')
  }
  return orderIdsDictionary
}

export function removeQueryParams(current: Route, names: string[]): RawLocation | void {
  if (names.some((name) => current.query[name])) {
    const nullParams: Dict<null> = {}
    for (const name of names) {
      nullParams[name] = null
    }

    const route = alterQueryParam(current, nullParams)
    route.replace = true
    return route
  }
}

export function removeQueryParam(current: Route, name: string): RawLocation | void {
  return removeQueryParams(current, [name])
}

export type QueryParams = Dict<string | string[] | boolean | undefined>

export function buildQueryString(params: QueryParams): string {
  const result: [string, Array<string | boolean>][] = []

  // Sort keys to optimize the URL for cache hits.
  for (const key of Object.keys(params).sort()) {
    const value = params[key]!

    // Ignore undefined values.
    if (value != null) {
      if (typeof value === 'boolean') {
        result.push([key, [value]])
      } else {
        const items = Array.isArray(value)
          ? // For arrays; Ignore duplicate values. Sort values to optimize the URL for cache hits.
            unique(value).sort()
          : // for strings; Split on commas to preserve commas without URI-encoding them.
            value.split(',')

        // Ignore empty arrays.
        if (items.length > 0) {
          result.push([key, items])
        }
      }
    }
  }

  return result
    .map(([key, values]) => [encodeURIComponent(key), values.map(encodeURIComponent)] as [string, string[]])
    .map(([key, values]) => [key, values.join(',')])
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}

export interface BaseQueryParamWithModifier<T> {
  value: T
  modifier: string // TODO Specify only possible values
}

type QueryParamWithModifier = BaseQueryParamWithModifier<string>
type QuantityQueryParam = BaseQueryParamWithModifier<Dict<number>>

export function getQueryParamFromString(param: string, searchString: string): QueryParamWithModifier | null {
  const params = new URLSearchParams(searchString.trim())
  for (const [key, value] of params) {
    const [name, modifier] = key.split('.')
    if (name === param) {
      return {
        value,
        modifier: modifier ?? '',
      }
    }
  }

  return null
}

export function getQueryParam(param: string): QueryParamWithModifier | null {
  return getQueryParamFromString(param, window.location.search)
}

export function formatQuantityParamValue(paramValue: string): Dict<number> {
  const quantities = paramValue
    .split(',')
    .map((q) => q.trim())
    .filter((q) => q !== '')

  const value = {}
  for (const q of quantities) {
    const [ticketType, numTickets] = q.split(':')
    value[ticketType] = numTickets ? Number(numTickets) : 0
  }

  return value
}

export function getQuantityParamFromString(searchString: string): QuantityQueryParam | null {
  const param = getQueryParamFromString('quantity', searchString)

  if (!param) {
    return null
  }

  const value = formatQuantityParamValue(param.value)

  return {
    value,
    modifier: param.modifier,
  }
}

export function getQuantityParam(): QuantityQueryParam | null {
  return getQuantityParamFromString(window.location.search)
}
