import type { ReserveTicketPayload } from '@/api/types/payloads'
import type { LinkedTG } from '@/api/types/processedEntities'
import { mapEntries } from '@/helpers/DictHelpers'
import { groupBy, indexItemsById } from '@/helpers/IndexHelpers'
import type { TGID, TGQuantities, TTQuantities } from '@/seats/helpers'
import type Seat from '@/seats/Seat'

// TODO Extend Seat instead of wrapping it?
export interface AssignedSeat {
  seat: Seat
  ticketGroupID: TGID
}

type Assignment = Dict<Seat[]>

export function calculateSeatAssignments(selected: Dict<Seat>, required: TGQuantities): Assignment[] {
  const seats = Object.values(selected).sort((a, b) => a.tgSet.size - b.tgSet.size)
  return traverse(seats, required, {})
}

export function availableTGs(assignments: Assignment[], quantities: TGQuantities): Set<TGID> {
  const result = new Set<TGID>()
  for (const assignedSeats of assignments) {
    for (const [tg, required] of Object.entries(quantities)) {
      const assigned = assignedSeats[tg]?.length ?? 0
      if (required > assigned) {
        result.add(tg)
      }
    }
  }
  return result
}

export function assignedSeats(assignment: Assignment, tgQuantities: TGQuantities): AssignedSeat[] {
  // Do not alter the input.
  const quantities = { ...tgQuantities }

  const result: AssignedSeat[] = []

  for (const [ticketGroupID, seats] of Object.entries(assignment)) {
    for (const seat of seats) {
      quantities[ticketGroupID] -= 1
      result.push({ seat, ticketGroupID })
    }
  }

  return result
}

export function ttToTGQuantities(tt: TTQuantities, groups: LinkedTG[]): TGQuantities {
  const types = groups.flatMap((group) => group.types)
  const ticketTypes = indexItemsById(types)
  const result: TGQuantities = {}
  for (const [id, quantity] of Object.entries(tt)) {
    const group = ticketTypes[id].group
    result[group.id] ??= 0
    result[group.id] += quantity
  }
  return result
}

export function assignSeatsByTT(
  assignedSeats: AssignedSeat[] | null,
  quantities: TicketTypeQuantities,
  allGroups: LinkedTG[],
): Dict<ReserveTicketPayload['handler_data'][]> {
  const seats = groupBy('ticketGroupID', assignedSeats ?? [])
  const groups = allGroups.filter((group) => group.handler === 'seated')

  const result = {}

  for (const group of groups) {
    for (const type of group.types) {
      result[type.id] ??= []
      const count = quantities[type.id]?.quantity ?? 0
      for (let i = 0; i < count; i++) {
        const seat = seats[group.id].shift()
        if (seat) {
          result[type.id].push({ seat_template_id: seat.seat.templateID })
        } else {
          throw new Error(`Not enough seats for ${group.name} ${type.name}`)
        }
      }
    }

    if (seats[group.id] && seats[group.id].length > 0) {
      throw new Error(`Too many seats for ${group.name}`)
    }
  }

  return result
}

// Debug tooling
export function _serializeSeatAssignments(sets: Assignment[]): Dictionary[] {
  return sets.map((set) => mapEntries(set, (seats) => seats.map((seat) => seat.name).join(',')))
}

function traverse(selected: Seat[], required: TGQuantities, assigned: Assignment): Assignment[] {
  const [firstSeat, ...otherSeats] = selected

  if (firstSeat) {
    const result: Assignment[] = []
    for (const tg of firstSeat.tgSet) {
      // Only consider TGs for TTs that the guest has selected, and that have more tickets that need seats assigned.
      if (required[tg]) {
        assigned[tg] ??= []
        if (required[tg] > assigned[tg].length) {
          result.push(...traverse(otherSeats, required, addSeat(assigned, tg, firstSeat)))
        }
      }
    }
    return result
  } else {
    // All seats are assigned. Any remaining TGs are available for selection.
    return [assigned]
  }
}

function addSeat(current: Assignment, tg: TGID, seat: Seat): Assignment {
  // Deep clone.
  const result = mapEntries(current, (seats) => [...seats])
  result[tg].push(seat)
  return result
}
