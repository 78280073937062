
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ModalOptions } from '@/modals/helpers/types'

/**
 * Accessible modal component
 */
@Component({ name: 'Modal' })
export default class extends Vue {
  @Prop({ required: true })
  modal: ModalOptions

  @Prop({ required: true })
  disabled: boolean

  open: boolean = false

  mounted() {
    const modal = this.$refs.modal as HTMLDialogElement
    if (modal.showModal) {
      modal.showModal()
    } else {
      // .showModal is not supported in iOS < 15.4
      // fallback to using the 'open' attribute to open the modal
      this.open = true
    }

    modal.classList.add('open')
  }

  close() {
    if (this.modal.closeable) {
      const modal = this.$refs.modal as HTMLDialogElement
      modal.classList.remove('open')

      const waitForCssTransitionDelay = parseInt(
        document.documentElement.style.getPropertyValue('--modal-transition-duration'),
        10,
      )
      setTimeout(() => {
        this.$emit('close')
      }, waitForCssTransitionDelay)
    }
  }

  get classes() {
    const mobileClass = `mobile-${this.modal.mobile}`
    const desktopClass = `desktop-${this.modal.desktop}`

    return ['modal', this.modal.size, this.modal.name, mobileClass, desktopClass, { disabled: this.disabled }]
  }

  handleBackdropClick(e) {
    if (!e.target.closest('.modal-window')) {
      this.close()
    }
  }
}
