import { configYml } from '@/helpers/Environment'
import { defaultPromoCode } from '@/helpers/PreloadPromoCodes'
import { fetchCartMod } from '@/helpers/PromoCodes'
import store from '@/store/store'

export function getPostCodeFilterRegularExpression(): Promise<RegExp | void> {
  const filter = configYml.postCodeAsDiscountCode?.filter
  if (filter) {
    return preloadedDiscounts().then((discounts) => {
      // Do not enable post codes as discount codes if the cart has any discount
      // other than the default_promo_codes discount.
      // TODO Restore support for custom regex filter per tenant?

      if (discounts.length < 1) {
        return postCodeFilters[filter]
      }
    })
  } else {
    return Promise.resolve()
  }
}

const postCodeFilters = {
  // Use the first 5 digits and ignore everything else, such as the four-digit suffix; 60607-1234
  US: /^[0-9]{5}/,

  // Ignore with "special cases" in UK postcodes.
  // TODO Require the space character in the middle?
  // @see https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
  UK: /^[a-z]{1,2}[0-9][a-z0-9]? ?[0-9][a-z]{2}$/i,
}

function preloadedDiscounts(): Promise<CartMod[]> {
  const results = store.getters['Cart/cartMods'] as CartMod[]
  return getDefaultPromoCodeDiscountID().then((id) => {
    return results.filter((mod) => {
      // Ignore any configured default promo code.
      return (
        mod.id !== id &&
        // Discounts are cartmods with activating code groups.
        mod.activating_code_groups &&
        mod.activating_code_groups.length > 0
      )
    })
  })
}

function getDefaultPromoCodeDiscountID(): Promise<string | void> {
  const code = defaultPromoCode()
  if (code) {
    return fetchCartMod(code).then((response) => response.cartmod._data[0].id)
  } else {
    return Promise.resolve()
  }
}
