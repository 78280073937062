import { API } from '@/api/API'

export function upsertUserAndGetId(
  payload: {
    first_name: string
    last_name: string
    email: string
    password?: string
    additional_info: Dict<string | boolean>
  },
  loggedIn: boolean,
): Promise<string> {
  type entities = 'identity'

  const args = {
    embed: 'identity',
    body: payload,
  }

  const request = loggedIn ? API.put<entities>('my/user', args) : API.post<entities>('user', args)

  return request
    .then((response) => response.identity._data[0].id)
    .catch((e) => {
      if (e.response?.status === 409 && e.response.data._data[0]._extra) {
        // Ignore conflict status codes. This occurs when updating a password-protected user.
        return e.response.data._data[0]._extra.identity_id
      } else {
        throw e
      }
    })
}
