
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'DescriptionModal',
})
export default class ConfirmModal extends Vue {
  @Prop()
  content: string
}
