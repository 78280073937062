import type { PaymentMethodName } from '@/checkout/stripe/helpers'

interface StripeRequiredFields {
  billing_details?: {
    name?: true
    email?: true
    address?: {
      line1?: true
      city?: true
      country?: true
      postalCode?: true
    }
  }
}

const required: Partial<Record<PaymentMethodName, StripeRequiredFields>> = {
  // From CreatePaymentMethodBacsDebitData.
  // https://github.com/stripe/stripe-js/blob/7f5a38b/types/stripe-js/payment-intents.d.ts#L406-L438
  bacs_debit: {
    billing_details: {
      name: true,
      email: true,
      address: {
        line1: true,
        city: true,
        country: true,
        postalCode: true,
      },
    },
  },

  // Afterpay requires all these fields be passed in as `shipping` when confirming payment intent.
  // https://docs.stripe.com/js/payment_intents/confirm_afterpay_clearpay_payment#stripe_confirm_afterpay_clearpay_payment-new-shipping-address
  afterpay_clearpay: {
    billing_details: {
      name: true,
      address: {
        line1: true,
        city: true,
        country: true,
        postalCode: true,
      },
    },
  },
}

const stripeToTixMap = {
  line1: 'address',
  postalCode: 'zip_code',
}

// TODO Support adding non-address fields when it becomes necessary.
export function getRequiredFieldsByPaymentMethod(name?: PaymentMethodName | null): string[] {
  if (name) {
    const address = required[name]?.billing_details?.address
    if (address) {
      return Object.keys(address).map((key) => stripeToTixMap[key] ?? key)
    }
  }

  return []
}
