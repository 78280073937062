
import Price from '@/components/elements/Price.vue'
import DescriptionModal from '@/modals/DescriptionModal.vue'
import { openModal } from '@/modals/helpers/api'
import { randomIdentifier, toKebabCase } from '@/helpers/StringHelpers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ name: 'RadioItems', components: { Price } })
export default class extends Vue {
  @Prop({ required: true })
  options: RadioGroupOption[]

  @Prop()
  label: string

  // Initial value property.
  @Prop({ default: null })
  value: Primitive

  @Prop({ default: false })
  optional: boolean

  @Prop()
  itemClassName: string

  @Prop()
  disabled: boolean

  @Prop({ default: 'More' })
  showMoreLabel: string

  // An internal data property is required for v-model to bind to. This links all the radio elements together,
  // even without a `name=""` attribute. It also allows the initial value to propagate from the parent component.
  // The `value` prop can not be used for this purpose because props must not be set from child components.
  private internal: Primitive | null = null

  // Name the radio inputs in the same group so that browser's form validation works.
  // Vue does not need a name attribute; v-model links radio buttons together.
  groupId: string = 'radio-group-' + randomIdentifier()

  created() {
    this.internal = this.value
  }

  @Watch('value')
  updateInternalValue() {
    this.internal = this.value
  }

  clickHandler(value) {
    if (this.optional && this.internal === value) {
      // Radio items in an optional radio group can be deselected.
      this.$emit('input', null)
    }
  }

  summary(option): string | null {
    const { summary, description } = option
    if (summary) {
      return summary
    } else if (description) {
      return description
    }
    return null
  }

  description(option): string | null {
    const { summary, description } = option
    if (summary && description) {
      return description
    }
    return null
  }

  get radioItems(): RadioItem[] {
    return this.options.map((option) => {
      const id = option.value === null ? null : `${this.groupId}-${toKebabCase(option.value.toString())}`

      return {
        id,
        ...option,
      }
    })
  }

  openDescriptionModal(description) {
    openModal({
      name: 'description-modal',
      component: DescriptionModal,
      mobile: 'full-screen',
      props: { content: description },
    })
  }
}
