import type { EventListItem } from '@/api/types/processedEntities'
import { TixTime } from '@/TixTime/TixTime'

export interface AbstractInterval<T> {
  start: T
  end: T
}

export type StringInterval = AbstractInterval<string>
export type TimeInterval = AbstractInterval<TixTime>

export type EventDetailsPredicate = (event: EventListItem) => boolean

export function predicateForSessionStartsInInterval(window: StringInterval): EventDetailsPredicate {
  const start = Number(window.start)
  const end = Number(window.end)

  function predicate(event: EventListItem) {
    const sessionStartTimes = event.sessions!.map((session) => Number(session.startTime.format('military')))
    for (const time of sessionStartTimes) {
      // The end-time of the session does not matter.
      // TODO Consider supporting some padding, perhaps via metadata config.
      if (start <= time && time < end) {
        return true
      }
    }
    return false
  }

  return predicate
}
