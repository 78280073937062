import { logInTrackJS } from '@/errors/helpers'
import Color from 'color'

/**
 * The formula for simple alpha compositing is
 *   co = Cs x αs + Cb x αb x (1 - αs)
 * Where
 *   co: the premultiplied pixel value after compositing
 *   Cs: the color value of the source graphic element being composited
 *   αs: the alpha value of the source graphic element being composited
 *   Cb: the color value of the backdrop
 *   αb: the alpha value of the backdrop
 *
 * @see https://www.w3.org/TR/compositing-1/#simplealphacompositing
 */
export function blendColorWithBackground(foreground: string, background: string | undefined): string {
  const fore = parseColorString(foreground) ?? Color('black')
  const back = parseColorString(background) ?? Color('white')

  function blendChannel(color: number, backdrop: number): number {
    return color * fore.alpha() + backdrop * back.alpha() * (1 - fore.alpha())
  }

  const red = blendChannel(fore.red(), back.red())
  const green = blendChannel(fore.green(), back.green())
  const blue = blendChannel(fore.blue(), back.blue())
  return Color([red, green, blue]).hex()
}

/**
 * Use when converting unknown string values into a Color object.
 */
export function parseColorString(value: string | undefined): Color<string> | undefined {
  try {
    return Color(value)
  } catch (e) {
    logInTrackJS('Unable to parse color', e)
  }
}

export function transparentize(original: string, opacity: number): string | undefined {
  if (opacity < 0 || opacity > 1) {
    throw Error('Opacity must be a value between 0 and 1 (inclusive)')
  }

  const color = parseColorString(original)
  if (color) {
    return color.alpha(color.alpha() * opacity).string()
  }
}
