import store from '@/store/store'

export function memberHasNoEmailAddress() {
  const user = store.getters['Member/user']
  return (
    // do not trigger for test+chypress email addresses, as those come in the 'no-email+...' format
    // but should not be considered as no-email addresses
    user && user.email.startsWith('no-email+') && user.email.indexOf('test+cypress') === -1

    // && user.email !== 'no-email+cypress-member@test-automation.d1tix.com'
  )
}
