
import SelectMenuFilter from '@/components/filters/SelectMenuFilter.vue'
import { formatDecimalHour } from '@/TixTime/helpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { TimeInterval, StringInterval } from '@/helpers/Intervals'

@Component({
  name: 'TimeOfDayFilters',
  components: { SelectMenuFilter },
})
export default class extends Vue {
  @Prop({ required: true })
  value: StringInterval

  @Prop({ required: true })
  visitWindow: TimeInterval

  // Half-hourly intervals.
  // TODO Move to config?
  intervalSize = 1 / 2

  get startOptions(): SelectOption[] {
    return this.options.filter(({ value }) => value! < this.value.end)
  }

  get endOptions(): SelectOption[] {
    return this.options.filter(({ value }) => value! > this.value.start)
  }

  private get options(): SelectOption[] {
    return this.intervals.map((hour) => {
      const { twelve, twentyFour } = formatDecimalHour(hour)
      return {
        label: twelve,
        value: twentyFour,
      }
    })
  }

  private get intervals(): number[] {
    const start = this.visitWindow.start.asDecimal()
    const end = this.visitWindow.end.asDecimal()
    const intervals = this.getMiddleIntervals(start, end, this.intervalSize)
    return [start, ...intervals, end]
  }

  private getMiddleIntervals(startTime: number, endTime: number, size: number): number[] {
    const result: number[] = []

    const start = Math.floor(startTime / size)
    const end = Math.ceil(endTime / size)

    for (let i = 1 + start; i < end; i++) {
      result.push(i * size)
    }

    return result
  }
}
