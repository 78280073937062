import { auth0LoginPath } from '@/helpers/Auth0Client'
import { environment } from '@/helpers/Environment'
import { removeParentCookie } from '@/helpers/MiscellaneousHelpers'
import { removeQueryParam } from '@/helpers/QueryStringParameters'
import { getCookie } from 'tiny-cookie'

/**
 * Consume any discount codes injected via the query string or cookie.
 */
export function preloadPromoCodes() {
  const promoCode = getQueryParamPromoCode() || getCookie('code')

  if (promoCode) {
    window.tixAppState.preloadedPromoCode = promoCode
  }

  // Always remove the promo code cookies, even if there is a query param promo code.
  removeParentCookie('code')
}

/**
 * Vue Router before each hook to remove the the promo code query param _after_ it has been consumed.
 */
export function removeQueryParamPromoCode(to, from, next) {
  if (to.path === auth0LoginPath) {
    // Ignore the `code` query string parameter when authenticating with Auth0.
    // It has a different meaning there.
    next()
  } else {
    next(removeQueryParam(to, 'code'))
  }
}

function getQueryParamPromoCode() {
  // Ignore the `code` query string parameter when authenticating with Auth0.
  // The code query parameter has a different meaning there.
  // Seller prefixes may mean that the router path is not at the beginning.
  if (window.URLSearchParams && !window.location.pathname.endsWith(auth0LoginPath)) {
    const params = new URLSearchParams(window.location.search)
    return params.get('code')
  }
}

export function getPreloadedPromoCodes(): string[] {
  const codes = window.tixAppState.preloadedPromoCode ?? defaultPromoCode() ?? ''
  return codes
    .split(',')
    .map((code) => code.trim())
    .filter((code) => code !== '')
}

export function defaultPromoCode(): string | undefined {
  return environment.web.default_promo_codes?.[0]
}
