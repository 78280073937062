
import FormInput2 from '@/components/forms/FormInput2.vue'
import { SurveyQuestion, surveyQuestionEnabled, SurveySpec } from '@/helpers/SurveyHelpers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * TODO Rename Survey.vue.
 */
@Component({
  name: 'Survey2',
  components: { FormInput2 },
})
export default class extends Vue {
  @Prop({ required: true })
  surveySpec: SurveySpec

  @Prop({ required: true })
  contexts: Set<string>

  @Prop({ default: false })
  hideHeader: boolean

  // Survey questions do not have default/initial values.
  answers: Dictionary = {}

  get fields() {
    return Object.entries(this.surveySpec.properties)
      .filter(([, question]) => surveyQuestionEnabled(question, this.contextsArray.join(' ')))
      .map(([id, question]) => {
        const { maximum, minimum } = question
        const required = this.surveySpec.required || []
        return {
          id,
          minimum,
          maximum,
          title: this.t.questionTitles[id],
          label: this.t.labelOverrides[id] || this.ignoreEmpty(question.title),
          description: this.ignoreEmpty(question.description),
          type: this.mapType(question),
          enum: question.enum,
          rank: question.propertyOrder,
          required: required.includes(id),
        }
      })
      .sort((a, b) => a.rank - b.rank)
  }

  get contextsArray() {
    return Array.from(this.contexts)
  }

  @Watch('answers', { deep: true })
  onAnswer() {
    const answers: Dict<Primitive | null> = {}
    Object.entries(this.answers)
      // Filter out empty values as they don't work with number inputs.
      // Will mean existing values can't be unset for editing survey answers in the future.
      .filter(([, value]) => value !== '')
      .forEach(([key, value]) => {
        const type = this.mapType(this.surveySpec.properties[key])
        answers[key] = type === 'number' ? Number(value) : value
      })

    this.$emit('input', answers)
  }

  ignoreEmpty(language?: string): string | undefined {
    if (language) {
      // Whitespace is used in the CMS to submit "empty" values for required form fields.
      // TODO Make the fields optional in CMS.
      const trimmed = language.trim()
      if (trimmed.length > 0) {
        return trimmed
      }
    }
  }

  mapType(question: SurveyQuestion): string {
    if (question.enum) {
      return 'options'
    }

    if (question.format === 'email') {
      return 'email'
    }

    if (question.format === 'date') {
      return 'date'
    }

    if (!question.type) {
      /* eslint-disable no-console */
      console.error(question)
      throw new Error('SurveyQuestion has no type')
    }

    const map = {
      integer: 'number',
      string: 'text',
    }
    const lower = question.type.toLowerCase()
    return map[lower] || lower
  }
}
