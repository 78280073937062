import stories from '@/stories/index'
import StoryViewerRoute from '@/stories/StoryViewerRoute.vue'
import type { RouteConfig } from 'vue-router'

const route: RouteConfig = {
  path: '/_stories',
  component: StoryViewerRoute,
  children: stories.map((story) => {
    return {
      path: story.path,
      component: story.component,
    }
  }),
}

export default route
