import type { TixDatePrecision, TTStringFormat } from '@/TixTime/helpers'
import moment from 'moment-timezone'

/**
 * Like TixTime, but with no time _nor timezone_.
 *
 * This makes it easier to author date-related code that is not prone to timezone bugs. 🎉
 *
 * TODO Replace Moment.js with a more modern datetime library.
 * TODO Replace moment.tz(this.#value, 'UTC') with a helper. Maybe this.#moment()
 */
export class TixDate {
  // The value is always formatted as YYYY-MM-DD.
  readonly #value: string
  private isTixDate = true

  static today(timezone: string): TixDate {
    return new TixDate(null, timezone)
  }

  constructor(date: string | null, timezone?: string) {
    if ((!date && !timezone) || (date && timezone)) {
      throw new Error('Must provide either a date or a timezone, not both')
    }

    if (timezone) {
      this.#value = moment.tz(undefined, timezone).format('YYYY-MM-DD')
    } else if (date) {
      // The input should match 4 digits, dash, 2 digits, dash, 2 digits. And be a valid date.
      const keyFormat = /^\d{4}-\d{2}-\d{2}$/
      if (keyFormat.test(date) && moment(date).isValid()) {
        this.#value = date
      }
    }

    if (!this.date) {
      throw new Error(`Invalid format for new TixDate: ${date}`)
    }
  }

  /**
   * YYYY-MM-DD format.
   */
  get date(): string {
    // This allows strictly read-only access to the internal value.
    return this.#value
  }

  add(amount: number, unit: TixDatePrecision): TixDate {
    const date = moment.tz(this.#value, 'UTC').add(amount, unit)
    return new TixDate(date.format('YYYY-MM-DD'))
  }

  subtract(amount: number, unit: TixDatePrecision): TixDate {
    const date = moment.tz(this.#value, 'UTC').subtract(amount, unit)
    return new TixDate(date.format('YYYY-MM-DD'))
  }

  getDayOfWeek(): number {
    return moment.tz(this.#value, 'UTC').day()
  }

  setDayOfWeek(day: number): TixDate {
    const date = moment.tz(this.#value, 'UTC').day(day)
    return new TixDate(date.format('YYYY-MM-DD'))
  }

  startOfMonth(): TixDate {
    const date = moment.tz(this.#value, 'UTC').startOf('month')
    return new TixDate(date.format('YYYY-MM-DD'))
  }

  endOfMonth(): TixDate {
    const date = moment.tz(this.#value, 'UTC').endOf('month')
    return new TixDate(date.format('YYYY-MM-DD'))
  }

  startOfWeek(): TixDate {
    const date = moment.tz(this.#value, 'UTC').startOf('week')
    return new TixDate(date.format('YYYY-MM-DD'))
  }

  endOfWeek(): TixDate {
    const date = moment.tz(this.#value, 'UTC').endOf('week')
    return new TixDate(date.format('YYYY-MM-DD'))
  }

  daysInMonth(): number {
    return moment.tz(this.#value, 'UTC').daysInMonth()
  }

  isBefore(date: TixDate | string): boolean {
    date = this.ensureTixDateType(date)
    return moment.tz(this.#value, 'UTC').isBefore(moment.tz(date.#value, 'UTC'))
  }

  isAfter(date: TixDate | string): boolean {
    date = this.ensureTixDateType(date)
    return moment.tz(this.#value, 'UTC').isAfter(moment.tz(date.#value, 'UTC'))
  }

  isSame(date: TixDate | string, precision: TixDatePrecision = 'day'): boolean {
    date = this.ensureTixDateType(date)

    // Compare strings if day comparison, which is much, much faster than moment.
    if (precision === 'day') {
      return this.#value === date.#value
    }
    return moment.tz(this.#value, 'UTC').isSame(moment.tz(date.#value, 'UTC'), precision)
  }

  isSameOrBefore(date: TixDate | string, precision: TixDatePrecision): boolean {
    // TODO Add unit tests.
    date = this.ensureTixDateType(date)
    return moment.tz(this.#value, 'UTC').isSameOrBefore(moment.tz(date.#value, 'UTC'), precision)
  }

  isBetween(
    start: TixDate | string,
    end: TixDate | string,
    precision?: TixDatePrecision,
    inclusivity?: '()' | '[)' | '(]' | '[]',
  ): boolean {
    start = this.ensureTixDateType(start)
    end = this.ensureTixDateType(end)

    return moment
      .tz(this.#value, 'UTC')
      .isBetween(moment.tz(start.#value, 'UTC'), moment.tz(end.#value, 'UTC'), precision, inclusivity)
  }

  // These will probably apply mostly-consistently across most JS date-time libraries.
  momentFormats: Record<TTStringFormat, string> = {
    LONG_DATE: 'ddd, MMM D, YYYY',
    LONGER_DATE: 'dddd, MMMM D, YYYY',
    DATE: 'YYYY-MM-DD',
  }

  // Should we consider separating to different methods here
  format(format: TTStringFormat): string {
    format = this.momentFormats[format] ?? format
    if (format === 'YYYY-MM-DD') return this.#value
    else return moment.tz(this.#value, 'UTC').format(format)
  }

  private ensureTixDateType(date: TixDate | string): TixDate {
    if (typeof date === 'string') {
      return new TixDate(date)
    }
    return date
  }
}
