
import { TranslateResult } from 'vue-i18n'
import { Component, Vue } from 'vue-property-decorator'
import EventBus from '@/helpers/EventBus'

@Component({ components: {} })
export default class CartExpiryModal extends Vue {
  language: TranslateResult

  created() {
    this.language = this.$t('cartExpire')
  }

  confirm() {
    EventBus.$emit('close:modal:all')
  }
}
