import { fetchCalendarForTicketGroupIDs } from '@/api/calendar'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'
import { environment } from '@/helpers/Environment'
import store from '@/store/store'
import type { Route } from 'vue-router'

export function processNextRoute(event: EventDetails, reservedTicketGroups: LinkedTG[]): string {
  const nextRoute = nextRouteConfiguredOnEvent(event, reservedTicketGroups)
  if (nextRoute) {
    store.commit('appendToUpsellsChain', nextRoute)
    return nextRoute
  } else {
    return environment.web.upsells ? '/events' : '/checkout'
  }
}

export function nextRouteConfiguredOnEvent(event: EventDetails, reservedTicketGroups: LinkedTG[]): string | void {
  // Sorting is necessary here.
  // TODO Work out why, and document it here.
  const groups = [...reservedTicketGroups].sort((a, b) => a._rank - b._rank)
  // Find first ticket group with a next_route configuration.
  const group = groups.find((group) => group.meta.next_route)
  if (group) {
    return group.meta.next_route
  } else {
    return event.config.web?.ecommerce?.next_route
  }
}

export function isChainedUpsell(route: Route): boolean {
  const stack = store.state['upsellsChain']
  if (stack.length > 0) {
    const last = stack[stack.length - 1]
    // The check only takes path into account, ignoring query params. For example,
    // `/events/123?foo=bar` matches `/events/123?tg=456` and `/events/123`
    return last.startsWith(route.path)
  }
  return false
}

// TODO Move into <EventDetailsRoute> loader().
export async function isEventAvailable(event: EventDetails): Promise<boolean> {
  const untimedHandlers = new Set<TGHandler>(['ledger', 'donation', 'membership', 'codes'])
  const isAlwaysAvailable = event.ticketGroups.every((group) => untimedHandlers.has(group.handler))

  if (isAlwaysAvailable) {
    return true
  }

  const ids = event.ticketGroups.map((group) => group.id)
  return fetchCalendarForTicketGroupIDs(event.id, ids).then((response) => {
    return Object.values(response).some((date) => date.status == 'available')
  })
}
