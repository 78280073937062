import { API } from '@/api/API'
import { fetchOwnCart } from '@/api/Cart'
import { authenticationMethod } from '@/helpers/Environment'
import { ensureLoggedOut } from '@/state/Authentication'
import store from '@/store/store'
import { TixTime } from '@/TixTime/TixTime'

export function loginAndTransferCart(credentials: Dictionary): Promise<void> {
  const cart = store.getters['Cart/cart']

  const parameters = {
    ...credentials,

    method: authenticationMethod,

    // Transfer any existing cart to logged in user.
    cart_id: cart?.id,
  }

  return store.dispatch('Member/login', parameters).then(() => {
    if (cart) {
      // TODO Update only the parts that have changed.
      return refreshStoredCart()
    }
  })
}

export function refreshStoredCart(): Promise<void> {
  const id = store.getters['Cart/cart'].id
  return fetchOwnCart(id)
    .then((response) => {
      const cart = response.cart._data[0]
      const tickets = response.ticket._data

      if (cart.state !== 'pending') {
        return store.dispatch('Cart/clearAll')
      } else if (tickets.length > 0) {
        store.commit('Cart/apiResponse', response)
      } else {
        // Clear the cart if it is now empty.
        // This also clears any associated expiry or giftee.
        return deleteCart(cart.id)
      }
    })
    .catch((error) => {
      if (isSellerScopeError(error)) {
        return deleteCart(id)
      } else if (error.response?.status === 404 || isStripeGatewayNotConfiguredError(error)) {
        return store.dispatch('Cart/clearAll')
      } else {
        throw error
      }
    })
}

/**
 *  A stripe_gateway not_configured error occurs when removing the seller prefix on the checkout page when there is no
 *  stripe account setup for the default. This will cause the cart to not be refreshed so need to clear it to stop the
 *  error as reloading the page won't get rid of it.
 */
function isStripeGatewayNotConfiguredError(error): boolean {
  return (
    error.response?.status === 400 &&
    error.response?.data._data[0]._context === 'stripe_gateway' &&
    error.response?.data._data[0]._code === 'not_configured'
  )
}

/**
 *  A scope_error error occurs when adding an item to the cart from one seller and then navigating to another seller's
 *  prefixed url.
 */
function isSellerScopeError(error): boolean {
  return error.response.status === 400 && error.response?.data._data[0]._code === 'scope_error'
}

export function injectCart({ cartId, orderId }: { cartId?: string; orderId?: string }): Promise<any> | void {
  if (cartId) {
    return ensureLoggedOut().then(() => store.dispatch('Cart/inject', cartId))
  }

  if (orderId) {
    return ensureLoggedOut().then(() => store.dispatch('Cart/injectOrder', orderId))
  }
}

export function cartExpired() {
  // This can not be a store getter because it is impure;
  // The return value changes depending on the current time.
  const localExpiry: number = store.getters['Cart/localExpiry']
  if (localExpiry) {
    try {
      return new TixTime(localExpiry).isBefore(new TixTime())
    } catch (e) {
      return false
    }
  } else {
    return false
  }
}

export function deleteCart(id): Promise<any> {
  return API.delete(`my/cart/${id}`)
    .catch((error) => {
      // Ignore cart not found errors and assume it has already been deleted.
      if (error.response?.data._data[0]._code !== 'not_found') {
        throw error
      }
    })
    .then(() => store.dispatch('Cart/clearAll'))
}
