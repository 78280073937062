
import { auth0Client, auth0LoginPath } from '@/helpers/Auth0Client'
import { authenticationMethod, environment } from '@/helpers/Environment'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import { openLoginModal } from '@/modals/loginModal'

@Component({
  name: 'LoginLink',
})
export default class extends Vue {
  onClick() {
    const externalLoginUrl = environment.web.external_urls?.login
    if (authenticationMethod === 'auth0_ecomm' && navigator.cookieEnabled) {
      auth0Client.then((client) => {
        if (client) {
          const slug = environment.seller?.slug
          const prefix = slug ? `/${slug}` : ''
          // @see https://auth0.com/docs/quickstart/spa/vuejs/01-login#create-an-authentication-wrapper
          client.loginWithRedirect({
            redirect_uri: window.location.origin + auth0LoginPath,
            appState: {
              fullPath: prefix + this.$route.fullPath,
            },
          })
        }
      })
    } else if (externalLoginUrl) {
      const cartId = store.getters['Cart/cartId']
      window.location.href = cartId ? `${externalLoginUrl}?cartId=${cartId}` : externalLoginUrl
    } else {
      openLoginModal()
        .then(() => this.$emit('success'))
        // TODO Reject the promise from openLoginModal() when the modal is dismissed.
        .catch(() => this.$emit('dismissed'))
    }
  }
}
