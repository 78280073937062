import { Vue } from 'vue-property-decorator'
import EventBus from '@/helpers/EventBus'
import { ModalOptions } from '@/modals/helpers/types'

export function openModal(options: ModalOptions) {
  Vue.prototype.$modals.$emit('new:modal', options)
}

export function registerModalEventListeners() {
  // dismisses a modal by name
  EventBus.$on('tix:modal:dismiss', (modalName) => {
    const modals = Vue.prototype.$modals
    modals.close({
      $index(data, modals) {
        return modals.findIndex((modal) => modal.name === modalName)
      },
    })
  })
}

export function getNormalizedModalOptions(options: Partial<ModalOptions>): Required<ModalOptions> {
  const defaults: Partial<ModalOptions> = {
    name: '',
    size: 'lg',
    closeable: true,
    onClose() {},
    desktop: 'modal',
    mobile: 'drawer',
  }

  return { ...defaults, ...options } as Required<ModalOptions>
}
