
import { imageUrlMaxWidth } from '@/helpers/ImageHelpers'
import { Component, Vue } from 'vue-property-decorator'
import { API } from '@/api/API'
import type { LanguageStrings } from '@/language/types'
import type { MetaData } from '@/api/types/processedEntities'
import { configYml } from '@/helpers/Environment'

@Component({ name: 'PendingOrdersRoute' })
export default class extends Vue {
  sellerMeta: MetaData = {}

  cart: CartEntity
  loading: boolean = true

  $el: HTMLElement

  t: LanguageStrings['pendingOrdersRoute']

  get referenceNumber() {
    return this.$t('pendingOrdersRoute.referenceNumber', { number: this.cart.cart_number })
  }

  get imageUrl() {
    const completedImage = configYml.componentOptions?.completedOrdersRoute?.completedImage ?? {}
    return {
      mobile: imageUrlMaxWidth(this.sellerMeta.completed_mobile_image) || completedImage.mobile,
      desktop: imageUrlMaxWidth(this.sellerMeta.completed_image) || completedImage.desktop,
    }
  }

  get contentOffset() {
    const completedContentOffset = configYml.componentOptions?.completedOrdersRoute?.completedContentOffset ?? {}
    return {
      mobile: this.sellerMeta.completed_content_mobile_offset || completedContentOffset.mobile,
      desktop: this.sellerMeta.completed_content_offset || completedContentOffset.desktop,
    }
  }

  created() {
    this.fetchCart()
      .then((response) => {
        if (response.ticket_order._count > 0) {
          this.$router.push({
            name: 'checkout/complete',
            query: {
              orderIds: response.ticket_order._data.map((order) => order.id) as any,
            },
          })
        }

        return response.cart._data[0]
      })
      .then((cart) => {
        this.setCSSCustomProperties()
        this.cart = cart
        this.loading = false
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return this.$notFound()
        }

        throw error
      })
  }

  private fetchCart(): Promise<ApiResponse<'cart' | 'ticket_order'>> {
    return API.getUncached(`cart/${this.$route.params.cartId}`, {
      embed: 'ticket_order',
      limit: 1000,
    })
  }

  private setCSSCustomProperties() {
    // Wait for the next JavaScript turn so that the <Loader> has gone and the content is in place.
    setTimeout(() => {
      this.setCSSCustomProperty('--completed-offset', this.contentOffset.desktop)
      this.setCSSCustomProperty('--completed-mobile-offset', this.contentOffset.mobile)
    })
  }

  private setCSSCustomProperty(name: string, value?: string) {
    if (value != null) {
      this.$el.style.setProperty(name, value)
    }
  }
}
