import type { SessionPickerTabsConfig } from '@/api/types/processedEntities'
import { toKebabCase } from '@/helpers/StringHelpers'
import type { AnnotatedSession } from '@/types/Sessions'
import type { TixTab } from '@/types/TixTab'

export interface SessionsGroup {
  id: string
  title: string
  max: string
  unavailableReason?: string
  sessions: AnnotatedSession[]
}

export function getSessionGroups(sessions: AnnotatedSession[], config: SessionPickerTabsConfig): SessionsGroup[] {
  // TODO Make the end-of-day time configurable. MeowWolf wants events starting at 12:30am to show up the day before.
  const min = '0000'
  const max = '2400'

  const threshold1 = config.first_tab_threshold
  const threshold2 = config.second_tab_threshold

  if (threshold2 && config.third_tab_name) {
    return [
      sessionGroup('group-1', config.first_tab_name, sessions, min, threshold1),
      sessionGroup('group-2', config.second_tab_name, sessions, threshold1, threshold2),
      sessionGroup('group-3', config.third_tab_name, sessions, threshold2, max),
    ]
  } else {
    return [
      sessionGroup('group-1', config.first_tab_name, sessions, min, threshold1),
      sessionGroup('group-2', config.second_tab_name, sessions, threshold1, max),
    ]
  }
}

export function toTixTab(group: SessionsGroup): TixTab {
  const unavailable = group.unavailableReason
  return {
    id: group.id,
    title: group.title,
    subtitle: unavailable,
    disabled: unavailable != undefined,
    classNames: toKebabCase(unavailable ?? ''),
  }
}

function sessionGroup(
  id: string,
  title: string,
  allSessions: AnnotatedSession[],
  min: string,
  max: string,
): SessionsGroup {
  const sessions = filterByTimeWindow(allSessions, Number(min), Number(max))
  const unavailableReason = getUnavailableReason(sessions)
  return { id, title, max, sessions, unavailableReason }
}

function getUnavailableReason(sessions: AnnotatedSession[]): string | undefined {
  if (sessions.length === 0) return 'Not available'
  else if (sessions.every((s) => s.sold_out)) return 'Sold out'
}

function filterByTimeWindow(sessions: AnnotatedSession[], min: number, max: number): AnnotatedSession[] {
  return sessions.filter((session) => {
    const start = Number(session.startTime.format('HHmm'))
    return min <= start && start < max
  })
}
