
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'MembershipLink' })
export default class extends Vue {
  @Prop({ default: false })
  renew: boolean

  get path(): string {
    return this.renew ? '/membership/renew' : '/membership'
  }

  constructor() {
    super()
  }
}
