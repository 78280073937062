
import { alterQueryParam } from '@/helpers/QueryStringParameters'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'SelectMenuFilter' })
export default class extends Vue {
  @Prop({ required: true })
  queryParamKey: string

  @Prop({ required: true })
  value: string

  @Prop({ required: true })
  options: SelectOption[]

  changed($event) {
    const queryParam = { [this.queryParamKey]: $event.target.value }
    this.$router.push(alterQueryParam(this.$route, queryParam))
  }
}
