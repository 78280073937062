
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { DayData } from './types'
import { TixDate } from '@/TixTime/TixDate'
import AnnotationBadges from '@/components/elements/AnnotationBadges.vue'
import AvailabilityStatus from '@/components/elements/AvailabilityStatus.vue'
import Price from '@/components/elements/Price.vue'

@Component({
  name: 'CalendarDay',
  components: { Price, AvailabilityStatus, AnnotationBadges },
})
export default class extends Vue {
  @Prop({ required: true })
  dayOfMonth: number

  @Prop({ required: true })
  day: DayData

  @Prop({ required: true })
  displayPrices: boolean

  @Prop()
  capacityThreshold: number | false

  @Prop()
  focusedDate: TixDate

  get enabled(): boolean {
    return this.day.enabled
  }

  get shouldDisplayPrices(): boolean {
    return this.displayPrices && this.day.price !== undefined
  }

  get isFocused(): boolean {
    return this.focusedDate && this.focusedDate.isSame(this.day.date)
  }

  onClick() {
    if (this.enabled) {
      this.$emit('input', this.day)
    }
  }

  get isAvailabilityDisplayed() {
    return this.capacityThreshold && this.day.available !== undefined && this.day.available < this.capacityThreshold
  }
}
