
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: {} })
export default class GiftAidCheckbox extends Vue {
  @Prop()
  label: string

  @Prop({ default: false })
  autofocus: boolean

  // Initial value property.
  @Prop({ default: null })
  value: boolean
}
