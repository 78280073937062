import { CartApiResponse, fetchCart } from '@/api/Cart'
import { apiEntities, firstEntity, linkTGRelations, mergeConfigIntoEvents } from '@/api/Helpers'
import type { EventWithConfig, LinkedTG, LinkedTT } from '@/api/types/processedEntities'
import type { WalletType } from '@/checkout/helpers/wallets'
import NotFoundError from '@/errors/NotFoundError'
import { anchorsAreConfigured } from '@/helpers/Anchors'
import { fetchOrderAndCart, findStripeFee, isFeesTicket, ticketsToCartItems } from '@/helpers/CartHelpers'
import { dictToMap } from '@/helpers/DictHelpers'
import { environment } from '@/helpers/Environment'
import { groupAsMap, groupBy, indexItemsById } from '@/helpers/IndexHelpers'
import { TimeInterval } from '@/helpers/Intervals'
import { sum } from '@/helpers/MiscellaneousHelpers'
import { requiresConfirmation } from '@/helpers/TicketEntity'
import { CartItem, isTimedItem, TimedItem } from '@/store/CartItem'
import { TixTime } from '@/TixTime/TixTime'
import type { ActionContext, Module } from 'vuex'

interface CartState {
  localExpiry: number | null
  giftCards: any[]
  apiResponse: null | CartApiResponse
  visitWindow: null | {
    start: string
    end: string
    timeZone: string
  }
  hasCitypassCoupons: null | true
  allDaySessionIds: string[]
  giftee: null | Dictionary
  autoRenewMembership: boolean
  wallet: null | WalletType
  redeemMembership: boolean
}

// Only use a factory function to prevent state leaking into the default.
function defaultState(): CartState {
  return {
    localExpiry: null,
    giftCards: [],
    apiResponse: null,
    visitWindow: null,
    allDaySessionIds: [],
    hasCitypassCoupons: null,
    giftee: null,
    autoRenewMembership: false,
    wallet: null,
    redeemMembership: false,
  }
}

export interface Cart extends CartEntity {
  ownedByPublicIdentity: boolean
}

export interface PendingGiftCardPayment {
  paymentAmount: number
  cardNumber: string
  availableBalance: number
}

interface FeeTicket {
  ticket: Ticket
  type: TicketType
  group: TicketGroup
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    /**
     * @deprecated Use state.giftee.
     */
    giftee({ giftee }) {
      return giftee
    },

    /**
     * @deprecated Use state.autoRenewMembership.
     */
    autoRenewMembership({ autoRenewMembership }) {
      return autoRenewMembership
    },

    cartId({ apiResponse: result }): string | void {
      if (result) {
        return result.cart._data[0].id
      }
    },

    cartNumber({ apiResponse: result }): string | void {
      if (result) {
        return result.cart._data[0].cart_number
      }
    },

    cart({ apiResponse: result }): void | Cart {
      if (result) {
        const cart = result.cart._data[0] as CartEntity
        return {
          ...cart,
          ownedByPublicIdentity: cart.identity_id === environment.portal.public_identity_id,
        }
      }
    },

    cartMods({ apiResponse: result }) {
      return result?.cartmod._data
    },

    blockingRules({ apiResponse: result }): CartMod[] | undefined {
      return result?.cartmod._data.filter(({ blocking_checkout }) => blocking_checkout)
    },

    cartFees({ apiResponse: result }): CartFee[] | void {
      return result?.cart_fees._data
    },

    stripeFee(_, { cartFees }): CartFee | void {
      if (cartFees) {
        return findStripeFee(cartFees)
      }
    },

    stripeAccountId(_, { stripeFee }): string {
      return stripeFee?.account_id
    },

    /**
     * @deprecated Use state.giftCards.
     */
    giftCards(state) {
      return state.giftCards
    },

    localExpiry({ localExpiry }): number | null {
      return localExpiry
    },

    visitWindow({ visitWindow }): TimeInterval | void {
      if (visitWindow) {
        // Deserialize the TixTime objects.
        const { start, end, timeZone } = visitWindow
        return {
          start: new TixTime(start, timeZone),
          end: new TixTime(end, timeZone),
        }
      }
    },

    ticketCount(state, { cartItems }): number {
      const values = cartItems.map((item) => {
        const count = sum(item.types.map((type) => type.ticketCount))
        return item.isMembershipEvent ? 1 : count
      })
      return sum(values)
    },

    // TODO Return something more similar to the grand_totals Dict<number> than 0 if there is no cart?
    totals(_, { stripeFee }): CartFee['grand_totals'] | 0 {
      return stripeFee?.grand_totals ?? 0
    },

    // After promo codes (cartmod).
    faceValue(state, { totals, feeTickets }) {
      // Discount fee ticket values, since the API doesn't take them into account.
      const fees = sum(feeTickets.map(({ ticket }) => parseFloat(ticket.face_value)))
      return parseFloat(totals.total_before_cartmod_price) - fees
    },

    totalFixedFees(state, { totals }) {
      return parseFloat(totals.total_fee_fixed_outside)
    },

    totalPercentFees(state, { totals }) {
      return parseFloat(totals.total_fee_percent_outside)
    },

    totalPromoDiscounts(state, { totals }) {
      return parseFloat(totals.total_cartmod_amount)
    },

    totalPayments(state, { totals }) {
      return parseFloat(totals.total_payments)
    },

    paymentDue(state, { totals }): number {
      return parseFloat(totals.total_outstanding)
    },

    giftCardBalance({ giftCards }) {
      return sum(giftCards.map((card) => card.balance))
    },

    paymentDueByCreditCard(_, { paymentDue, giftCardBalance }) {
      return Math.max(0, paymentDue - giftCardBalance)
    },

    pendingGiftCardPayments({ giftCards }, { paymentDue }): PendingGiftCardPayment[] {
      let remainder = paymentDue
      return giftCards.map((card) => {
        const amount = Math.min(remainder, card.balance)
        remainder -= amount
        return {
          cardNumber: card.number,
          availableBalance: card.balance,
          paymentAmount: amount,
          remainingBalance: card.balance - amount,
        }
      })
    },

    identityFormConfigurations({ apiResponse }): string[] {
      if (apiResponse) {
        return apiResponse.meta._data
          .filter(({ metakey, resource }) => {
            // Legacy support.
            if (resource === 'ticket_group' && metakey === 'extra_checkout_fields') {
              return true
            } else if (resource === 'ticket_group' || resource === 'event_template') {
              return metakey === 'identity_form'
            }
          })
          .map((meta) => meta.value)
      } else {
        return []
      }
    },

    currentCartExists(_, { cart, localExpiry }): boolean {
      if (!cart) {
        return false
      }

      if (!localExpiry) {
        // Held carts don't have a localExpiry because they weren't created by the web UI.
        return true
      }

      return new TixTime(localExpiry).isAfter(new TixTime())
    },

    cartHasItems({ apiResponse: result }) {
      return result && result.ticket._data.length > 0
    },

    cartItems(
      { apiResponse },
      getters: {
        currentCartExists: boolean
        allDaySessionIds: Set<string>
        eventsWithConfig: EventWithConfig[]
        indexedLinkedTicketTypes: Dict<LinkedTT>
        indexedLinkedTicketGroups: Dict<LinkedTG>
      },
    ): CartItem[] {
      if (!apiResponse || !getters.currentCartExists) {
        return []
      }

      const entities = apiEntities(apiResponse)
      const sessions = indexItemsById(entities.event_session)
      const types = getters.indexedLinkedTicketTypes
      const groups = getters.indexedLinkedTicketGroups
      const templates = indexItemsById(getters.eventsWithConfig)

      // Filter out fee tickets.
      const tickets = entities.ticket.filter((ticket) => !isFeesTicket(ticket, types, groups))

      // Group tickets by session.
      const bySession = groupBy('event_session_id', tickets)

      // Group the session-less tickets by template. E.g. Membership.
      const byTemplate = groupAsMap(bySession.null || [], 'event_template_id', templates)

      // Remove the session-less tickets from the tickets grouped by session.
      delete bySession.null

      // Then build a Map from the remaining sessions.
      const sessionGroups = dictToMap(bySession, sessions)

      // Store various lookup tables for re-use later.
      const data = {
        templates,
        allDaySessionIds: getters.allDaySessionIds,
        ticketTypes: types,
        ticketGroups: groups,
        venues: indexItemsById(entities.venue),
      }

      const untimed = ticketsToCartItems(byTemplate, data)
      const timed = (ticketsToCartItems(sessionGroups, data) as TimedItem[])
        // TODO Use event template rank for secondary sort.
        // .sort((a, b) => (a.event._rank - b.event._rank))
        .sort((a, b) => (a.startTime.isBefore(b.startTime) ? -1 : 1))

      const memberships = untimed.filter((item) => item.isMembershipEvent)
      const otherUntimed = untimed.filter((item) => !item.isMembershipEvent)

      // Order of items in the cart should be
      // 1. Memberships
      // 2. Cart items with sessions
      // 3. Other cart items without sessions
      return [...memberships, ...timed, ...otherUntimed]
    },

    feeTickets(
      { apiResponse },
      getters: {
        tickets: Ticket[]
        currentCartExists: boolean
        allDaySessionIds: Set<string>
        eventsWithConfig: EventWithConfig[]
        indexedLinkedTicketTypes: Dict<LinkedTT>
        indexedLinkedTicketGroups: Dict<LinkedTG>
      },
    ): FeeTicket[] {
      if (!apiResponse || !getters.currentCartExists) {
        return []
      }

      const types = getters.indexedLinkedTicketTypes
      const groups = getters.indexedLinkedTicketGroups
      return getters.tickets
        .filter((ticket) => isFeesTicket(ticket, types, groups))
        .map((ticket) => {
          return {
            ticket,
            type: types[ticket.ticket_type_id],
            group: groups[ticket.ticket_group_id],
          }
        })
    },

    indexedLinkedTicketGroups(_, { linkedTicketGroups }): void | Dict<TicketGroup> {
      return indexItemsById(linkedTicketGroups)
    },

    indexedLinkedTicketTypes(_, { linkedTicketGroups }): void | Dict<TicketGroup> {
      return indexItemsById(linkedTicketGroups.flatMap((group) => group.types))
    },

    anchor(_, { cartItems }) {
      // There are two definitions of anchors, depending on whether upsell configuration
      // specifies anchor events or allows any event to be an anchor;
      if (anchorsAreConfigured()) {
        return cartItems.find((item) => item.isAnchor)
      } else {
        // If no anchors are configured, use the first timed cart item as an anchor.
        return cartItems.find(isTimedItem)
      }
    },

    tickets({ apiResponse: result }): Ticket[] {
      return result?.ticket._data ?? []
    },

    /**
     * @deprecated Identity does not indicate the cart is injected and it is not necessarily the owner.
     */
    injectedCartOwner({ apiResponse: result }) {
      return result?.identity._data[0]
    },

    eventTemplates({ apiResponse: result }): EventTemplate[] {
      return result?.event_template._data ?? []
    },

    eventsWithConfig({ apiResponse }: CartState): EventWithConfig[] {
      if (apiResponse) {
        return mergeConfigIntoEvents(apiResponse.event_template._data, apiResponse)
      } else {
        return []
      }
    },

    ticketTypes({ apiResponse: result }) {
      return result!.ticket_type._data
    },

    ticketGroups({ apiResponse: result }): TicketGroup[] {
      return result?.ticket_group._data ?? []
    },

    linkedTicketGroups({ apiResponse }): LinkedTG[] {
      if (apiResponse) {
        const data = apiEntities(apiResponse)
        return linkTGRelations(data.ticket_group, data.meta, data.ticket_type)
      } else {
        return []
      }
    },

    uniqueDatesInCart(_, { cartItems }) {
      const dates = cartItems.filter((item) => item.startTime != null).map((item) => item.startTime.format('DATE'))
      return [...new Set(dates)]
    },

    allDaySessionIds({ allDaySessionIds }) {
      return new Set(allDaySessionIds)
    },
  },

  mutations: {
    giftee(state, value) {
      state.giftee = value
    },

    localExpiry(state, value) {
      state.localExpiry = value
    },

    // Only used by clearAll.
    giftCards(state, cards) {
      state.giftCards = cards
    },

    addGiftCard(state, giftCard) {
      state.giftCards.push(giftCard)
    },

    removeGiftCard(state, number) {
      state.giftCards = state.giftCards.filter((card) => number !== card.number)
    },

    apiResponse(state, result) {
      state.apiResponse = result
    },

    allDaySessionIds(state, result) {
      state.allDaySessionIds = result
    },

    hasCitypassCoupons(state, value) {
      state.hasCitypassCoupons = value
    },

    visitWindow(state, value) {
      if (value) {
        const { start, end } = value
        // Serialize the Tix Time objects.
        state.visitWindow = {
          start: start.format('iso'),
          end: end.format('iso'),
          timeZone: start.timezone,
        }
      } else {
        state.visitWindow = null
      }
    },

    autoRenewMembership(state, value) {
      state.autoRenewMembership = value
    },

    wallet(state, value) {
      state.wallet = value
    },

    redeemMembership(state, value) {
      state.redeemMembership = value
    },
  },

  actions: {
    addAllDaySession(context: ActionContext<CartState, CartState>, id) {
      context.commit('allDaySessionIds', [id, ...context.state.allDaySessionIds])
    },

    clearAll(context: ActionContext<CartState, CartState>) {
      // Do not clear legacy properties that are no longer supported.
      const state = defaultState()
      for (const key in state) {
        context.commit(key, state[key])
      }
    },

    // This is used for payment links and cart hand-off. Scenarios 3-6 in the checkout matrix;
    // https://docs.google.com/spreadsheets/d/1L3OMbW1Tc3CHpx3GgkIXvVRpzXamIEtdtqlUzHV4vYw/edit?usp=sharing
    inject(context: ActionContext<CartState, CartState>, id) {
      return fetchCart(id).then((response) => {
        const [cart] = firstEntity(response, 'cart')
        const { cart_fees: fees, ticket: tickets } = apiEntities(response)
        const fee = findStripeFee(fees)
        const outstanding = parseFloat(fee.grand_totals.total_outstanding)
        // Tickets may be issued without payment, which confirms all tickets but leaves payment owing.
        // Is a payment due? Or do any tickets need to be confirmed?
        if (outstanding > 0 || tickets.some(requiresConfirmation)) {
          context.dispatch('clearAll')
          context.commit('apiResponse', response)
        } else {
          // TODO Show a more useful error message? E.g. "Cart is already booked and fully paid and cannot be processed again"
          throw new NotFoundError('cart', cart)
        }
      })
    },

    // <EditOrderRoute> uses this Scenarios 7-8 in the checkout matrix;
    // https://docs.google.com/spreadsheets/d/1L3OMbW1Tc3CHpx3GgkIXvVRpzXamIEtdtqlUzHV4vYw/edit?usp=sharing
    injectOrder(context: ActionContext<CartState, CartState>, orderId: string) {
      return fetchOrderAndCart(orderId).then(({ cart: response }) => {
        // TODO Assert the cart is booked?
        // response.cart._data[0].state === 'booked'
        context.dispatch('clearAll')
        context.commit('apiResponse', response)
      })
    },
  },
} as Module<CartState, CartState>
