import VueRouter from 'vue-router'

/**
 * Navigate back. Go Home if there's no history.
 */
export function navigateBack(router: VueRouter) {
  let goneBack = false

  // Arbitrary number to make sure that the check happens after `popstate`
  // callback is fired
  const goneBackCheckDelay = 300

  const popstateCallback = () => (goneBack = true)

  window.addEventListener('popstate', popstateCallback)

  router.go(-1)

  setTimeout(() => {
    if (!goneBack) {
      router.push('/')
    }
    window.removeEventListener('popstate', popstateCallback)
    goneBack = false
  }, goneBackCheckDelay)
}
