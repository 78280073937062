
import FormInput2 from '@/components/forms/FormInput2.vue'
import { applyPromoCode } from '@/helpers/PromoCodes'
import { promoCodeErrorMessage } from '@/helpers/PromoCodesHelper'
import { reportFormValidity } from '@/helpers/Validation'
import { Component, Prop, Vue } from 'vue-property-decorator'

// TODO Merge/deduplicate with <CodeModal> component.
@Component({
  name: 'PromoCodesModal',
  components: { FormInput2 },
})
export default class extends Vue {
  @Prop({ default: false })
  submitDisabled: boolean

  private readonly minLength = 2

  rawValue: string = ''
  applying: boolean = false
  error: string | null = null

  get value() {
    return this.rawValue.trim()
  }

  apply() {
    this.applying = true
    this.error = null

    reportFormValidity(this.$refs.form as HTMLFormElement)
      .then(() => applyPromoCode(this.value))
      .then(() => {
        this.rawValue = ''
        this.$modals.close()
      })
      .catch((error) => {
        const input = this.$refs.input as HTMLInputElement
        this.error = promoCodeErrorMessage(this.value, error, input)

        if (this.error === this.$t('applyPromoCodes.unknownErrorMessage')) {
          throw error
        }
      })
      .finally(() => {
        this.applying = false
      })
  }
}
