
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { LanguageStrings } from '@/language/types'

@Component({ name: 'AvailabilityStatus' })
export default class extends Vue {
  @Prop()
  isSoldOut: boolean

  @Prop({ required: true })
  available: number

  @Prop({ required: true })
  threshold: number | false

  t: LanguageStrings['availabilityStatus']

  get label(): string {
    if (this.isSoldOut) return this.t.soldOut
    else if (!this.threshold) return ''
    else if (!this.lowAvailability) return this.t.available
    else return this.$t('availabilityStatus.nAvailable', { count: this.available }) as string
  }

  get lowAvailability(): boolean {
    return this.available < (this.threshold || 0)
  }
}
