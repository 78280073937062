import { ModalCloseHandlerFn } from '@/modals/helpers/types'
import { openModal } from '@/modals/helpers/api'
import TermsModal from '@/modals/TermsModal.vue'

export function openTermsModal(title: string, onClose?: ModalCloseHandlerFn): Promise<void> {
  return new Promise(() =>
    openModal({
      name: 'terms-conditions',
      component: TermsModal,
      title,
      onClose,
    }),
  )
}
