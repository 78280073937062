
import { LanguageStrings } from '@/language/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Portal } from 'portal-vue'
import { TixTimeDuration } from '@/TixTime/Duration'

@Component({ name: 'ExpiryTimer', components: { Portal } })
export default class extends Vue {
  t: LanguageStrings['expiryTimer']

  @Prop()
  expiresIn: TixTimeDuration

  // For screen readers only
  screenReaderExpiryMinutes: number | null = null
  screenReaderExpirySeconds: number | null = null

  readonly first_threshold = 5
  readonly second_threshold = 2

  mounted() {
    this.updateScreenReaderExpiry()
  }

  updateScreenReaderExpiry() {
    this.screenReaderExpiryMinutes = this.expiresIn.minutes()
    this.screenReaderExpirySeconds = this.expiresIn.seconds()
  }

  @Watch('expiresIn')
  handleExpiresInChange() {
    const minutes = this.expiresIn.minutes()
    const seconds = this.expiresIn.seconds()

    if (seconds === 0 && (minutes === this.first_threshold || minutes === this.second_threshold)) {
      this.updateScreenReaderExpiry()
    }
  }

  get formattedTime() {
    const expiresIn = this.expiresIn
    if (expiresIn.asSeconds() > 0) {
      return expiresIn.format('m:ss')
    } else {
      return null
    }
  }

  get timerClass(): '' | 'warning' | 'error' {
    const minutes = this.expiresIn.asMinutes()
    if (minutes < this.second_threshold) {
      return 'error'
    } else if (minutes < this.first_threshold) {
      return 'warning'
    }
    return ''
  }
}
