







import { Component } from 'vue-property-decorator'
import BaseIcon from './BaseIcon.vue'

@Component({})
export default class extends BaseIcon {}
