
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SectionHeader',
})
export default class extends Vue {
  @Prop({ required: true })
  title: string

  @Prop()
  caption: string
}
