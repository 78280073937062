import { API } from '@/api/API'
import { linkTypesBackToGroups } from '@/api/Helpers'
import type { LinkedTG } from '@/api/types/processedEntities'
import { groupBy } from '@/helpers/IndexHelpers'
import { showAdvertisedFees } from '@/helpers/Fees'

export function getPricedTicketGroups(id: string, groups: LinkedTG[]): Promise<LinkedTG[]> {
  const ttIds = groups.map((group) => group.types.map((type) => ({ ticket_type_id: type.id }))).flat()

  if (!showAdvertisedFees() || ttIds.length === 0) {
    // Don't attempt to get prices if there are no ttIds as the API will return an error if there isn't at least 1 ticket type.
    return Promise.resolve(groups)
  }

  const path = `events/${id}/pricecheck`
  const args = { body: { ticket_types_required: ttIds } }
  return API.post(path, args).then(({ ticket_type }) => {
    const types = ticket_type._data
    const typesByGroupId = groupBy('ticket_group_id', types)
    // Merge groups with returned types
    const mergedGroups: LinkedTG[] = groups.map((group) => {
      return {
        ...group,
        types: typesByGroupId[group.id],
      } as LinkedTG
    })
    linkTypesBackToGroups(mergedGroups)
    return mergedGroups
  })
}
