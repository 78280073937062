import { isLoggedIn } from '@/state/Authentication'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class extends Vue {
  get isLoggedIn() {
    return isLoggedIn()
  }

  @Watch('isLoggedIn')
  handleLogin(value: boolean) {
    if (value) {
      this.$router.push('/')
    }
  }
}
