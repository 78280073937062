import Seat from '@/seats/Seat'

/**
 * First-in-first-out queue for neighbouring seats.
 */
export default class NeighboringSeatQueue {
  items: Seat[] = []

  /**
   * Returns the first available item in the queue.
   *
   * Check the availability as late as possible to reduce the chance of incorrectly checking it before selection of
   * a previous seat in the queue has completed.
   */
  get next(): Seat | undefined {
    let item = this.items.shift()
    while (item && item.state !== 'available') {
      item = this.items.shift()
    }
    return item
  }

  add(seat?: Seat) {
    if (seat) {
      this.items.push(seat)
    }
  }
}
