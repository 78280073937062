
import { API } from '@/api/API'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { defaultLedgerType } from '@/helpers/Environment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  name: 'ApplyGiftCardsModal',
  components: { FormInput2 },
  computed: {
    ...mapGetters({
      appliedCards: 'Cart/giftCards',
      paymentDueByCreditCard: 'Cart/paymentDueByCreditCard',
    }),
  },
})
export default class extends Vue {
  @Prop({ default: false })
  processing: boolean

  newCardNumber: string = ''
  applying: boolean = false
  error: string = ''

  appliedCards

  apply() {
    if (this.appliedCards.some((card) => card.number === this.newCardNumber)) {
      this.error = 'Oops! You already applied this gift card'
      return
    }

    this.applying = true

    const input = this.$refs.cardNumber as HTMLInputElement
    const cardNumber = encodeURIComponent(input.value)
    API.getUncached(`ledger/${defaultLedgerType}/${cardNumber}`)
      .then((result) => {
        const giftCard = result.ledger._data[0]
        const { number, state } = giftCard
        const balance = parseFloat(giftCard.balance)

        if (balance <= 0 || giftCard.state !== 'active') {
          this.error = 'Gift Card balance is $0'
        } else {
          // Store the new gift card number.
          this.$store.commit('Cart/addGiftCard', { number, balance, state })

          // Reset state.
          this.newCardNumber = ''
          this.error = ''
          this.$modals.close()
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          this.error = this.t.giftCardNotFound as string
        } else {
          this.error = 'We are having trouble with that sorry. Please try again later.'
          throw new Error('Unexpected error applying gift card number')
        }
      })
      .finally(() => {
        // Reset state
        this.applying = false
      })
  }
}
