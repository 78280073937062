import { blendColorWithBackground, parseColorString, transparentize } from '@/helpers/Color'
import { environment } from '@/helpers/Environment'
import { getThemeValue } from '@/themeConfig/processing'
import type { NormalizedThemeOptions } from '@/themeConfig/types'
import type { Appearance } from '@stripe/stripe-js'
import type { StripeElementsOptionsMode } from '@stripe/stripe-js/types/stripe-js/elements-group'

export interface PaymentElementStyles {
  label: CSSStyleDeclaration
  input: {
    default: CSSStyleDeclaration
    hover: CSSStyleDeclaration
    focus: CSSStyleDeclaration
    invalid: CSSStyleDeclaration
  }
  message: CSSStyleDeclaration
}

export function stripeOptions(
  theme: NormalizedThemeOptions,
  paymentDueByCreditCard: number,
  styles: PaymentElementStyles,
  // TODO Make this required once all tenants have been migrated to provide pmc_id?
  paymentMethodConfig?: string,
): StripeElementsOptionsMode {
  return {
    mode: 'payment',
    amount: Math.round(paymentDueByCreditCard * 100),
    currency: environment.portal.default_currency_code.toLowerCase(),
    paymentMethodConfiguration: paymentMethodConfig,
    paymentMethodCreation: 'manual',
    fonts: fontOptions(),
    appearance: {
      variables: variables(theme, styles),
      rules: rules(theme, styles),
    },
  }
}

function rules(theme: NormalizedThemeOptions, { input, label, message }: PaymentElementStyles): Appearance['rules'] {
  const labelText = getTextStyle(label)
  const isDarkTheme = theme.themeMode === 'dark'
  const pageBackgroundColor = getThemeValue(theme.paymentElement?.background ?? theme.pageBackgroundColor, isDarkTheme)
  const borderColor = blendColorWithBackground(input.default.getPropertyValue('border-color'), pageBackgroundColor)
  const accordionHoverColor = parseColorString(label.getPropertyValue('color'))!
    .darken(1 / 5)
    .hex()

  return {
    '.AccordionItem': {
      backgroundColor: getThemeValue(theme.paymentElement?.background, isDarkTheme) ?? 'transparent',
      borderColor: borderColor,
      ...labelText,
    },
    '.AccordionItem:hover': {
      // Passing in undefined doesn't cause any errors and this should always be defined as the string comes
      // from CSS and should be a valid color string but have to use `!` to stop it complaining about types.
      color: accordionHoverColor!,
    },
    '.Input': getInputStyle(input.default, pageBackgroundColor),
    '.Input:hover': getInputStyle(input.hover, pageBackgroundColor),
    '.Input:focus, .Input:focus:hover': getInputStyle(input.focus, pageBackgroundColor),
    '.Input--invalid, .Input--invalid:hover': getInputStyle(input.invalid, pageBackgroundColor),
    '.Label': {
      ...labelText,
      margin: label.getPropertyValue('margin'),
    },
    '.Error': {
      ...getTextStyle(message),
      margin: message.getPropertyValue('margin'),
    },
    '.Block': {
      backgroundColor: 'transparent',
      borderColor: borderColor,
    },
  }
}

function getTextStyle(s: CSSStyleDeclaration): Dictionary {
  return {
    color: s.getPropertyValue('color'),
    fontWeight: s.getPropertyValue('font-weight'),
    fontSize: s.getPropertyValue('font-size'),
    fontFamily: s.getPropertyValue('font-family'),
    textTransform: s.getPropertyValue('text-transform'),
    lineHeight: s.getPropertyValue('line-height'),
  }
}

function getInputStyle(s: CSSStyleDeclaration, pageBackgroundColor: string | undefined) {
  return {
    backgroundColor: blendColorWithBackground(s.getPropertyValue('background-color'), pageBackgroundColor),
    color: blendColorWithBackground(s.getPropertyValue('color'), s.getPropertyValue('background-color')),
    border: s.getPropertyValue('border'),
    padding: s.getPropertyValue('padding'),
    fontWeight: s.getPropertyValue('font-weight'),
    fontSize: s.getPropertyValue('font-size'),
    fontFamily: s.getPropertyValue('font-family'),
    lineHeight: s.getPropertyValue('line-height'),
    outline: s.getPropertyValue('outline'),
    outlineOffset: s.getPropertyValue('outline-offset'),
    boxShadow: s.getPropertyValue('box-shadow'),
    transition: s.getPropertyValue('transition'),
  }
}

function fontOptions(): StripeElementsOptionsMode['fonts'] {
  return (
    Array.from(document.querySelectorAll<HTMLLinkElement>('link[data-fonts]'))
      .map((link) => link.href)
      .filter((url) => url != null)
      // `url` shouldn't be null after the filter but typescript doesn't understand this so need to include `as string`.
      .map((url) => ({ cssSrc: url as string }))
  )
}

function variables(theme: NormalizedThemeOptions, source?: PaymentElementStyles): Appearance['variables'] | undefined {
  if (!source) return

  const { input, label } = source

  const isDarkTheme = theme.themeMode === 'dark'
  const backgroundColor = getThemeValue(theme.paymentElement?.background ?? theme.pageBackgroundColor, isDarkTheme)
  const textColor = blendColorWithBackground(label.getPropertyValue('color'), backgroundColor)
  return {
    colorPrimary: getThemeValue(theme.primaryColor, isDarkTheme),
    colorLogo: isDarkTheme ? 'dark' : 'light',
    colorTextPlaceholder: getPlaceholderColor(input.default.getPropertyValue('color'), isDarkTheme),
    colorText: textColor,
    colorTextSecondary: textColor,
    fontFamily: label.getPropertyValue('font-family'),
    fontSizeBase: input.default.getPropertyValue('font-size'),
    fontSmooth: input.default.getPropertyValue('-webkit-font-smoothing'),
    fontVariantLigatures: input.default.getPropertyValue('font-variant'),
    fontLineHeight: input.default.getPropertyValue('line-height'),
    borderRadius: input.default.getPropertyValue('border-radius'),
  }
}

function getPlaceholderColor(color: string, isDarkTheme: boolean) {
  const placeholder = transparentize(color, 0.5)
  if (placeholder) {
    return placeholder
  } else {
    return isDarkTheme ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
  }
}
