import { IdentityFormData } from '@/helpers/IdentityHelpers'
import store from '@/store/store'

export function identityDataForStripe(values: IdentityFormData) {
  const [identity] = store.getters['Member/identityWithMeta']
  if (identity) {
    return {
      ...identity.meta,
      zip_code: undefined,
      // Do not allow custom identity fields to overwrite built-in identity fields.
      name: identity.name,
      email: identity.email,
    }
  } else {
    const first = values.first_name
    const last = values.last_name
    return {
      ...values,
      // For the purpose of Stripe validation, use regular whitespace character to join the names.
      name: first && last ? `${first} ${last}` : undefined,
    }
  }
}
