import { i18n } from '@/app/I18n'
import { environment } from '@/helpers/Environment'
import type { LanguageStrings } from '@/language/types'
import store from '@/store/store'

export function eventListingTitle() {
  const t = languageItem('eventListingRoute')
  return store.getters['Cart/cartHasItems'] ? t.upsellsTitle : t.title
}

export function changeMemberDetailsInstructions(): string {
  return (
    environment.portalStrings.change_member_details_instructions ??
    'To change your membership details, please contact us.'
  )
}

/**
 * Use LanguageStrings interface/type when accessing i18n language properties.
 */
export function languageItem<Key extends keyof LanguageStrings>(key: Key): LanguageStrings[Key] {
  return i18n.t(key as string) as any
}

export function reserveLanguage<Key extends 'reserveDateFirst' | 'reserveQuantityFirst'>(
  name: Key,
): LanguageStrings['reserve'] & LanguageStrings[Key] {
  return {
    ...languageItem('reserve'),
    // Allow the more specific key to override the more generic key.
    ...languageItem(name),
  }
}
