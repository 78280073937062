
import { Component, Prop, Vue } from 'vue-property-decorator'
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon.vue'
import ChevronRightIcon from '../components/icons/ChevronRightIcon.vue'

@Component({
  name: 'CalendarScrollButton',
  components: { ChevronLeftIcon, ChevronRightIcon },
})
export default class extends Vue {
  @Prop({ required: true })
  enabled: boolean

  @Prop({ required: true })
  direction: string

  handleScrollButtonPress(delta: number): void {
    if (this.enabled) {
      this.$emit('scroll', delta)
    }
  }

  get isPrevious(): boolean {
    return this.direction === 'prev'
  }

  get classNames(): Dict<boolean> {
    return {
      disabled: !this.enabled,
      'prev-month': this.isPrevious,
      'next-month': !this.isPrevious,
    }
  }
}
