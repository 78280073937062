import { TTStringFormat } from '@/TixTime/helpers'
import type { Duration } from 'moment-timezone'
import moment from 'moment-timezone'

export class TixTimeDuration {
  duration: Duration

  constructor(duration: number) {
    this.duration = moment.duration(duration)
  }

  asMinutes(): number {
    return this.duration.asMinutes()
  }

  asSeconds(): number {
    return this.duration.asSeconds()
  }

  asHours(): number {
    return this.duration.asHours()
  }

  asDays(): number {
    return this.duration.asDays()
  }

  asMilliseconds(): number {
    return this.duration.asMilliseconds()
  }

  minutes(): number {
    return this.duration.minutes()
  }

  seconds(): number {
    return this.duration.seconds()
  }

  format(format: TTStringFormat = 'iso'): string {
    return moment.utc(this.duration.asMilliseconds()).format(format)
  }
}
