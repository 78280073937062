
import { logInTrackJS } from '@/errors/helpers'
import type { StripeElements, StripePaymentElementOptions } from '@stripe/stripe-js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { PaymentMethodName } from '@/checkout/stripe/helpers'

@Component({
  name: 'StripePaymentElement',
})
export default class extends Vue {
  @Prop({ required: true })
  value: PaymentMethodName

  @Prop({ required: true })
  elements: StripeElements

  mounted() {
    const options: StripePaymentElementOptions = {
      paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
      layout: 'accordion',
      fields: {
        billingDetails: {
          address: 'never',
          name: 'never',
          email: 'never',
        },
      },
    }
    const el = this.elements.create('payment', options)
    el.on('loaderror', (e) => logInTrackJS(e.error.message!, e.error))
    el.on('change', (event) => this.$emit('input', event.value.type))
    el.on('focus', (e) => this.$emit('focus', e))
    el.mount(this.stripeElement)
  }

  private get stripeElement() {
    return this.$refs.stripeElement as HTMLElement
  }
}
