
import { alterQueryParam, QueryParamFilter } from '@/helpers/QueryStringParameters'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @deprecated OMSI is the only customer using or desiring this feature.
 */
@Component({
  name: 'DateFilters',
})
export default class extends Vue {
  @Prop({ default: null })
  value: string | null

  @Prop({ default: false })
  disabled: boolean

  get links(): QueryParamFilter[] {
    const all = this.filterLocation(null, this.t.all as string)

    return [all].concat(this.dateOptions)
  }

  get dateOptions(): QueryParamFilter[] {
    const t: Dictionary = this.$t('date') as any

    const map = {
      today: t.today,
      tomorrow: t.tomorrow,
      // week: 'This Week',
      // weekend: 'This Weekend',
      // nextWeek: 'Next Week',
      // nextWeekend: 'Next Weekend',
      // TODO Date picker.
    }

    return Object.entries(map).map(([value, label]) => this.filterLocation(value, label))
  }

  filterLocation(value: string | null, label: string) {
    return {
      value,
      label,
      location: alterQueryParam(this.$route, { date: value }),
    }
  }
}
