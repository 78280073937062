
import { Component, Vue } from 'vue-property-decorator'
import { environment } from '@/helpers/Environment'

@Component({
  name: 'TermsModal',
})
export default class extends Vue {
  get termsAndConditions(): string {
    return this.$portalString.terms_and_conditions_dialog_content
  }

  accepted() {
    this.$modals.close({ accepted: true })
  }

  declined() {
    this.$modals.close()
  }

  get showCaptchaMessage() {
    return environment.captcha?.hcaptcha_public != null
  }
}
