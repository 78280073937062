
import { Component, Prop, Vue } from 'vue-property-decorator'
import retry from '@/helpers/retry'

@Component({ components: {} })
export default class MobileFooterPortal extends Vue {
  @Prop({ default: 'has-mobile-footer' })
  className: string

  resizeObserver: ResizeObserver

  mounted() {
    const body = document.body
    body.classList.add(this.className)

    let footerEl: HTMLElement | null = null

    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        if (!footerEl) {
          footerEl = this.getFooterEl()
        }

        this.updateMobileFooterHeightCssProp(footerEl?.offsetHeight)
      })
    }

    const stop = retry(() => {
      const footer = this.getFooterEl()
      if (footer) {
        this.resizeObserver?.observe(footer)
        this.updateMobileFooterHeightCssProp()
        stop()
      }
    })
  }

  getFooterEl(): HTMLElement | null {
    // Do not use `this.$refs` to get the `.mobile-footer` element because
    // it's in a portal. It required double `$nextTick` to work
    // (https://portal-vue.linusb.org/guide/caveats.html#refs)
    return document.querySelector('.mobile-footer')
  }

  /**
   * Update `--mobile-footer-height` CSS var. If height argument is provided
   * then use it, otherwise query the height from `.mobile-footer` element.
   */
  updateMobileFooterHeightCssProp(height?: number) {
    if (!height) {
      // Use `document.querySelector` instead of Vue ref as a ref's not ready
      // when a user added the first item to the cart
      const footer: HTMLElement | null = this.getFooterEl()
      height = footer?.offsetHeight ?? 80
    }

    document.body.style.setProperty('--mobile-footer-height', `${height}px`)
  }

  destroyed() {
    document.body.classList.remove(this.className)
    this.resizeObserver?.disconnect()
  }
}
