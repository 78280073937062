import type { EventDetails } from '@/api/types/processedEntities'
import { environment } from '@/helpers/Environment'
import { isMembershipEvent } from '@/helpers/MiscellaneousHelpers'
import { isTrueish } from '@/helpers/StringHelpers'

export function isAnchor(event: EventTemplate): boolean {
  const config = environment.web.upsells

  if (config?.anchor_events) {
    return config.anchor_events!.includes(event.id)
  } else if (config?.anchor_categories) {
    return config.anchor_categories!.includes(event.category)
  } else {
    return false
  }
}

export function isDependency(template: EventTemplate, tickets: Ticket[]) {
  return isAnchor(template) || isMembershipEvent(template, tickets)
}

export function upsellTicketsMustMatchAnchorTickets(event: EventDetails): boolean {
  const eventConfig = isTrueish(event.meta.tickets_must_match_anchor_tickets)
  const portalConfig = environment.web.upsells?.tickets_must_match_anchor_tickets
  const enabled = eventConfig ?? portalConfig ?? false
  return enabled && anchorsAreConfigured() && isUpsell(event)
}

export function anchorsAreConfigured(): boolean {
  const config = environment.web.upsells
  return Boolean(config?.anchor_categories || config?.anchor_events)
}

export function isUpsell(event: EventTemplate): boolean {
  return environment.web.upsells?.categories?.includes(event.category) || false
}
