
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { TixTab } from '@/types/TixTab'

@Component({ name: 'TixTabs' })
export default class extends Vue {
  @Prop({ required: true })
  tabs: TixTab[]

  @Prop({ default: null })
  value: string | null
}
