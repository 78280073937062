














import { navigateBack } from '@/helpers/route'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'FloatNavigateBack',
})
export default class extends Vue {
  private scrollThresholdCache: number | undefined

  mounted() {
    window.addEventListener('scroll', this.scrollHandler)
  }

  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  private scrollHandler() {
    const threshold = this.scrollThreshold()
    // Opacity will be zero when scroll threshold element hits the top of viewport
    const opacity = 1 - window.scrollY / threshold
    const el: HTMLElement = this.$refs['mobile-button'] as HTMLElement
    if (el) {
      el.style.opacity = String(opacity)
      if (opacity < 0) {
        // So it doesn't receive user events
        el.style.pointerEvents = 'none'
      } else {
        el.style.pointerEvents = 'unset'
      }
    }
  }

  /**
   * Get scroll threshold for when the mobile back button become transparent.
   *
   * The value is determined by X position of
   * `[data-mobile-button-scroll-threshold]` element. If the element is not
   * found, `100` is used as default value.
   */
  private scrollThreshold(): number {
    if (this.scrollThresholdCache === undefined) {
      const el: HTMLElement = document.querySelector('[data-mobile-button-scroll-threshold]') as HTMLElement

      if (el) {
        this.scrollThresholdCache = el.getBoundingClientRect().y + window.scrollY
      } else {
        this.scrollThresholdCache = 100
      }
    }

    return this.scrollThresholdCache
  }

  private navigateBackOne() {
    navigateBack(this.$router)
  }
}
