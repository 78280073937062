// eslint-disable-next-line no-unused-vars
import { languageItem } from '@/language/helpers'
import CodeModal from '@/modals/CodeModal.vue'
import { openModal } from '@/modals/helpers/api'

export type CodeModalCallback = (code: string) => Promise<any>

// This has to be in a separate module to the other open-modal helpers to prevent circular dependencies.
export function openCodeModal(message: string, applyCallback: CodeModalCallback) {
  const t = languageItem('codeModal')
  openModal({
    name: 'code-modal',
    size: 'sm',
    component: CodeModal,
    title: t.title,
    props: {
      message,
      applyCallback,
    },
  })
}
