export function unique<T>(values: T[]): T[] {
  return Array.from(new Set(values))
}

export function difference<T>(a: T[], b: T[], before = false): T[] {
  const base = new Set(b)
  return a.filter((extra) => !base.has(extra))
}

export function appendUniqueItems<T>(base: T[], extras: T[]): T[] {
  return [...base, ...difference(extras, base)]
}

export function prependUniqueItems<T>(base: T[], extras: T[]): T[] {
  return [...difference(extras, base), ...base]
}
