
import LoginForm from '@/components/forms/LoginForm.vue'
import EventBus from '@/helpers/EventBus'
import { openModal } from '@/modals/helpers/api'
import { isLoggedIn } from '@/state/Authentication'
import { Component, Vue } from 'vue-property-decorator'
import { languageItem } from '@/language/helpers'

export function openLoginOrGuestDialog() {
  const t = languageItem('loginOrGuestDialog')
  if (!isLoggedIn()) {
    openModal({
      name: 'login-or-guest-modal',
      title: t.title,
      component: LoginOrGuestDialog,
    })
  }
}

@Component({
  name: 'LoginOrGuestDialog',
  components: { LoginForm },
})
export default class LoginOrGuestDialog extends Vue {
  created() {
    EventBus.$on('tix:login:success', () => {
      Vue.prototype.$modals.close()
    })
  }

  closeDialog() {
    this.$modals.close()
  }

  get guestTitle() {
    return this.$t('homeRoute.guestTitle') as string
  }

  get loginTitle() {
    return this.$t('homeRoute.loginTitle') as string
  }
}
