import TixStripeError from '@/checkout/stripe/TixStripeError'
import ValidationError from '@/errors/ValidationError'
import type { PaymentMethod, Stripe, StripeElements } from '@stripe/stripe-js'

export type StripePaymentMethodName = 'card' | 'afterpay_clearpay' | 'bacs_debit' // PayPal etc
export type WalletPaymentMethodName = 'apple_pay' | 'google_pay'
export type PaymentMethodName = StripePaymentMethodName | WalletPaymentMethodName

/**
 * Creates a payment-method to prepare for payment.
 *
 * @see https://stripe.com/docs/payments/payment-intents/migration-synchronous
 */
export function authorizePayment(
  stripe: Stripe,
  elements: StripeElements,
  identityFields: Dictionary,
  paymentElement: Element,
): Promise<PaymentMethod> {
  return elements
    .submit()
    .then(() => {
      return stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: billingDetails(identityFields),
        },
      })
    })
    .then((result) => {
      if (result.paymentMethod) {
        return result.paymentMethod
      } else if (result.error.type === 'validation_error') {
        paymentElement.scrollIntoView()
        // Stripe shows validation errors in the UI.
        throw new ValidationError()
      } else {
        // Handle other Stripe errors, such as test cards in live mode, and some declined scenarios;
        // Some automated tests use Stripe test cards in production.
        throw new TixStripeError(result.error)
      }
    })
}

function billingDetails(identity: Dictionary) {
  function getValue(key: string): string | null {
    const value = identity[key]
    if (value && value.length > 0) {
      return value
    } else {
      // Set values to `null` instead of `undefined` so that Stripe Payment Element's validation passes.
      return null
    }
  }

  // @see https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details
  const result: Dict<any> = {
    address: {} as Dict<string | undefined>,
  }

  result.name = getValue('name')
  result.email = getValue('email')
  result.phone = getValue('phone')
  result.address.country = getValue('country')
  result.address.postal_code = getValue('zip_code')
  result.address.state = getValue('state')
  result.address.city = getValue('city')
  result.address.line1 = getValue('address')
  result.address.line2 = getValue('address_more')

  return result
}
