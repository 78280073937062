
import { authenticationMethod, environment } from '@/helpers/Environment'
import { isLoggedIn } from '@/state/Authentication'
import { inCartMembership, isCurrentMember } from '@/state/Membership'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import { sellerConfig } from '@/helpers/Config'

@Component({ name: 'MembersBanner' })
export default class extends Vue {
  get enabled(): boolean {
    const onlyHasInCartMembership = inCartMembership() && !isCurrentMember()
    const hasMembership = sellerConfig('membership_event_id')
    return Boolean(hasMembership && authenticationMethod && this.promoMessage.length > 0 && !onlyHasInCartMembership)
  }

  get isLoggedIn() {
    return isLoggedIn()
  }

  get welcomeMessage(): string {
    const params = {
      name: store.getters['Member/user'].first_name,
    }

    return this.$t('membersBanner.welcomeBack', params) as string
  }

  get promoMessage(): string {
    return (
      environment.portalStrings.members_banner ??
      'Are you a member? <login-link>Log in</login-link> Not a member? <membership-link>Sign up now</membership-link>'
    )
  }
}
