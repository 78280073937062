import { DirectiveOptions } from 'vue'

const scrollIntoView: DirectiveOptions = {
  inserted(element, { value }) {
    if (value) {
      // Wait for the element to finish getting its layout.
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 100)
    }
  },
}

export default scrollIntoView
