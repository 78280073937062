import type { DayData } from '@/calendar/types'
import { environment } from '@/helpers/Environment'

export function availabilityThreshold(items: number[], threshold: number | false = false): number | false {
  if (threshold !== false && items.some((available) => available < threshold)) {
    return threshold
  }

  return false
}

export function capacityThreshold(days: DayData[]) {
  const availability = days.filter((day) => day.available !== undefined).map((day) => day.available!)
  return availabilityThreshold(availability, environment.web.show_remaining_session_capacity_threshold)
}

export function showAvailability(capacityThreshold: number | false): boolean {
  return capacityThreshold !== false && capacityThreshold > 0
}
