import { completeSentence, csvToArray } from '@/helpers/StringHelpers'
import { Location, Route } from 'vue-router'

const reserveExpectedErrors = [
  'max_tickets_per_order_exceeded:reserve',
  'min_tickets_per_order_not_met:reserve',
  'rule_blocks_reserve:checkout_rules',
  'event_session_sold_out:reserve',
  'member_limit_exceeded:reserve',
]

export function reserveTicketsExpectedErrors(errors: string[] = []): string[] {
  return reserveExpectedErrors.concat(errors)
}

export function notFoundRoute({ path, query }: Route): Location {
  return {
    name: 'error/404',
    params: {
      0: path.startsWith('/') ? path.substring(1) : path,
    },
    query: query,
  }
}

export type ExpectedErrors = string | string[]

/**
 * Gets a suitable error message to show to the customer, and logs unexpected 400 errors in TrackJS.
 */
export function apiErrorMessage(original: any, expected: ExpectedErrors): string | void {
  // Is this a fetch/axios error?
  // TODO Check `original?.isAxiosError` instead?
  // @see https://trello.com/c/QNGMkVsF
  if (original?.response?.data) {
    const error = getApiErrorEntity(original)
    if (error) {
      logUnexpectedApi400Error(original.response, error, expected)
      return completeSentence(error._description)
    } else {
      // Handles network and API errors other than 4xx errors, such as 5xx and network failures.
      return 'Sorry! Something unexpected happened. Please try again.'
    }
  }
}

export function getApiErrorEntity(error): ApiErrorEntity | void {
  const status = error.response?.status
  if (400 <= status && status < 500) {
    return error.response.data?._data?.[0]
  }
}

export function apiErrorMessageOrRethrow(error: any, expected: ExpectedErrors = ''): string {
  const message = apiErrorMessage(error, expected)
  if (message) {
    return message
  } else {
    // TODO Remove this once we understand what scenarios trigger it.
    // @see https://trello.com/c/QNGMkVsF
    if (error == undefined) {
      logInTrackJS(`Unknown ${error} error`, error)
    } else if (error.name || error.message) {
      logInTrackJS(`${error.name}: ${error.message}`, error)
    } else {
      logInTrackJS(error, error)
    }
    // Rethrow anything that does not appear to be a Tix API error.
    throw error
  }
}

function logUnexpectedApi400Error(response: any, error: ApiErrorEntity, expected: ExpectedErrors) {
  if (isExpectedApiError(error, expected)) {
    // This is an expected error.
  } else {
    logInTrackJS(`Unknown Tix API 4xx error ${error._code}`, response)
  }
}

export function isExpectedApiError(error: ApiErrorEntity, expected: ExpectedErrors) {
  const expectedErrors = Array.isArray(expected) ? expected : csvToArray(expected)
  return expectedErrors.includes(error._code) || expectedErrors.includes(`${error._code}:${error._context}`)
}

export function logInTrackJS(message: string, data: any) {
  try {
    data.config.data = JSON.parse(data.config.data)
    sanitizeApiRequestPayloadForLogging(data.config)
  } catch (e) {
    // data.config.data may be invalid JSON or not a JSON body.
  }
  // console.log() puts the additional error information in the TrackJS Telemetry Timeline.
  /* eslint-disable no-console */
  console.log(data)
  // console.error() makes it clear to developers in local environments that something is wrong.
  // TrackJS logs console.error() too.
  console.error(message)
}

/**
 * TODO Consolidate this function and its counterpart in the CMS repos;
 * and move it to a shared repos so if there are any changes, bug fixes,
 * and unit tests, they should applies to both eCommerce and CMS.
 */
function sanitizeApiRequestPayloadForLogging(config: any) {
  const payload = config.data
  if (config.url?.includes('/modify') && payload.reserve) {
    // Redact ticket admit names.
    payload.reserve.tickets
      .filter((ticket) => ticket.admit_name)
      .forEach((ticket) => {
        ticket.admit_name = 'REDACTED'
      })

    payload.reserve.tickets
      .filter((ticket) => ticket.admit_email)
      .forEach((ticket) => {
        ticket.admit_email = 'REDACTED'
      })

    // Redact answers to ticket survey questions as they may contain personal information.
    const additionalInfo = payload.reserve.additional_info
    if (additionalInfo) {
      for (const info of additionalInfo) {
        for (const key in info.data) {
          info.data[key] = 'REDACTED'
        }
      }
    }
  }

  // Redact personal details provided during checkout.
  const identityFields = ['first_name', 'last_name', 'email', 'password']
  identityFields.forEach((key) => {
    if (payload[key]) {
      payload[key] = 'REDACTED'
    }
  })

  // Redact additional info as it may include personal info depending on the questions on checkout.
  if (payload.additional_info) {
    for (const key in payload.additional_info) {
      payload.additional_info[key] = 'REDACTED'
    }
  }
}

export function cartCodeIsRequired(e: ApiErrorEntity) {
  return (
    e._context === 'checkout_rules' && e._code === 'rule_blocks_reserve' && e._extra?.block_reason === 'code_required'
  )
}
