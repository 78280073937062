import type { WithMeta } from '@/api/types/processedEntities'
import store from '@/store/store'

/**
 * @see https://docs.google.com/spreadsheets/d/1L3OMbW1Tc3CHpx3GgkIXvVRpzXamIEtdtqlUzHV4vYw/edit?usp=sharing
 */
export function cartIsModifiable(): boolean {
  const cart = store.getters['Cart/cart']
  if (cart && cart.state === 'pending') {
    return cart.ownedByPublicIdentity || ownedByAuthenticatedUser()
  } else {
    return false
  }
}

export function ownedByAuthenticatedUser(): boolean {
  const identity = memberIdentity()
  return identity ? identity.id === store.getters['Cart/cart'].identity_id : false
}

export function memberIdentity(): WithMeta<Identity> | void {
  return store.getters['Member/identityWithMeta'][0]
}
