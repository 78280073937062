
import FormInput2 from '@/components/forms/FormInput2.vue'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { config } from '@/helpers/Environment'
import { isTrueish } from '@/helpers/StringHelpers'
import { isCurrentMember } from '@/state/Membership'

@Component({
  name: 'AdmitFields',
  components: {
    FormInput2,
  },
})
export default class extends Vue {
  @Prop({
    default: () => ({
      first: '',
      last: '',
      email: '',
    }),
  })
  value: MembershipAdmitDetails

  @Prop({ required: true })
  emailRequired: MembershipAdmitDetailsConfig

  initialDetails: MembershipAdmitDetails | null = null
  created() {
    this.initialDetails = { ...this.value }
  }

  get input() {
    return { ...this.value }
  }

  @Emit('input')
  emit(): MembershipAdmitDetails {
    return this.input
  }

  get showEmailInput() {
    return this.emailRequired !== 'no'
  }

  get isEmailRequired() {
    return this.emailRequired === 'yes'
  }

  get disableEditing() {
    return isCurrentMember() && isTrueish(config.prevent_membership_updates || 'false')
  }
}
