export class IterableDict<T> {
  constructor(public entries: [string, T][]) {}

  map<U>(callback: (value: T, key: string) => U): IterableDict<U> {
    const entries: [string, U][] = this.entries.map(([key, value]) => {
      const newValue = callback(value, key)
      return [key, newValue]
    })

    return new IterableDict<U>(entries)
  }

  mapKeys(callback: (key: string, value: T) => string): IterableDict<T> {
    const entries: [string, T][] = this.entries.map(([key, value]) => {
      const newKey = callback(key, value)
      return [newKey, value]
    })

    return new IterableDict<T>(entries)
  }

  filter(callback: (value: T, key: string) => boolean): IterableDict<T> {
    const entries = this.entries.filter(([key, value]) => callback(value, key))
    return new IterableDict(entries)
  }

  toObject(): Dict<T> {
    const result = {}
    for (const [key, value] of this.entries) {
      result[key] = value
    }
    return result
  }
}
