
import { Component, Prop, Vue } from 'vue-property-decorator'
import AvailabilityStatus from '@/components/elements/AvailabilityStatus.vue'
import Price from '@/components/elements/Price.vue'
import AnnotationBadges from '@/components/elements/AnnotationBadges.vue'
import type { DayData } from '@/calendar/types'
import TixLegend, { LegendProps } from '@/components/elements/TixLegend.vue'
import { showAvailability } from '@/helpers/AvailabilityHelpers'

@Component({
  name: 'DateSelector',
  components: { TixLegend, AnnotationBadges, Price, AvailabilityStatus },
})
export default class extends Vue {
  @Prop()
  value: DayData | undefined

  @Prop({ required: true })
  days: DayData[]

  @Prop({ default: false })
  displayPrices: boolean

  @Prop()
  capacityThreshold: number | false

  onSelect(item: DayData) {
    this.$emit('input', item)
  }

  onDeselect() {
    this.$emit('input')
  }

  get legendProps(): LegendProps {
    return {
      annotations: this.days.map((day) => day.annotations).flat(),
      arePricesDisplayed: this.displayPrices,
      // There are no selectable-buttons for sold out dates. Only <CalendarPicker> shows sold out dates.
      areAnySoldOut: false,
      hasPriceIncrease: false,
    }
  }

  get showAvailability(): boolean {
    return showAvailability(this.capacityThreshold)
  }
}
