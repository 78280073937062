
import MembersBanner from '@/components/elements/MembersBanner.vue'
import EventListingItem from '@/components/events/EventListingItem.vue'
import CategoryFilters from '@/components/filters/CategoryFilters.vue'
import DateFilters from '@/components/filters/DateFilters.vue'
import TimeOfDayFilters from '@/components/filters/TimeOfDayFilters.vue'
import { isUpsell } from '@/helpers/Anchors'
import { dateQueryParamToTixTime } from '@/helpers/Date'
import { configYml, environment } from '@/helpers/Environment'
import { eventListingTitle } from '@/language/helpers'
import { eventHasMetadataValues, metadataKeys, removeQueryParams } from '@/helpers/QueryStringParameters'
import { isForMembersOnly } from '@/helpers/TicketGroupHelpers'
import { isLoggedIn } from '@/state/Authentication'
import { currentMembership } from '@/state/Membership'
import store from '@/store/store'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { teaserImageSize } from '@/helpers/ImageHelpers'
import type { EventDetailsPredicate, StringInterval, TimeInterval } from '@/helpers/Intervals'
import { predicateForSessionStartsInInterval } from '@/helpers/Intervals'
import { fetchAvailableEvents, FetchAvailableEventsOptions } from '@/api/Events'
import type { EventListItem } from '@/api/types/processedEntities'

function filterByManyPredicates(items: EventListItem[], filters: EventDetailsPredicate[]): EventListItem[] {
  return items.filter((item) => filters.every((filter) => filter(item)))
}

/**
 * Filterable list of events customer can select for their primary tickets.
 *
 * Filter state is the value prop. The parent component is responsible for updating it
 * either with v-model or in response to @input events.
 *
 * TODO Use <router-link> for each event so things like "open in new tab" work.
 * TODO Merge with <EventListingRoute>?
 */
@Component({
  name: 'EventListingWithFilters',
  components: { TimeOfDayFilters, CategoryFilters, EventListingItem, DateFilters, MembersBanner },
})
export default class extends Vue {
  @Prop({ required: true })
  value: Dictionary

  private data: EventListItem[] | null = null
  private areSessionsIncluded: boolean | undefined

  $el: HTMLElement

  loading: boolean = false

  created() {
    this.fetch().then(() => {
      if (this.isUpsellListing && this.templates.length < 1) {
        // Skip upsells if there are none.
        this.$router.replace('/checkout')
      }
    })
  }

  mounted() {
    this.setTeaserImageSize('height')
    this.setTeaserImageSize('width')
  }

  private setTeaserImageSize(dimension: string): void {
    const value = teaserImageSize()[dimension]
    if (value) {
      this.$el.style.setProperty(`--teaser-image-${dimension}`, `${value}px`)
    }
  }

  get showMembersOnlyEvents(): boolean {
    // Show members only events when:
    // - User is logged in, whether they have an active membership or not
    // - There is an effective membership, from the user's logged-in state or the cart
    return isLoggedIn() || currentMembership() != undefined
  }

  get templates() {
    // TODO Disable/hide time filter start on timed entry events.
    if (!this.data) {
      return []
    }

    const cartHasAnchor = store.getters['Cart/anchor'] != null
    const filters: EventDetailsPredicate[] = []

    // Exclude anchored upsells unless the cart is anchored.
    filters.push((template) => cartHasAnchor || !isUpsell(template))

    // Exclude member only events unless member benefits are available.
    filters.push((template) => this.showMembersOnlyEvents || !isForMembersOnly(template))

    // Exclude events that do not have any sessions starting in the session time filter/window.
    if (this.timeWindow && this.areSessionsIncluded) {
      filters.push(predicateForSessionStartsInInterval(this.timeWindow))
    }

    return filterByManyPredicates(this.data, filters)
  }

  get timeWindow(): StringInterval | undefined {
    if (this.value.time || this.visitWindow) {
      return {
        // Use the visit window if no time query parameters are set yet.
        start: this.value['time.start'] || this.visitWindow.start.format('military'),
        end: this.value['time.end'] || this.visitWindow.end.format('military'),
      }
    } else {
      return undefined
    }
  }

  get visitWindow(): TimeInterval {
    // Reserving an anchor event (adding to cart) sets this.
    return store.getters['Cart/visitWindow']
  }

  get showTimeFilters(): boolean {
    // Are time filters enabled in the tenant configuration?
    return (
      this.opt.timeFilters &&
      // Is there a visit window to filter by?
      this.visitWindow &&
      // Time filters are not useful on same_start upsells, because the start times must match exactly.
      environment.web.upsells?.visit_time !== 'same_start'
    )
  }

  get filteredTemplates(): EventListItem[] {
    const filters: EventDetailsPredicate[] = []

    if (this.value.category) {
      filters.push((template) => template.category === this.value.category)
    }

    if (this.filteredByMetadata) {
      // This is used for URLs like /events?meta.foo=bar
      // Probably only OMSI uses this feature.
      filters.push((template) => eventHasMetadataValues(template, this.value))
    }

    return filterByManyPredicates(this.templates, filters)
  }

  get metadataKeys(): string[] {
    return metadataKeys(this.value)
  }

  get filteredByMetadata(): boolean {
    // This is used for URLs like /events?meta.foo=bar
    // Probably only OMSI uses this feature.
    return this.metadataKeys.length > 0
  }

  get metadataFiltersLanguage() {
    return this.metadataKeys.map((key) => this.value[key]).join(', ')
  }

  get title() {
    return eventListingTitle()
  }

  get isUpsellListing() {
    return store.getters['Cart/cartHasItems']
  }

  @Watch('visitWindow')
  @Watch('isUpsellListing')
  @Watch('value.date')
  fetch() {
    this.loading = true

    const options: FetchAvailableEventsOptions = {}

    // Events can be filtered by metadata values specified in query parameters. This filtering is done client-side.
    // If metadata query parameters are specified, get all the metadata entities.
    // Otherwise, just get the profile image.
    if (!this.filteredByMetadata) {
      options.metadataKeys = 'image_profile'
    }

    if (this.isUpsellListing) {
      options.categories = environment.web.upsells?.categories

      if (this.visitWindow) {
        options.includeSessions = true
        options.date = this.visitWindow.start
      }
    } else if (this.value.date) {
      // This is used for URLs like /events?date=tomorrow
      // Probably only OMSI uses this feature.
      // See eventListingWithFilters.dateFilters in config.yml
      options.date = dateQueryParamToTixTime(this.value.date, environment.portal.timezone)
    }

    return fetchAvailableEvents(options)
      .then((events) => {
        this.data = events
        this.areSessionsIncluded = options.includeSessions
      })
      .finally(() => {
        this.loading = false
      })
  }

  get clearMetaQueryParams() {
    return removeQueryParams(this.$route, this.metadataKeys)
  }

  get isUpsell(): boolean {
    return this.$store.getters['Cart/cartHasItems']
  }

  get titleInsideContentColumn(): boolean {
    return configYml.titleInsideContentColumn ?? false
  }
}
