function createEvent(name) {
  let event
  if (typeof Event === 'function') {
    event = new Event(name)
  } else {
    // IE11 Support
    event = document.createEvent('Event')
    event.initEvent(name, true, true)
  }
  return event
}

export function dispatchEvent(type, element) {
  const event = createEvent(type)
  element.dispatchEvent(event)
}
