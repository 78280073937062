import type { PaymentMethodName, WalletPaymentMethodName } from '@/checkout/stripe/helpers'
import type { StripePaymentRequestButtonElementOptions } from '@stripe/stripe-js'

export const walletNames = {
  apple: 'Apple Pay',
  google: 'Google Pay',
}

export type WalletType = 'none' | keyof typeof walletNames

type Style = Required<StripePaymentRequestButtonElementOptions>['style']['paymentRequestButton']
export type ButtonType = Style['type']
export type ButtonTheme = Style['theme']

export function isWalletPayment(identifier: PaymentMethodName): identifier is WalletPaymentMethodName {
  return identifier === 'apple_pay' || identifier === 'google_pay'
}
