import type { LinkedTG } from '@/api/types/processedEntities'
import { i18n } from '@/app/I18n'
import { LanguageStrings } from '@/language/types'
import { TixTimeDuration } from '@/TixTime/Duration'
import { TixTime } from '@/TixTime/TixTime'

export function isForFlexibleTickets(group: TicketGroup): boolean {
  return group.handler === 'passes' || group.handler === 'codes'
}

export function excludeExpiredPasses(group: TicketGroup) {
  if (isForFlexibleTickets(group)) {
    const now = new TixTime()
    if (group.start_policy === 'fixedfrom') {
      // 'fixedfrom' means tickets are available between start and end dates
      const start = new TixTime(group.start_policy_date)
      const duration = new TixTimeDuration(group.duration_new!).duration.asDays()
      const end = start.add(duration, 'days').format()
      return now.isBefore(end)
    } else if (group.start_policy === 'fixedto') {
      // 'fixedto' means tickets are available before a specified date
      const end = new TixTime(group.start_policy_date)
      return now.isBefore(end)
    }
  }
  return true
}

export function isForCodes(group: TicketGroup): boolean {
  return group.handler === 'codes'
}

export function isForMembersOnly(entity: EventTemplate | TicketGroup): boolean {
  return entity.hidden_type === 'public_member_only'
}

export function hasSeated(groups: LinkedTG[]) {
  return groups.some(({ handler }) => handler === 'seated') || false
}

export type TGQueryParam = undefined | string | string[]
type LanguageKey = keyof LanguageStrings['stepperGroupTitles']

function stepperTitle(titleKey: string, values?: Dictionary): string {
  return i18n.t(`stepperGroupTitles.${titleKey}`, values) as string
}

function getStepperTitle(key: LanguageKey, isFlexibleTickets: boolean, isGiftOfMembership: boolean): string {
  if (isGiftOfMembership) {
    return stepperTitle('giftOfMembership')
  }

  const type = isFlexibleTickets ? 'passes' : 'tickets'

  if (isFlexibleTickets) {
    return stepperTitle(key, { type })
  }

  return stepperTitle(key, { type })
}

export interface StepperGroups {
  title: string | null
  className: string
  groups: LinkedTG[]
}

export function getMemberAndGuestStepperGroups(
  groups: LinkedTG[],
  giftOfMembership: boolean,
  limits?: MemberBenefits,
): StepperGroups[] {
  const result: StepperGroups[] = []

  const { memberTGs, guestTGs } = getMemberAndGuestTicketGroups(groups, limits)
  const isFlexibleTickets = groups.every(isForFlexibleTickets)

  if (memberTGs.length > 0) {
    const title = getStepperTitle('members', isFlexibleTickets, giftOfMembership)

    result.push({
      title,
      groups: memberTGs,
      className: 'member-ticket-steppers',
    })
  }

  if (guestTGs.length > 0) {
    const languageKey = memberTGs.length > 0 ? 'guests' : 'default'
    const title = getStepperTitle(languageKey, isFlexibleTickets, giftOfMembership)

    result.push({
      title,
      groups: guestTGs,
      className: 'guest-ticket-steppers',
    })
  }

  return result
}

function getMemberAndGuestTicketGroups(
  groups: LinkedTG[],
  limits?: MemberBenefits,
): {
  memberTGs: LinkedTG[]
  guestTGs: LinkedTG[]
} {
  const memberTGs: LinkedTG[] = []
  const guestTGs: LinkedTG[] = []

  for (const ticketGroup of groups) {
    const isMembersTicketGroup = isForMembersOnly(ticketGroup)
    if (isMembersTicketGroup && limits) {
      // Filter out member-only ticket types with no purchase limits.
      const types = ticketGroup.types.filter((type) => limits[type.name] ?? limits['*'])
      if (types.length > 0) {
        memberTGs.push({ ...ticketGroup, types })
      }
    } else if (!isMembersTicketGroup) {
      guestTGs.push(ticketGroup)
    }
  }

  return { memberTGs, guestTGs }
}
