
import EmailSubmitForm from '@/components/forms/EmailSubmitForm.vue'
import GoHomeOnLogin from '@/plugins/GoHomeOnLogin'
import { changeMemberDetailsInstructions } from '@/language/helpers'
import { Component, Vue } from 'vue-property-decorator'
import { isLoggedIn } from '@/state/Authentication'

@Component({
  name: 'ForgotMembership',
  mixins: [GoHomeOnLogin],
  components: {
    EmailSubmitForm,
  },
})
export default class extends Vue {
  beforeRouteEnter(to, from, next) {
    // Go home if the user is logged in
    next(isLoggedIn() ? '/' : undefined)
  }

  get instructions() {
    return (
      this.$portalString.forgot_membership_instructions ||
      "Enter the email address associated with your membership, and we'll resend your membership number."
    )
  }

  get warningMessage() {
    return (
      this.$portalString.forgot_membership_message ||
      `Check your email for your membership ID. If it doesn't appear within a few minutes, check your spam folder. ${changeMemberDetailsInstructions()}`
    )
  }
}
