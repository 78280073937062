
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ConfirmModal',
})
export default class ConfirmModal extends Vue {
  @Prop()
  title: string

  @Prop()
  message: string

  confirm() {
    this.$modals.close({ confirmed: true })
  }

  cancel() {
    this.$modals.close()
  }
}
