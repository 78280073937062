import { environment } from '@/helpers/Environment'
import currencyFormatter from 'currency-formatter'

/**
 * Format a currency, using the current portal default currency code if
 * none is given.
 **/
export function formatCurrency(amount: number, code: string = environment.portal.default_currency_code): string {
  return currencyFormatter.format(amount, { code })
}

export function currencySymbol(code: string = environment.portal.default_currency_code): string {
  return currencyFormatter.findCurrency(code).symbol
}

export function findCurrency(code: string = environment.portal.default_currency_code): any {
  return currencyFormatter.findCurrency(code)
}

/**
 * TODO Support internationalization.
 *
 * The intention is to split the total to be
 * displayed into four components of: sign, symbol, whole numbers, and
 * decimal, while also being agnostic about how the currency should be
 * formatted. Then the template can use those components in specific
 * markup. At the moment the template always places the symbol on the left.
 *
 * Probably builtin Internationalization API with a polyfill is the best solution;
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat/formatToParts
 * @see https://github.com/tc39/proposal-intl-formatToParts
 * @see https://www.npmjs.com/package/intl
 *
 * @returns {array}
 */

export function formatCurrencyParts(amount: number): string[] {
  const currency = currencyFormatter.findCurrency(environment.portal.default_currency_code)
  const formatted = formatCurrency(amount).replace(new RegExp(`[ \\-${currency.symbol}]`, 'g'), '')
  return [amount < 0 ? '-' : '', currency.symbol, ...formatted.split('.')]
}
