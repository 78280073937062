import { API } from '@/api/API'
import { applyCartCodes } from '@/api/Cart'
import type { ApplyCodePayload } from '@/api/types/payloads'
import store from '@/store/store'

const userErrorCodes: Set<ApiErrorEntity['_code']> = new Set([
  'code_exhausted',
  'code_not_found',
  'additional_info_validation_failed',
  'validation_failed',
  'invalid_citypass',
  'citypass_failure',
  'no_code_benefit',
])

export function userShouldTryAnotherPromoCode(error: ApiErrorEntity) {
  return userErrorCodes.has(error?._code)
}

export function applyPromoCode(code) {
  return applyPromoCodes({
    codes: [code],
    // API will only apply the code if it yields a benefit.
    // TODO Come up with better names for applyPromoCode() and applyOptionalPromoCode().
    require_benefit: true,
  })
}

export function applyOptionalPromoCode(code) {
  return (
    applyPromoCodes({ codes: [code] })
      // Ignore errors.
      .catch(() => null)
  )
}

function applyPromoCodes(payload: ApplyCodePayload): Promise<{
  _code: string
  _context: string
  _description: string
}> {
  return applyCartCodes(store.getters['Cart/cart'].id, payload)
    .then((response) => store.commit('Cart/apiResponse', response))
    .catch((e) => {
      const errors = e.response?.data?._data
      const first = errors?.[0]
      if (first?._code === 'duplicate_code') {
        // Ignore duplicate_code errors.
        if (errors?.[1]) {
          // Return next error.
          throw errors[1]
        } else {
          // Fake success if a promo code can not be applied because it has already been applied,
          // and there are no other errors.
          return first
        }
      } else if (userShouldTryAnotherPromoCode(first)) {
        throw first
      } else {
        throw e
      }
    })
}

export function fetchCartMod(
  code: string,
  includeCodeLookup: boolean = false,
): Promise<ApiResponse<'cartmod' | 'ticket_type' | 'code_lookup'>> {
  const args = includeCodeLookup ? { query: { mode: 'advertise,code' } } : undefined
  return API.getCached<'cartmod' | 'ticket_type' | 'code_lookup'>(`code/${encodeURIComponent(code)}`, args)
}
