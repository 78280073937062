
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Supports arbitrary CMS managed strings as Vue templates.
 *
 * This is useful for language overrides which need to support router links.
 *
 * Only globally-registered custom elements are supported. E.g.
 *
 *   - <login-link>
 *   - <membership-link>
 *   - <router-link>
 *
 * Unlike TenantContent blocks, the template does not need to be wrapped.
 * This component wraps the content in a div. The class=" attribute is
 * inherited and merged, as with any Vue component.
 *
 * @see services/Vue/index.ts
 */
@Component({ name: 'TixTemplate' })
export default class extends Vue {
  @Prop({ required: true })
  template: string

  @Prop({ default: true })
  wrapper: boolean

  render(createElement) {
    const template = this.wrapper ? `<div>${this.template}</div>` : this.template
    return createElement(Vue.component('TixTemplateContent', { template }))
  }
}
