import { AssignedSeat, assignedSeats, availableTGs, calculateSeatAssignments } from '@/seats/assignments'
import type { TGID, TGQuantities } from '@/seats/helpers'
import type Seat from '@/seats/Seat'

export default class SeatSelection {
  available: Set<TGID>
  #value: Dict<Seat> = {}

  constructor(private readonly required: TGQuantities) {
    this.#update()
  }

  /**
   * @deprecated Use Seat.select().
   */
  add(seat: Seat): void | AssignedSeat[] {
    if (seat.state !== 'available') {
      throw new Error(`Only available seats may be selected. Seat ${seat.name} is ${seat.state}`)
    }
    this.#value[seat.name] = seat
    return this.#update()
  }

  /**
   * @deprecated Use Seat.deselect().
   */
  remove(seat: Seat): void | AssignedSeat[] {
    if (seat.state !== 'selected') {
      throw new Error(`Only selected seats may be deselected. Seat ${seat.name} is ${seat.state}`)
    }
    delete this.#value[seat.name]
    return this.#update()
  }

  #update(): void | AssignedSeat[] {
    // Calculate all the different ways selected seats can be assigned.
    const sets = calculateSeatAssignments(this.#value, this.required)

    // TGs that may still have seats assigned to them.
    this.available = availableTGs(sets, this.required)

    // Debug
    // console.log('Selected', Object.keys(this.#value))
    // console.log('Assignments', _serializeSeatAssignments(sets))
    // console.log('Available TGs', this.available)

    if (this.available.size === 0) {
      // Any seat assignment set will do.
      return assignedSeats(sets[0], this.required)
    }
  }
}
