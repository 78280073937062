import type { FormInputField } from '@/components/forms/types'

export function toKebabCase(s: string): string {
  return s.toLowerCase().replace(/ /g, '-').replace(/_/g, '-')
}

export function camelToKebabCase(s: string): string {
  return s.replace(/([A-Z0-9])/g, (w, _, i) => {
    return i === 0 ? w.toLowerCase() : `-${w.toLowerCase()}`
  })
}

export function splitFullName(value: string): [string, string] {
  const names = value.trim().split(' ')
  const last = names.pop()
  const first = names.length ? names.join(' ') : last

  if (first && last) {
    return [first, last]
  } else {
    throw new Error('Input must not be empty.')
  }
}

export function toSentenceCase(s: string): string {
  if (s.length === 0) {
    return s
  }
  return s[0].toUpperCase() + s.slice(1)
}

export function lowerFirst(s: string): string {
  if (s.length === 0) {
    return s
  }
  return s[0].toLowerCase() + s.slice(1)
}

export function formInputPlaceholderLanguage(field: FormInputField) {
  if (field.type === 'select' || field.type === 'options') {
    if (field.showPlaceholderDropdownOption) {
      const name = field.labelHtml ? stripTrustedHTML(field.labelHtml) : field.label
      return `Select a ${name}`
    }
  }
}

export function isTrueish(value: string): boolean | undefined {
  if (value == null) {
    return
  }

  const map = {
    '1': true,
    '0': false,
    on: true,
    off: false,
    enabled: true,
    disabled: false,

    true: true,
    false: false,
    t: true,
    f: false,

    yes: true,
    no: false,
    y: true,
    n: false,
  }

  return map[value.toLowerCase()]
}

export function lowerTrim(s: string): string {
  return s.trim().toLowerCase()
}

export function stripWhitespace(s: string): string {
  // \s for white-Space characters.
  return s.replace(/\s/g, '')
}

/**
 * Returns a large integer as a string.
 *
 * This is intended to be fast. No performance analysis has been done.
 */
export function randomIdentifier(): string {
  return Math.random().toString().replace('0.', '')
}

export function csvToArray(values: string): string[] {
  return parseDelimitedString(',', values)
}

export function extractWords(str: string): string[] {
  return parseDelimitedString(' ', str)
}

function parseDelimitedString(delimiter: string, values: string): string[] {
  return values
    .split(delimiter)
    .map((value) => value.trim())
    .filter((value) => value.length > 0)
}

export function completeSentence(sentence: string) {
  if (sentence.endsWith('.') || sentence.endsWith('!') || sentence.endsWith('?')) {
    return sentence
  } else {
    return `${sentence}.`
  }
}

export function escapeHTML(html: string): string {
  const escape = document.createElement('textarea')
  escape.textContent = html
  return escape.innerHTML
}

/**
 * This must not be used to sanitize untrusted content. Any malicious payloads in the input will be executed.
 */
export function stripTrustedHTML(html: string): string {
  const escape = document.createElement('div')
  escape.innerHTML = html

  // Trello: https://trello.com/c/ZfIJ9yz9
  // `innerHTML` can be `undefined` based on the error on TrackJS
  // https://my.trackjs.com/shared/MzQzYWQ5Y2Y2ZTY1NDE3MmI2MTg3OTFlNzUwOTc1MWQ.
  // Possibly, this only occurs on really old browsers. However, we couldn't
  // replicate this on BrowserStack using the same OS and Browser combinations
  // shown in TrackJS.
  if (escape.innerText == null) {
    /* eslint-disable no-console */
    console.log('stripHTML(): HTMLElement.innerText is not defined')
  }
  return escape.innerText ?? ''
}

export function truncate(text: string, length = 128, omittedChars = '&hellip;') {
  if (text.length <= length) {
    return text
  }

  const truncated = text.substring(0, length)
  return truncated + omittedChars
}

export function getSummary(description?: string, summary?: string): string | null {
  if (summary) {
    return summary
  } else if (description) {
    return truncate(stripTrustedHTML(description))
  }
  return null
}

export function formatTicketCount(count: number) {
  if (count < 100) {
    return String(count)
  } else {
    return '99+'
  }
}

// TODO Unit tests.
export function formatList(items: string[]): string {
  switch (items.length) {
    case 0:
      throw new Error('Cannot format a list of length 0')
    case 1:
      return items[0]
  }

  const last = items.pop()
  // TODO Specify list item separator ("," in english) in i18n, per language?
  const first = items.join(', ')
  // TODO Move to i18n.
  return `${first} and ${last}`
}
