
import EmailSubmitForm from '@/components/forms/EmailSubmitForm.vue'
import { Component, Vue } from 'vue-property-decorator'
import { isLoggedIn, logout } from '@/state/Authentication'

@Component({
  name: 'ResendOrderRoute',
  components: {
    EmailSubmitForm,
  },
})
export default class extends Vue {
  created() {
    if (isLoggedIn()) {
      logout(this.$router)
    }
  }
}
