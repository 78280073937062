
import { config } from '@/helpers/Environment'
import { alterQueryParam, QueryParamFilter } from '@/helpers/QueryStringParameters'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { eventListingCategories } from '@/helpers/Categories'
import type { EventDetails } from '@/api/types/processedEntities'

@Component({
  name: 'CategoryFilters',
})
export default class extends Vue {
  @Prop({ default: null })
  value: string | null

  @Prop({ required: true })
  templates: EventDetails[]

  showButtons = true

  @Watch('links')
  onLinksChanged() {
    if (this.links.length > 1) {
      // $nextTick required to check height after elements render.
      this.$nextTick(() => {
        const filterButtons = Array.from(this.$el.querySelectorAll('a.filter'))
        const yPositions = new Set(filterButtons.map((el) => el.getBoundingClientRect().y))
        // When there are more than 2 rows switch to using a `select`.
        if (yPositions.size > 2) {
          this.showButtons = false
        }
      })
    }
  }

  get links(): QueryParamFilter[] {
    const all = this.filterLocation(null, this.t.all as string)
    const categories = this.categories
      .filter((c) => c.length > 0)
      .map((category) => this.filterLocation(category, category))

    if (categories.length > 1) {
      return [all].concat(categories)
    } else {
      return []
    }
  }

  get categories(): string[] {
    return eventListingCategories(this.templates, config.event_categories)
  }

  filterLocation(value: string | null, label: string) {
    return {
      value,
      label,
      location: alterQueryParam(this.$route, { category: value }),
    }
  }

  handleLinkChange(e) {
    const link = this.links.find((link) => link.value === e.target.value)
    if (link) {
      this.$router.push(link.location)
    } else {
      this.$router.replace({ query: { category: '' } })
    }
  }

  get currentValue() {
    return this.$route.query.category
  }
}
