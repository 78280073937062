import Bus from '../helpers/EventBus'

export default {
  install(Vue) {
    // Global $modals property
    Vue.prototype.$modals = new Vue({
      name: '$modals',

      created() {
        const events = ['new:modal', 'close:modal', 'update:modal']

        events.forEach((event) => {
          this.$on(event, (data) => {
            Bus.$emit(event, data)
          })
        })
      },

      methods: {
        open(options = null) {
          Bus.$emit('new:modal', options)
        },

        close(data = null) {
          Bus.$emit('close:modal', data)
        },
      },
    })
  },
}
