import english from './English'

const language = {
  en: english,
  // TODO Re-enable support for non-English languages.
  // Use English language for any missing Spanish translations.
  // TODO Use i18n's fallback language feature?
  // @see https://kazupon.github.io/vue-i18n/guide/fallback.html#explicit-fallback-with-one-locale
  // es: deepmerge(en, es) as LanguageStrings,
}

export default language
