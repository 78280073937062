
import { getCartPayload, triggerRemoveFromCartEvent } from '@/checkout/helpers/ga4'
import { portal } from '@/helpers/Environment'
import { deleteCart } from '@/state/Cart'
import { CartItem } from '@/store/CartItem'
import store from '@/store/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { removeTickets } from '@/state/Reserve'

@Component({
  name: 'RemoveCartItemOverlay',
  computed: mapGetters({
    cartItems: 'Cart/cartItems',
  }),
})
export default class extends Vue {
  @Prop({ required: true })
  item: CartItem

  processing = false

  cartItems

  confirm() {
    this.processing = true

    const tickets = store.getters['Cart/tickets'] as Ticket[]
    const templates = store.getters['Cart/eventTemplates'] as EventTemplate[]
    const types = store.getters['Cart/ticketTypes'] as TicketType[]
    const groups = store.getters['Cart/ticketGroups'] as TicketGroup[]
    const mods = store.getters['Cart/cartMods'] as CartMod[]

    this.removeItems().then((ticketIDs) => {
      this.processing = false
      this.$emit('close')
      const payload = getCartPayload(portal.default_currency_code, ticketIDs, tickets, templates, types, groups, mods)
      triggerRemoveFromCartEvent(payload)
    })
  }

  cancel() {
    this.$emit('close')
  }

  private removeItems(): Promise<any> {
    if (this.item.isDependency || this.cartItems.length === 1) {
      const result = store.getters['Cart/tickets'].map((item) => item.id)
      const cartId = store.getters['Cart/cartId']
      return deleteCart(cartId).then(() => result)
    } else {
      const result = this.ticketIds
      return removeTickets(this.ticketIds).then(() => result)
    }
  }

  get ticketIds(): string[] {
    return this.item.ticketIds
  }

  get showDependencyWarning() {
    return this.item.isDependency && this.cartItems.length > 1
  }
}
