
import { applyPromoCode } from '@/helpers/PromoCodes'
import { reportFormValidity } from '@/helpers/Validation'
import store from '@/store/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { promoCodeErrorMessage } from '@/helpers/PromoCodesHelper'

@Component({ name: 'ApplyPromoCodes' })
export default class extends Vue {
  @Prop({ default: false })
  submitDisabled: boolean

  rawValue: string = ''
  expanded: boolean = false
  applying: boolean = false
  error: string | null = null

  private readonly minLength = 2

  get totalPromoDiscounts() {
    return store.getters['Cart/totalPromoDiscounts']
  }

  get value() {
    return this.rawValue.trim()
  }

  apply() {
    this.applying = true
    this.error = null
    reportFormValidity(this.$refs.form as HTMLFormElement)
      .then(() => applyPromoCode(this.value))
      .then(() => {
        this.rawValue = ''
        this.expanded = false
      })
      .catch((error) => {
        const input = this.$refs.input as HTMLInputElement
        this.error = promoCodeErrorMessage(this.value, error, input)

        if (this.error === this.$t('applyPromoCodes.unknownErrorMessage')) {
          throw error
        }
      })
      .finally(() => {
        // Reset state.
        this.applying = false
      })
  }

  reset() {
    this.expanded = false
    this.error = null
    this.rawValue = ''
  }
}
