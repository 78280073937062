import type { LinkedTG } from '@/api/types/processedEntities'

export function assignGroupColor(group: LinkedTG, index: number): LinkedTG {
  return {
    ...group,
    color: 1 + index,
  }
}

export function getTicketGroupColor(group: LinkedTG): string {
  return ticketGroupColors[group.color - 1]
}

export const ticketGroupColors = [
  'rgb(230, 25, 75)',
  'rgb(60, 180, 75)',
  'rgb(67, 99, 216)',
  'rgb(245, 130, 49)',
  'rgb(145, 30, 180)',
  'rgb(66, 212, 244)',
  'rgb(240, 50, 230)',
  'rgb(191, 239, 69)',
  'rgb(250, 190, 190)',
  'rgb(70, 153, 144)',
  'rgb(230, 190, 255)',
  'rgb(154, 99, 36)',
  'rgb(255, 250, 200)',
  'rgb(128, 0, 0)',
  'rgb(170, 255, 195)',
  'rgb(128, 128, 0)',
  'rgb(255, 216, 177)',
  'rgb(0, 0, 117)',
  'rgb(169, 169, 169)',
  'rgb(96 125 139)',
]
