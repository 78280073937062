/**
 * The only interfaces a Vue component should use are
 *
 *   1. initializeSeats() and Seat[].
 *   2. Seat.select() returns a list of seat assignments once selection is complete.
 *   3. Seat.deselect()
 */

import type { LinkedTG } from '@/api/types/processedEntities'
import { i18n } from '@/app/I18n'
import { indexBy } from '@/helpers/IndexHelpers'
import { sum } from '@/helpers/MiscellaneousHelpers'
import { formatList } from '@/helpers/StringHelpers'
import { filterQuantitiesByGroups } from '@/helpers/TicketTypeQuantities'
import type { AssignedSeat } from '@/seats/assignments'
import { ttToTGQuantities } from '@/seats/assignments'
import Seat from '@/seats/Seat'
import SeatSelection from '@/seats/SeatSelection'

// Aliases.
export type TGID = string

/**
 * Ticket count per ticket type/group ID.
 */
export type TGQuantities = Dict<number>
export type TTQuantities = Dict<number>

export function initializeSeats(data: SeatEntity[], quantities: TGQuantities): Seat[] {
  // Initialize the SeatSelection and each Seat instance first.
  const selection = new SeatSelection(quantities)
  const result = data.map((item) => new Seat(item, selection))

  // Then iterate over API seat entities one more time to link the prev & next seats.
  // Index the Seat instances for constant-time lookup.
  const index = indexBy('name', result) as Dict<Seat>
  for (const item of data) {
    const seat = index[item.seat]
    seat.prev = item.previous ? index[item.previous] : undefined
    seat.next = item.next ? index[item.next] : undefined
  }

  return result
}

export function seatSelectionIsComplete(selectedQuantities: TGQuantities, selectedSeats: AssignedSeat[]): boolean {
  // Do not mutate the input.
  const quantities = { ...selectedQuantities }

  for (const seat of selectedSeats) {
    quantities[seat.ticketGroupID] -= 1
  }

  return Object.values(quantities).every((count) => count === 0)
}

export function seatSelectionNeeded(
  showSeatSelection: boolean,
  selectedSeats: AssignedSeat[] | null,
  groups: LinkedTG[] | undefined,
  allQuantities: TicketTypeQuantities,
): boolean {
  if (showSeatSelection && groups) {
    const ttQuantities = filterQuantitiesByGroups(allQuantities, groups)
    const tgQuantities = ttToTGQuantities(ttQuantities, groups)
    if (selectedSeats) {
      // Check all required seats have been selected.
      return !seatSelectionIsComplete(tgQuantities, selectedSeats)
    } else {
      return true
    }
  }
  return false
}

export function getSelectedSeatsMessage(groups: LinkedTG[], quantities: TTQuantities): string {
  if (groups.length < 1) {
    throw new Error('Cannot get selected seats message without any groups')
  }
  const key = groups.length == 1 ? 'selectSeats.singleItem' : 'selectSeats.listItem'

  const params = groups.map((group) => {
    const counts = group.types.map((type) => quantities[type.id] ?? 0)
    return {
      count: sum(counts),
      name: group.name,
    }
  })

  const items = params.map(({ count, name }) => i18n.tc(key, count, { name }) as string)
  return i18n.t('selectSeats.message', { list: formatList(items) }) as string
}
