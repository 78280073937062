/**
 * Returns list of categories to show in event listing category picker.
 *
 * @param events Event templates that are actually available in the listing.
 * @param configured   Ordered list of configured event categories.
 */
export function eventListingCategories(events: EventTemplate[], configured: string[]): string[] {
  const result: string[] = []

  // The categories actually used in the available events.
  const inUse = new Set(events.map((event) => event.category))

  for (const category of configured) {
    // Ignore any categories that are configured, but not actually used.
    if (inUse.has(category)) {
      inUse.delete(category)
      result.push(category)
    }
  }

  // Sort any additional categories that are missing from config. (Configured categories are ordered manually.)
  const extras = Array.from(inUse).sort()

  return result.concat(extras)
}
