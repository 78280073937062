var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.fields.length > 0)?_c('div',{staticClass:"admit-details-fields"},[_c('h3',[_vm._v(_vm._s(_vm.t.title))]),_vm._l((_vm.fields),function(ref){
var key = ref.key;
var ticketTypeId = ref.ticketTypeId;
var index = ref.index;
var label = ref.label;
var required = ref.required;
var email = ref.email;
return _c('div',{key:key,staticClass:"admit-details-field-group"},[(email !== 'disabled')?_c('h4',[_vm._v(_vm._s(label))]):_vm._e(),_c('FormInput2',{staticClass:"admit-details-field name",attrs:{"label":_vm.nameLabel(email, label),"required":required,"type":"text"},model:{value:(_vm.internal[ticketTypeId][index].name),callback:function ($$v) {_vm.$set(_vm.internal[ticketTypeId][index], "name", $$v)},expression:"internal[ticketTypeId][index].name"}}),(email !== 'disabled')?_c('FormInput2',{staticClass:"admit-details-field email",attrs:{"label":"Email Address","required":required && email === 'required',"type":"email"},model:{value:(_vm.internal[ticketTypeId][index].email),callback:function ($$v) {_vm.$set(_vm.internal[ticketTypeId][index], "email", $$v)},expression:"internal[ticketTypeId][index].email"}}):_vm._e()],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }