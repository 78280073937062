
import EmailSubmitForm from '@/components/forms/EmailSubmitForm.vue'
import GoHomeOnLogin from '@/plugins/GoHomeOnLogin'
import { Component, Vue } from 'vue-property-decorator'
import { isLoggedIn } from '@/state/Authentication'

@Component({
  name: 'ForgotPasswordRoute',
  mixins: [GoHomeOnLogin],
  components: {
    EmailSubmitForm,
  },
})
export default class extends Vue {
  email: string | null = null

  beforeRouteEnter(to, from, next) {
    // Go home if the user is logged in
    next(isLoggedIn() ? '/' : undefined)
  }

  get contactInstructions() {
    return (
      this.$portalString.forgot_password_contact_details ??
      'If you do not receive a password reset email or if you would like to change details about your membership, please contact us.'
    )
  }

  onSuccess(email) {
    this.email = email
  }
}
