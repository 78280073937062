import type { MetaData } from '@/api/types/processedEntities'
import { categoriesThatMayConflict, environment } from '@/helpers/Environment'
import type { TimeInterval } from '@/helpers/Intervals'
import { CartItem, isTimedItem } from '@/store/CartItem'
import type { Session } from '@/types/Sessions'

/**
 * Returns sessions that conflict with any session for any other event for any tickets already in the cart.
 *
 * TODO Improve unit test coverage.
 * TODO Replace first two params with on EventDetails param?
 */
export function conflictingSessions(
  eventId: string,
  metadata: MetaData,
  sessions: Session[],
  cartItems: CartItem[],
): Session[] {
  const cartSessions = cartItems
    // Cart items for special events like gift cards and memberships are untimed and don't conflict.
    .filter(isTimedItem)
    // All-day-entry items (e.g. general admission) never conflict.
    // TODO Deprecate all-day concept in favor of conflict_avoidance.exclude_categories?
    .filter((item) => !item.allDay)
    // Exclude cart items for events that never conflict.
    .filter((item) => !categoriesThatMayConflict.has(item.event.category))
    // Ignore sessions conflicting with the same event template we're
    // looking at sessions for. This will happen in edit mode.
    .filter((item) => eventId !== item.templateId)

  if (cartSessions.length < 1) {
    // There are no items in the cart that can conflict.
    return []
  } else {
    // Return the sessions that overlap with a cart item session.
    const cartIntervals = cartSessions.map((session) => occupiedInterval(session, session.meta))
    return sessions.filter((session) => {
      const sessionInterval = occupiedInterval(session, metadata)
      return cartIntervals.some((interval) => intervalsOverlap(interval, sessionInterval))
    })
  }
}

function intervalsOverlap(a: TimeInterval, b: TimeInterval): boolean {
  // Partial overlaps:
  //       ========
  //     ----    ----

  // Contained:
  //       ========
  //         ----
  //     ------------

  // Edges touch:
  //       ========
  //       --------
  //       ---  ---
  //       -----------
  //     ----------

  // No overlap:
  //       ========
  //   ----        ----
  // ----            ----

  // Items overlap if both opposite ends of each interval are in order.
  // @see https://stackoverflow.com/questions/13513932/algorithm-to-detect-overlapping-periods
  return a.start.isBefore(b.end) && b.start.isBefore(a.end)
}

function occupiedInterval(item, templateMetaData: MetaData): TimeInterval {
  const { startTime, endTime } = item

  const preStart = paddingConfig('pad_pre_start', templateMetaData)
  const postEnd = paddingConfig('pad_post_end', templateMetaData)

  return {
    start: startTime.subtract(preStart, 'minutes'),
    end: endTime.add(postEnd, 'minutes'),
  }
}

/**
 * Gets a padding variable, taking care to return any 0 values.
 */
function paddingConfig(metadataKey: string, metadata: MetaData): number {
  const configKey = `default_${metadataKey}`
  const config = environment.web.conflict_avoidance

  if (metadata[metadataKey] != null) {
    return Number(metadata[metadataKey])
  } else if (config?.[configKey] != null) {
    return config[configKey]
  } else {
    return 5 // minutes
  }
}

/**
 * Returns sessions that conflict with any session for any other event for any tickets already in the cart.
 *
 * This uses padding before and after the start time. The padding defaults to 10/40 minutes
 * before/after the start time, and can be overridden in the event template meta.
 *
 * The session's end time is ignored.
 *
 * @deprecated Use `conflictingSessions()` instead.
 *
 * This function fails to identify conflicting candidates sessions that start before
 * an in-cart session starts, even if it ends after the in-cart session has started.
 *
 * @param template     Event template desired to add to cart
 * @param sessions     Event sessions for the desired event template
 * @param inCart       Pool of event sessions wanting to reserve. This should at least
 *                     be the sessions already in cart, plus any extra sessions that
 *                     are going to be reserved in the call we are constructing a
 *                     payload for when there are multiple sessions (eg. multiple
 *                     events in intent reserve.)
 * @param metaEntities Metadata for the desired event template AND event templates for
 *                     the pooled sessions (can include other metadata, will be
 *                     filtered)
 * @param admission
 * @returns {*}
 */
export function checkConflict(
  template: EventTemplate,
  sessions: Session[],
  inCart: Session[],
  metaEntities: Dict<MetaData>,
  admission?: EventTemplate,
): Session[] {
  function getPadding(meta: MetaData) {
    return {
      pre: Number(meta.pad_pre_start) || 10,
      post: Number(meta.pad_post_start) || 40,
    }
  }

  const padding = getPadding(metaEntities[template.id])

  // Check each one doesn't conflict with another event already in cart
  return sessions.filter(({ startTime }) =>
    inCart.some((session) => {
      // Do not check against the master event in cart
      // admission may be null if only checking against other cart items (no admission event)
      if (admission && admission.id === session.event_template_id) {
        return false
      }

      // Ignore sessions conflicting with the same event template we're
      // looking at sessions for. This will happen in edit mode.
      if (template.id === session.event_template_id) {
        return false
      }

      const { pre, post } = getPadding(metaEntities[session.event_template_id])

      const start = session.startTime
      const postStart = start.add(post + padding.pre, 'minutes')
      const preEnd = start.subtract(pre + padding.post, 'minutes')

      return startTime.isBetween(preEnd, postStart)
    }),
  )
}
