
import FormInput2 from '@/components/forms/FormInput2.vue'
import { apiErrorMessageOrRethrow } from '@/errors/helpers'
import { authenticationFields } from '@/helpers/Authentication'
import { authenticationMethod, environment } from '@/helpers/Environment'
import EventBus from '@/helpers/EventBus'
import { memberHasNoEmailAddress } from '@/helpers/RequireMemberEmailAddress'
import { reportFormValidity } from '@/helpers/Validation'
import { loginAndTransferCart } from '@/state/Cart'
import { TrackJS } from 'trackjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { LanguageStrings } from '@/language/types'

@Component({
  name: 'LoginForm',
  components: { FormInput2 },
})
export default class extends Vue {
  @Prop()
  hideForgotLink: boolean

  @Prop()
  showBecomeMemberLink: boolean

  initError: string | null = null
  credentials: Dictionary = {}
  loading: boolean = false
  error: string | null = null

  // The 'loginHelpText' language string has no default value, but can be specified in portal strings.
  t: LanguageStrings['loginForm'] & { loginHelpText?: string }

  get fields() {
    return authenticationFields()
  }

  created() {
    if (!authenticationMethod) {
      this.initError = 'Login is disabled'
    } else if (!navigator.cookieEnabled) {
      this.initError = 'Enable cookies and reload to login.'
      TrackJS.track('Cookies are disabled')
    }
  }

  onSubmit() {
    this.error = null
    this.loading = true

    reportFormValidity(this.$el as HTMLFormElement)
      .then(() => loginAndTransferCart(this.credentials))
      .then(() => {
        this.$emit('success')
        // Emit an event on the global event bus too.
        EventBus.$emit('tix:login:success')
      })
      .catch((error) => {
        if (error.validationError) {
          // Ignore validation errors and resolve the promise.
        } else if (401 === error.response?.status) {
          this.error = this.errorMessage(error)
        } else {
          this.error = apiErrorMessageOrRethrow(error)
        }
      })
      .finally(() => {
        this.loading = false
      })
      .then(() => {
        if (memberHasNoEmailAddress()) {
          this.$router.push('/user/set-email-address')
        }
      })
  }

  /**
   * Returns a friendly custom error message for invalid credentials.
   */
  errorMessage(e): string {
    // Fall through to the server response for `password` authentication. The API returns
    // a specific and friendly message, like;
    //   - "Incorrect password for account"
    //   - "Account not found"
    //
    // This is a known account enumeration vulnerability which we have accepted for
    // password authentication.
    // @see https://affinity-it-security.com/what-is-account-enumeration/
    return this.t.invalidCredentials[authenticationMethod as string] || e.response.data.message
  }

  reset() {
    this.credentials = {}
    this.$nextTick(() => {
      this.inputElement().focus()
    })
  }

  dismissModal() {
    EventBus.$emit('tix:modal:dismiss', 'login')
  }

  inputElement() {
    return this.$el.querySelector('input') as HTMLInputElement
  }

  get forgotLink() {
    if (authenticationMethod === 'password') {
      return {
        label: this.t.forgotPassword,
        to: '/user/forgot-password',
      }
    } else {
      return {
        label: this.$portalString.forgot_membership_id_link_text || this.t.forgotMembership,
        to: '/user/forgot-membership',
      }
    }
  }

  get showLinkInsteadOfForm() {
    return authenticationMethod === 'auth0_ecomm' || environment.web.external_urls?.login
  }
}
