import { TrackJS } from 'trackjs'
import { environmentName } from '@/helpers/Environment'

// Ignore all Tix API network errors on the same hostname as the document.
const tixApiPatterns = [
  new RegExp('^402 (Payment Required)?: POST /api/'),
  new RegExp('^404 (Not Found)?: GET /cached_api/'),
  new RegExp('^404 (Not Found)?: GET /api/'),
  new RegExp('^404 (Not Found)?: POST /api/'),
  new RegExp('^409 (Conflict)?: POST /api/'),
  new RegExp('^410 (Gone)?: POST /api/'),
  new RegExp('^422 (Unprocessable Entity)?: POST /api/'),
  // Invalid credentials.
  new RegExp('^401 (Unauthorized)?: POST /api/login'),
  // Incorrect password on checkout.
  new RegExp('^401 (Unauthorized)?: POST /api/user'),
]

export function initializeTrackJS() {
  // Always instantiate Track JS, regardless of the environments.
  TrackJS.install({
    token: 'dd56fb43d4e9473e9e8378c4212e2a37',
    application: `whitelabel-${environmentName}`,
    onError: ({ message }) => !tixApiPatterns.some((pattern) => message.match(pattern)),
  })

  return TrackJS
}
