
import RadioGroup from '@/components/forms/RadioGroup.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'BooleanRadios',
  components: { RadioGroup },
})
export default class extends Vue {
  @Prop()
  label: string

  // Initial value property.
  @Prop({ default: null })
  value: boolean

  @Prop({ default: 'Yes' })
  yesLabel: string

  @Prop({ default: 'No' })
  noLabel: string

  @Prop({ default: null })
  optional: boolean

  options: [RadioGroupOption, RadioGroupOption] | null = null

  private internal: boolean | null = null

  created() {
    this.internal = this.value
    this.options = [
      { label: this.yesLabel, value: true },
      { label: this.noLabel, value: false },
    ]
  }
}
