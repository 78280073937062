import { API } from '@/api/API'
import { cartApiParams, CartApiResponse } from '@/api/Cart'
import type { CitypassCouponsPayload, ReserveTicketPayload } from '@/api/types/payloads'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'
import { getCartPayload, triggerAddToCartEvent } from '@/checkout/helpers/ga4'
import { cartCodeIsRequired, getApiErrorEntity } from '@/errors/helpers'
import { portal } from '@/helpers/Environment'
import { getPreloadedPromoCodes } from '@/helpers/PreloadPromoCodes'
import { CartChanges, cartPayload, reserveTicketsPayload } from '@/helpers/Reserve'
import { surveyPayload } from '@/helpers/SurveyHelpers'
import { openCodeModal } from '@/modals/codeModal'
import store from '@/store/store'
import { TixTime } from '@/TixTime/TixTime'
import type { Session } from '@/types/Sessions'
import { Vue } from 'vue/types/vue'

export interface ReserveSubmitOptions {
  event: EventDetails
  /**
   * @deprecated TODO Decide whether or not to support ReserveDateFirst & CityPASS.
   */
  promoCodesToApplyBeforeReserve?: CitypassCouponsPayload
  codes?: string[]
  ticketsPayload: ReserveTicketPayload[]
  surveyAnswers: Dict<Dict<Primitive>>
  selectedGroups: LinkedTG[]
  emitCallback: Vue['$emit']
}

// TODO Merge/Deduplicate with `submit()`s of <ReserveDateFirst> and <BuyMembership>
export function reserveSubmitHandler(options: ReserveSubmitOptions): Promise<void> {
  function handleCodeRequiredError(message: string, options: ReserveSubmitOptions): Promise<void> {
    return new Promise((resolve) => {
      openCodeModal(message, (code) => {
        // ❗️Recursion!
        return reserveSubmitHandler({ ...options, codes: [code] }).then(resolve)
      })
    })
  }

  const selectedGroupIDs = new Set(options.selectedGroups?.map((g) => g.id))

  const codes = options.promoCodesToApplyBeforeReserve ?? (options.codes ? { codes: options.codes } : undefined)

  const changes: CartChanges = {
    event: options.event,
    preReservePromoCodes: codes,
    add: options.ticketsPayload,
    additionalInfo: surveyPayload(options.surveyAnswers, selectedGroupIDs),
  }

  return reserveTickets(changes)
    .then(() => {
      options.emitCallback('done', options.selectedGroups)
    })
    .catch((error) => {
      const apiError = getApiErrorEntity(error)
      if (apiError && cartCodeIsRequired(apiError)) {
        return handleCodeRequiredError(apiError._description, { ...options })
      } else {
        throw error
      }
    })
}

export function reserveSession(
  selectedTicketTypes: TicketTypeQuantities,
  selectedSession: Session | null,
  details: Dict<EventAdmitDetails[]>,
  data?: Dict<ReserveTicketPayload['handler_data'][]>,
  changes?: CartChanges,
): Promise<void> {
  const result = { ...changes }
  result.add = reserveTicketsPayload(selectedTicketTypes, selectedSession, details, data)
  return reserveTickets(result)
}

export function reserveTickets(changes: CartChanges): Promise<void> {
  const previousTicketIDs = store.getters['Cart/tickets'].map((ticket) => ticket.id)
  const cartExists = store.getters['Cart/currentCartExists']
  const result = cartExists ? modifyExistingCart(changes) : createAndModifyCart(changes)
  return result.then(() => pushAddToCartEvent(previousTicketIDs))
}

function pushAddToCartEvent(prevTicketIDs: string[]) {
  const previous = new Set(prevTicketIDs)
  const tickets = store.getters['Cart/tickets']
  const newTicketIDs = tickets.map((ticket) => ticket.id).filter((id) => !previous.has(id))

  const payload = getCartPayload(
    portal.default_currency_code,
    newTicketIDs,
    tickets as Ticket[],
    store.getters['Cart/eventTemplates'] as EventTemplate[],
    store.getters['Cart/ticketTypes'] as TicketType[],
    store.getters['Cart/ticketGroups'] as TicketGroup[],
    store.getters['Cart/cartMods'] as CartMod[],
  )

  triggerAddToCartEvent(payload)
}

export function removeTickets(ids: string[]) {
  return modifyExistingCart({ remove: ids })
}

export function replaceTickets(changes: CartChanges): Promise<void> {
  return modifyExistingCart(changes)
}

function createAndModifyCart(changes: CartChanges): Promise<void> {
  const withCampaign: CartChanges = {
    campaign: window.tixAppState.queryParams ?? {},
    ...changes,
  }
  return modify(`my/cart/modify`, withCampaign).then(updateCartStore)
}

function modifyExistingCart(payload: CartChanges): Promise<void> {
  const cart = store.getters['Cart/cart']
  return modify(`my/cart/${cart.id}/modify`, payload).then(updateCartStore)
}

export function modify(path: string, changes: CartChanges) {
  const body = cartPayload(
    changes,
    store.getters['Cart/tickets'],
    store.getters['Cart/linkedTicketGroups'],
    store.getters['Cart/eventsWithConfig'],
    getPreloadedPromoCodes(),
  )

  return API.post(path, { body, ...cartApiParams() })
}

function updateCartStore(response: CartApiResponse) {
  // Inject a client-side safe copy of expires_at based on local-clock's now and the expires_in
  // value. This ensures the cart is not mistakenly expired if the user's system clock is ahead.
  // Expire the cart 5 seconds earlier to allow for any delay caused by the network.
  const cart = response.cart._data[0]
  const localExpiry = new TixTime().add(cart.expires_in - 5, 'seconds').asTimeValue()

  store.commit('Cart/localExpiry', localExpiry)
  store.commit('Cart/apiResponse', response)
}
