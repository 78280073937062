import { API } from '@/api/API'
import { quantitiesPayload } from '@/helpers/TicketTypeQuantities'

export type CalendarApiResponse = EventBasicDateData[] | EventDateData[]

export async function fetchCalendarForTicketGroupIDs(
  eventId: string,
  ticketGroupIds: string[],
): Promise<EventBasicDateData[]> {
  const query = {
    _format: 'extended',
    'ticket_group_id._in': ticketGroupIds.join(','),
  }
  const response = await API.getCached<'calendar'>(`events/${eventId}/calendar`, { query })
  return response.calendar._data
}

export async function fetchCalendarForTicketTypes(
  eventId: string,
  quantities: TicketTypeQuantities,
  includePricing: boolean,
): Promise<CalendarApiResponse> {
  const query = { _format: includePricing ? 'price' : 'extended' }
  const body = quantitiesPayload(quantities)
  const response = await API.post<'calendar'>(`events/${eventId}/calendar`, { query, body })
  return response.calendar._data
}
