import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Cart from './Cart'
import Member from './Member'

Vue.use(Vuex)

const plugins = []

// localStorage is not available in some scenarios. Do not attempt to persist state.
try {
  plugins.push(
    createPersistedState({
      storage: window.localStorage,
    }),
  )
} catch (e) {
  // Gracefully skip persistence if any of an expected error.
  const expected = new Set([
    // Cookies disabled in Chrome.
    "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",

    // Private browsing mode in some older browsers
    'Invalid storage instance given',

    // Safari with all cookies blocked
    'The operation is insecure.',
  ])
  if (!expected.has(e.message)) {
    throw e
  }
}

// Reload state from disk when it changes in any other tab.
// @see https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
// @see https://github.com/robinvdvleuten/vuex-persistedstate/issues/234
window.addEventListener('storage', (event) => {
  if (event.key === 'vuex' && localStorage.vuex) {
    store.replaceState(JSON.parse(localStorage.vuex))
  }
})

/**
 * TODO Upgrade to Pinia.
 * @see https://pinia.vuejs.org/introduction.html
 * @see https://github.com/vuejs/pinia/issues/309
 *
 * TODO Or, maybe, use https://github.com/ktsn/vuex-class
 *
 * TODO Separate the bare minimum mutable state from data that can be derived and/or cached from that mutable state.
 *
 *  - Mutable state should exist across all tabs/windows of the current browser (cookies or localStorage).
 *    - Caches should be more volatile, e.g. memory or sessionState
 *
 * The bare minimum mutable state probably boils down to;
 *
 * 1. Cart ID
 *    - All other cart content/data can be derived from this via API queries.
 *    - Cart content must be observable for the cart widget (and other things that observe the cart) to work well.
 *    - It should also be cached fairly aggressively.
 *    - No value if nothing is reserved.
 *    - Deleted when carts are emptied, abandoned or expire.
 *
 * 2. Authentication session
 *    - This is stored in the session cookie.
 *    - Current user and memberships should be derived and cached from the session via API queries.
 *
 * 3. Giftee (gift recipient) data
 *    - For membership gift carts
 *
 * 4. Stored discount codes from URL query string parameter.
 *    - @see Router.ts
 *
 * 5. Express-checkout (skip upsells) mode from URL query string parameter.
 *    - @see Router.ts
 *
 * 6. "All day" events?
 *    - This is a hack to workaround the lack of concept for "all day event" in the data model.
 *    - @see addAllDaySession()
 *
 * TODO For state to be manageable, it must be bare minimum. Nevertheless, this list probably is not complete.
 *
 * A good architecture to cache API responses is necessary to avoid unnecessary API requests. It should;
 *
 *  - Sort query string parameters and embeds
 *    - This allows cloudfront API response caches to hit more frequently
 *    - API.ts does this. APIService does not.
 *  - Cache responses by entity
 *    - It should;
 *      - have one bucket per entity type; event_template, session, identity, ticket, order, etc
 *      - be keyed by entity id
 *    - Caching responses by query;
 *      - is very similar to the cloudfront cache, so may be less likely to be as effective as an entity cache.
 *      - can be added later as another layer of cache
 *      - will result in misses for e.g. event template on the event detail page after visiting the event list page.
 *  - Fetch (and cache?) Ticket Groups and Ticket Types together.
 *    - They are closely related and one is usually not useful without the other.
 *  - Be purge-able when mutable state changes are observed
 *    - The mutation may have occurred in the same tab/window, or a different one.
 *
 * The API cache should allow for advanced optimisations like;
 *
 *  - Fetching event template, ticket groups and ticket types in a single query when the cache is empty.
 *  - Only fetching ticket groups and types when the event template is already loaded.
 *  - Priming on app instantiation via data in the html document (similar to window.tix/environment.ts).
 *    - This is a good way to load venues, which are few and very static.
 *    - This may need to be configurable per tenant so that, e.g.
 *      - some tenants with many venues omit some or all of them
 *      - tenants with a single/primary event that is very static include the event_template, ticket groups and types.
 *
 * GraphQL maybe a good query language between the API cache and the front end.
 *
 * A service worker may be a good layer to implement the API cache.
 */
function defaultState() {
  return {
    membershipRules: [],
    cancelledTicketIds: [],
    upsellsChain: [],
  }
}

const store = new Vuex.Store({
  plugins,

  modules: { Cart, Member },

  state: defaultState(),

  mutations: {
    membershipRules(state, membershipRules) {
      state.membershipRules = membershipRules
    },

    cancelledTicketIds(state, value) {
      state.cancelledTicketIds = value
    },

    addCancelledTicketIds(state, value) {
      state.cancelledTicketIds.push(...value)
    },

    removeCancelledTicketIds(state, value) {
      state.cancelledTicketIds = state.cancelledTicketIds.filter((id) => !value.includes(id))
    },

    appendToUpsellsChain(state, value) {
      state.upsellsChain.push(value)
    },

    upsellsChain(state, value) {
      state.upsellsChain = value
    },

    clearUpsellsChain(state) {
      state.upsellsChain = []
    },
  },

  actions: {
    clearAll(context, exception) {
      // Clear each item of this module's state.
      // Do not clear legacy properties that are no longer supported.
      const state = defaultState()
      for (const key in state) {
        context.commit(key, state[key])
      }

      // Clear each sub-module's state.
      Object.keys(this._modules.root._children).forEach((subModuleName) => {
        // TODO Always exclude the 'Member' and require this is cleared via logout?
        if (subModuleName !== exception) {
          this.dispatch(`${subModuleName}/clearAll`)
        }
      })
    },
  },
})

export default store
