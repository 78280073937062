
import CartWidget from '@/components/cart/CartWidget.vue'
import EventListingWithFilters from '@/components/events/EventListingWithFilters.vue'
import { eventListingTitle } from '@/language/helpers'
import { metadataKeys } from '@/helpers/QueryStringParameters'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import CheckoutMobileFooter from '../components/cart/CheckoutMobileFooter.vue'
import MobileCartWidget from '../components/cart/MobileCartWidget.vue'
import MobileFooterPortal from '../components/cart/MobileFooterPortal.vue'
import { configYml } from '@/helpers/Environment'

/**
 * Implements EventListingWithFilters as a route with a title and optional sidebar.
 *
 * Filter state is stored in query string parameters, exclusively.
 *
 * TODO Merge with <EventListingWithFilters>?
 */
@Component({
  name: 'EventListingRoute',
  components: {
    CartWidget,
    EventListingWithFilters,
    MobileFooterPortal,
    CheckoutMobileFooter,
    MobileCartWidget,
  },
})
export default class extends Vue {
  beforeRouteEnter(to, from, next) {
    if (window.tixAppState.skipUpsells && store.getters['Cart/cartHasItems']) {
      next('/checkout')
    } else {
      next()
    }
  }

  get filters(): Dictionary {
    const query = this.$route.query
    const params = metadataKeys(query).map((key) => [key, query[key]])
    return {
      ...Object.fromEntries(params),
      'time.start': query['time.start'],
      'time.end': query['time.end'],
      date: query.date,
      category: query.category,
    }
  }

  get title() {
    return eventListingTitle()
  }

  get ticketCount(): number {
    return store.getters['Cart/ticketCount']
  }

  get isUpsell(): boolean {
    return this.$store.getters['Cart/cartHasItems']
  }

  get titleInsideContentColumn(): boolean {
    return configYml.titleInsideContentColumn ?? false
  }
}
