import type { EventDetails } from '@/api/types/processedEntities'
import { environment } from '@/helpers/Environment'
import { isLoggedIn } from '@/state/Authentication'
import type { TimedItem } from '@/store/CartItem'
import store from '@/store/store'
import { TixTime } from '@/TixTime/TixTime'
import type { Session } from '@/types/Sessions'

export function citypassIsEnabled(event: EventDetails): boolean {
  return Boolean(
    // Disable CityPASS if logged in.
    !isLoggedIn() && environment.config.citypass_code_groups && event.config.web?.ecommerce?.citypass,
  )
}

export function setVisitWindow(sessions: Session[]) {
  const config = environment.web.upsells?.visit_time
  const anchor = store.getters['Cart/anchor']
  if (config && anchor) {
    const [start, end] = calculateVisitWindow(config, anchor, sessions)
    store.commit('Cart/visitWindow', { start, end })
  }
}

function calculateVisitWindow(config: string, anchor: TimedItem, sessions: Session[]): [TixTime, TixTime] {
  const anchorStart = anchor.startTime
  const anchorEnd = anchor.endTime
  const dayStart = anchorStart.startOfDay()
  const dayEnd = anchorStart.endOfDay()
  const lastSession = sessions[sessions.length - 1]
  const anchorClose = lastSession.endTime

  if (config === 'timeslot') {
    return [anchorStart, anchorEnd]
  } else if (config === 'same_start') {
    // Create a window of one minute. This is much simpler than modifying multiple API query builders to dynamically
    // support multiple query parameter modifiers;
    //   1. Less than, < ._lt
    //   2. Less than or equal to, <= ._lte
    // And it is good enough for an unused feature. As of November 2022 no tenants use the same_start option. That might
    // be because it did not work as documented.
    return [anchorStart, anchorStart.add(1, 'minute')]
  } else if (config === 'same_date') {
    return [dayStart, dayEnd]
  } else if (config === 'until_close') {
    return [anchorStart, anchorClose]
  } else {
    throw new Error('Unsupported value for `upsells.visit_time` config: ' + config)
  }
}
