
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { CalendarMonthData } from './types'
import { TixDate } from '@/TixTime/TixDate'
import CalendarDay from './CalendarDay.vue'

@Component({
  name: 'CalendarMonth',
  components: { CalendarDay },
})
export default class extends Vue {
  @Prop({ required: true })
  month: CalendarMonthData

  @Prop({ required: true })
  focusedDate: TixDate

  @Prop({ required: true })
  displayPrices: boolean

  @Prop()
  capacityThreshold: number | false

  get classes() {
    return {
      'show-prices': this.displayPrices,
      'show-availability': this.capacityThreshold,
    }
  }
}
