import { DirectiveOptions } from 'vue'

const registry = new Map<HTMLElement, Function>()

document.addEventListener('click', (e) => {
  registry.forEach((callback, el) => {
    if (!el.contains(e.target as Node)) {
      callback()
    }
  })
})

const clickOutside: DirectiveOptions = {
  bind(el, binding) {
    if (typeof binding.value === 'function') {
      registry.set(el, binding.value)
    }
  },

  unbind(el, binding) {
    registry.delete(el)
  },
}

export default clickOutside
