<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 14 15">
    <path
      d="M7.04546 2.04541L8.74496 5.66581L12.5455 6.24993L9.79546 9.06644L10.4445 13.0454L7.04546 11.1658L3.64646 13.0454L4.29546 9.06644L1.54546 6.24993L5.34596 5.66581L7.04546 2.04541Z"
      stroke="#F0EBF3"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
  import { Component } from 'vue-property-decorator'
  import BaseIcon from './BaseIcon.vue'

  @Component({})
  export default class StarIcon extends BaseIcon {}
</script>
