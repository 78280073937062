
import FormInput2 from '@/components/forms/FormInput2.vue'
import { applyPromoCode, userShouldTryAnotherPromoCode } from '@/helpers/PromoCodes'
import { reportFormValidity } from '@/helpers/Validation'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'BlockedCartForm',
  components: {
    FormInput2,
  },
})
export default class extends Vue {
  warning = 'A code is required to continue with your order.'
  value = ''
  description: string
  error: string | null = null
  loading = false

  created() {
    // Handle one checkout-blocking rule at a time.
    const rule = store.getters['Cart/blockingRules'][0] as CartMod
    this.description = rule.description
  }

  onSubmit() {
    this.error = ''
    this.loading = true

    reportFormValidity(this.$refs.form as HTMLFormElement)
      .then(() => applyPromoCode(this.value))
      .catch((error) => {
        if (error.validationError) {
          // Resolve the promise chain.
        } else if (error._code && error._description) {
          this.error = error._description
          if (userShouldTryAnotherPromoCode(error)) {
            this.error += '. Please try another one.'
          }
        } else {
          throw error
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
