
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { MessageType } from '@/helpers/DynamicMessages'
import { getMessages } from '@/helpers/DynamicMessages'
import { TixTime } from '@/TixTime/TixTime'
import type { EventDetails } from '@/api/types/processedEntities'

@Component({
  name: 'DynamicMessageGroup',
})
export default class extends Vue {
  @Prop()
  start: TixTime | undefined | null

  @Prop({ required: true })
  event: EventDetails

  @Prop({ required: true })
  type: MessageType

  @Prop()
  quantities: TicketTypeQuantities | undefined

  get messages(): MessageAction[] {
    return getMessages(this.event, this.type, this.start, this.quantities)
  }
}
