import type { EventWithConfig } from '@/api/types/processedEntities'
import { environment, theme } from '@/helpers/Environment'
import type { NormalizedThemeOptions } from '@/themeConfig/types'

export interface TixImage {
  url: string | void
  alt: string
  noCropping: boolean
}

export function imageData(event: EventWithConfig, urlHandler: (event: EventWithConfig) => string | void): TixImage {
  const config = event.config.config?.image
  return {
    url: urlHandler(event),
    alt: config?.alt_text ?? `Image for ${event.name}`,
    noCropping: config?.no_cropping ?? false,
  }
}

/**
 * Use the same sizes for each type of image to reduce data use and increase cache hits.
 */
export function teaserImageUrl(event: EventWithConfig): string | void {
  const { width, height } = teaserImageSize()
  return event.config.config?.image?.no_cropping
    ? imageUrl(event.meta.image_profile, width * 2)
    : imageUrl(event.meta.image_profile, width * 2, height * 2)
}

export function tenantTeaserImageSize(size?: NormalizedThemeOptions['teaserImageSize']): {
  width: number
  height: number
} {
  // Most tenants have teasers that are 272x216px, an aspect ratio of 4:5.
  const width = size?.width ?? 272
  const height = size?.height ?? 216
  return { width, height }
}

export function teaserImageSize() {
  return tenantTeaserImageSize(theme?.teaserImageSize)
}

export function coverImageUrl(event: EventWithConfig): string | void {
  return imageUrl(event.meta.image_cover, 1500)
}

export function imageUrlMaxWidth(url: string): string | void {
  return imageUrl(url, 1500)
}

export function cartItemImageUrl(event: EventWithConfig): string | undefined {
  const width = 64
  const height = 51
  if (!event.config.config?.image?.no_cropping) {
    return imageUrl(event.meta.image_profile, width * 2, height * 2)
  }
}

function imageUrl(apiUrl: string, width: number, height?: number) {
  if (apiUrl) {
    // Use a relative URL to load the image from the CDN instead of the API.
    // Also replace the path prefix from /v1/ to /api/ to request via the tix-web API proxy.
    const path = apiUrl.replace(/^https?:\/\/.*?\/v1\//, '/api/')

    const parameters = {
      sizex: width,
      sizey: height,
    }

    const queryString = Object.entries(parameters)
      .filter(([, value]) => Boolean(value))
      .map(([name, value]) => `${name}=${value}`)
      .join('&')

    return path + '?shrinkonly&' + queryString
  }
}

export interface LogoURLs {
  large: string
  small?: string
}

export function getLogoUrls(slug: string): LogoURLs {
  function format(logo?: string) {
    if (logo) {
      return `assets/${logo}`
    }
  }

  const logo = environment.manifest[`${slug}/logo`]
  const logoSmall = environment.manifest[`${slug}/logoSmall`]
  const sandboxLogo = environment.manifest['sandbox/logo']
  return {
    large: format(logo ?? sandboxLogo)!,
    small: format(logoSmall),
  }
}
