import type { TimedItem } from '@/store/CartItem'
import type { TTStringFormat } from '@/TixTime/helpers'
import { TixTime } from '@/TixTime/TixTime'
import type { ComponentOptions } from '@/types/ComponentOptions'

// This is used for URLs like /events?date=tomorrow
// Probably only OMSI uses this feature.
// See eventListingWithFilters.dateFilters in config.yml
export function dateQueryParamToTixTime(value: string, timezone: string): TixTime {
  const today = new TixTime(null, timezone)
  const handlers = {
    today: today,
    tomorrow: today.add(1, 'day'),
  }

  if (handlers[value]) {
    return handlers[value]
  } else {
    // TODO Support concrete date values. E.g. 2020-06-06
    throw new Error('Unsupported date query string parameter: ' + value)
  }
}

export function formatCartItemDateTime(item: TimedItem, options?: ComponentOptions['cartWidget']): string | null {
  const dateTimeFormat: TTStringFormat = (options?.dateTimeFormat as TTStringFormat) ?? 'LONG_DATE_TIME'
  const dateOnlyFormat: TTStringFormat = (options?.dateOnlyFormat as TTStringFormat) ?? 'LONG_DATE'
  const timeOnlyFormat: TTStringFormat = (options?.timeOnlyFormat as TTStringFormat) ?? 'LT'

  const dateTime = (date: TixTime) => date.format(dateTimeFormat)
  const date = (date: TixTime) => date.format(dateOnlyFormat)
  const time = (date: TixTime) => date.format(timeOnlyFormat)
  const mode = item.event.meta.ticket_date_time
  const { startTime: start, endTime: end } = item

  if (item.allDay || mode === 'date-only') {
    return date(start)
  } else if (mode === 'hidden') {
    return null
  } else if (mode === 'show-end') {
    if (start.isSame(end, 'day')) {
      return `${date(start)} at ${time(start)} to ${time(end)}`
    } else {
      return `${dateTime(start)} to ${dateTime(end)}`
    }
  } else if (mode === 'dates-only-show-end') {
    if (start.isSame(end, 'day')) {
      return date(start)
    } else {
      return `${date(start)} to ${date(end)}`
    }
  } else if (mode == null || mode === '') {
    return dateTime(start)
  }

  return mode
}
