
import { apiErrorMessageOrRethrow } from '@/errors/helpers'
import { auth0Client } from '@/helpers/Auth0Client'
import { loginAndTransferCart } from '@/state/Cart'
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'Auth0LoginRoute' })
export default class extends Vue {
  error: string | null = null

  async created() {
    const client = await auth0Client
    if (!navigator.cookieEnabled) {
      this.error = 'Please enable cookies and try again.'
    } else if (!client) {
      this.error = 'Auth0 could not be initialized. Please try again.'
    } else {
      // @see https://auth0.com/docs/quickstart/spa/vuejs/01-login#create-an-authentication-wrapper
      try {
        const { appState } = await client.handleRedirectCallback()
        const claims = await client.getIdTokenClaims()
        await loginAndTransferCart({ jwt: claims.__raw })

        // Navigate to the destination using window.location instead of Vue Router so that the router re-initializes
        // using the seller prefix.
        window.location = appState.fullPath
      } catch (error: any) {
        if (error.message === 'Invalid state') {
          // User probably navigated here using browser forward/back button.
          window.history.forward()

          // Log the error if the browser is still on this page after 5 seconds.
          // That probably means there is no location in the history API to navigate forward to.
          setTimeout(() => {
            throw error
          }, 5_000)
        } else {
          this.error = apiErrorMessageOrRethrow(error)
        }
      }
    }
  }
}
