import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'
import QueryParameterError from '@/errors/QueryParameterError'
import { annotateSession } from '@/helpers/DynamicMessages'
import { getQuantityParam, getQueryParam, NormalizedReserveParamValue } from '@/helpers/QueryStringParameters'
import { isTrueish } from '@/helpers/StringHelpers'
import { TixTime } from '@/TixTime/TixTime'
import type { Session } from '@/types/Sessions'
import { Vue } from 'vue-property-decorator'

export default abstract class ReserveBase extends Vue {
  abstract event: EventDetails

  abstract availableDates: string[]
  abstract selectedDate: TixTime | null
  abstract sessions: Session[] | null
  abstract selectedSession: Session | null

  abstract selectedQuantities: TicketTypeQuantities

  abstract availableTGs: LinkedTG[]

  initializationWarning: string | null = null
  initializationError: string | null = null

  get availableTicketTypes(): TicketType[] {
    return this.availableTGs.reduce((tickeTypes: TicketType[], group) => {
      return [...tickeTypes, ...group.types]
    }, [])
  }

  selectDate(date: TixTime): SuccessOrFailureReason {
    // Check if the date of the session is still available before selecting it
    if (this.availableDates.includes(date.format('DATE'))) {
      this.selectedDate = date
      return ''
    }

    return this.$t('reserve.unavailableOnDate') as string
  }

  selectDateBySession(session: EventSession, venue: Venue): SuccessOrFailureReason {
    const date = new TixTime(session.start_datetime, venue.timezone)
    return this.selectDate(date)
  }

  selectSessionByID(sessionID: string): SuccessOrFailureReason {
    const session = this.sessions?.find((s) => s.id === sessionID)
    if (session) {
      this.selectedSession = annotateSession(session, this.event)
      return ''
    }
    return this.$t('reserve.sessionUnavailable') as string
  }

  selectQuantitiesByQueryParams(): SuccessOrFailureReason {
    const quantityParamValue = getQuantityParam()?.value
    if (!quantityParamValue) {
      return ''
    }

    const tempQuantities: TicketTypeQuantities = {}
    for (const [ticketType, num] of Object.entries(quantityParamValue)) {
      const types = this.availableTicketTypes.filter((t) => {
        // Ticket type can be id or name
        return t.id === ticketType || t.name === ticketType
      })

      for (const type of types) {
        tempQuantities[type.id] = { quantity: num }
      }
    }

    if (Object.keys(tempQuantities).length === 0) {
      return this.$tc('reserve.ticketTypesNotFound', Object.keys(quantityParamValue).length) as string
    }

    this.selectedQuantities = { ...this.selectedQuantities, ...tempQuantities }
    return ''
  }

  get normalizedReserveParam(): NormalizedReserveParamValue {
    const reserveParam = getQueryParam('reserve')?.value ?? ''
    let reserve: NormalizedReserveParamValue = 'false'

    if (['warn', 'strict'].includes(reserveParam)) {
      reserve = reserveParam as NormalizedReserveParamValue
    } else if (isTrueish(reserveParam)) {
      reserve = 'true'
    }

    return reserve
  }

  handleQueryParamError(error: string): void {
    if (error) {
      throw new QueryParameterError(error)
    }
  }

  isQueryParamError(e: any): e is QueryParameterError {
    return e.isQueryParameterError
  }

  handleLoadParamError(e: Error | QueryParameterError, reserve: NormalizedReserveParamValue) {
    if (!this.isQueryParamError(e)) {
      throw e
    } else if (reserve === 'warn') {
      this.initializationWarning = e.message
    } else if (reserve === 'strict') {
      this.initializationError = this.$t('reserve.unavailable') as string
    }
  }
}
