import type { Vue } from 'vue-property-decorator'

export function watchOnce(vueInstance: Vue, propToWatch: string) {
  return new Promise((resolve) => {
    const unwatch = vueInstance.$watch(propToWatch, (value: unknown) => {
      unwatch()
      resolve(value)
    })
  })
}
