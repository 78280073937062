import { API } from '@/api/API'
import { linkToMetadata } from '@/api/Helpers'
import { formatCountry } from '@/helpers/CountriesAndRegions'
import { mapEntries } from '@/helpers/DictHelpers'
import { timezone } from '@/helpers/Environment'
import { groupBy } from '@/helpers/IndexHelpers'
import { TixTime } from '@/TixTime/TixTime'

const emptyApiResource = {
  _data: [],
}

function defaultState() {
  return {
    identity: {
      identity: emptyApiResource,
      meta: emptyApiResource,
      user: emptyApiResource,
    },
    previousMembership: {
      membership: emptyApiResource,
      ticket_order: emptyApiResource,
      ticket: emptyApiResource,
    },
  }
}

/**
 * `previousMembership` is the whole API response. It includes all current and future memberships.
 *
 * If there is no current membership it includes the most recent expired membership.
 *
 * Memberships are in valid_to descending order;
 *
 *   - The first one is the furthest in the future.
 *   - The last one is the current (or most recently expired) membership.
 *
 * If there is one membership only, it could be current, expired, or (very unlikely) future.
 *
 * TODO Rename `previousMembership` to `apiResponse`.
 * TODO Rename `identity` to `identityApiResponse`.
 */
export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    identity({ identity }) {
      const data = identity.identity._data
      return data.length > 0 ? data[0] : null
    },

    membershipId(state, { membership }) {
      return membership.scan_code
    },

    membershipLevelName(state, { oldestMembership }) {
      // Use the oldest membership to show the current/previous role.
      // TODO Use the highest ranking current/future membership based on role._rank.
      return oldestMembership.role
    },

    memberDetailsByType: function (state, { tickets }) {
      const byType = groupBy('ticket_type_id', tickets)
      return mapEntries(byType, (tickets) => tickets)
    },

    membershipExpiry(state, { memberships }) {
      if (memberships.length > 0) {
        return memberships[0].validTo
      }
    },

    identityWithMeta({ identity }) {
      return linkToMetadata('identity', identity.identity._data, identity.meta._data).map((i) => {
        if (i.meta.country) {
          // Members may have invalid country data due to being imported.
          i.meta.country = formatCountry(i.meta.country)
        }
        return i
      })
    },

    /**
     * Newest membership (furthest in the future). May be a current or expired membership.
     */
    membership(state, { memberships }) {
      if (memberships.length > 0) {
        return memberships[0]
      }
    },

    /**
     * Current or previous membership.
     *
     * May be a future membership if there are no current or expired memberships.
     */
    oldestMembership(state, { memberships }) {
      if (memberships.length > 0) {
        return memberships[memberships.length - 1]
      }
    },

    /**
     * @returns MembershipWithTimeObjects[]
     */
    memberships({ previousMembership }) {
      /** @type ApiResponse<'membership'> */
      const apiResponse = previousMembership
      return apiResponse.membership._data.map((membership) => ({
        ...membership,
        benefits: membership.member_event_purchase_limit,
        validTo: new TixTime(membership.valid_to, timezone),
        validFrom: new TixTime(membership.valid_from, timezone),
      }))
    },

    tickets({ previousMembership: apiResponse }, { membership }) {
      // Only include tickets from current membership.
      return apiResponse.ticket._data
        .filter((ticket) => ticket.ticket_order_id === membership.ticket_order_id)
        .filter((ticket) => ticket.state !== 'cancelled')
    },

    /**
     * @returns {TicketType[]}
     */
    ticketTypes({ previousMembership: apiResponse }) {
      return apiResponse.ticket_type._data
    },

    user({ identity }) {
      const data = identity.user._data
      return data.length > 0 ? data[0] : null
    },
  },

  mutations: {
    identity(state, identity) {
      state.identity = identity
    },

    previousMembership(state, previousMembership) {
      state.previousMembership = previousMembership
    },
  },

  actions: {
    clearAll(context) {
      // Do not clear legacy properties that are no longer supported.
      const state = defaultState()
      for (const key in state) {
        context.commit(key, state[key])
      }
    },

    load({ commit }) {
      // Includes a secondary call for identity meta embed.
      // TODO Are all these embeds still used and necessary?
      // TODO Merge these into one, using embeds?
      const fetches = [
        API.getUncached('my/membership', { embed: 'role, identity_link, ticket,ticket_order, ticket.ticket_type' }),
        API.getUncached(`my/user`, { embed: 'identity, identity.meta' }),
      ]

      return Promise.all(fetches).then(([membership, identity]) => {
        commit('previousMembership', membership)
        commit('identity', identity)
      })
    },

    updateUser({ commit, getters }, data) {
      return API.patch(`my/user/${getters.user.id}`, {
        body: data,
        embed: 'identity, identity.meta',
      }).then((response) => {
        commit('identity', response)
      })
    },

    login({ dispatch }, payload) {
      return API.post('login', { body: payload }).then(() => dispatch('load'))
    },
  },
}
