import type { CompletedOrdersResponse } from '@/checkout/helpers/completing'
import { environment } from '@/helpers/Environment'
import { groupBy, indexItemsById } from '@/helpers/IndexHelpers'
import { sum } from '@/helpers/MiscellaneousHelpers'
import type { CartPayload, GAEvent, ItemPayload, PurchasePayload, ViewItemPayload } from '@/types/ga4'

export function isGA4() {
  return environment.web.gtm_container_id
}

function triggerEvent(name: GAEvent, payload: Dict<any>) {
  if (isGA4()) {
    window.dataLayer.push({ event: name, ...payload })

    /**
     * Clearing `ecommerce` object. @see
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clearing_the_ecommerce_object
     *
     * Note that GA suggests we clear the object before triggering an event with
     * an ecommerce object. However, automatically triggered events like
     * `page_view` that are triggered afterwards still contain the last
     * ecommerce data so we try to clear ecommerce object after every event
     * trigger in the app code.
     */
    window.dataLayer.push({ ecommerce: null })
  }
}

export function triggerAddToCartEvent(payload: CartPayload) {
  triggerEvent('add_to_cart', { ecommerce: payload })
}

export function triggerRemoveFromCartEvent(payload: CartPayload) {
  triggerEvent('remove_from_cart', { ecommerce: payload })
}

export function triggerBeginCheckoutEvent(payload: CartPayload) {
  triggerEvent('begin_checkout', { ecommerce: payload })
}

export function triggerPurchaseEvents(payloads: Array<PurchasePayload>) {
  for (const payload of payloads) {
    triggerEvent('purchase', { ecommerce: payload })
  }
}

export function triggerViewItemEvent(payload: ViewItemPayload) {
  triggerEvent('view_item', { ecommerce: payload })
}

/**
 * @deprecated TODO Replace the last ~3 parameters with `entities: ReturnType<typeof apiEntities>`.
 * Callers can conveniently provide the value from VueX with apiEntities(store.state.Cart.apiResponse).
 */
export function getPurchasePayloads(
  currency: string,
  checkoutResponse: CompletedOrdersResponse,
  // entities: ReturnType<typeof apiEntities>,
  templates: EventTemplate[],
  types: TicketType[],
  cartmods: CartMod[],
): Array<PurchasePayload> {
  const payloads: Array<PurchasePayload> = []

  // Group all tickets by ticket order.
  const ticketsByOrder = groupBy('ticket_order_id', checkoutResponse.ticket._data)

  // Based on original portal string GTM integration for The Broad.
  for (const ticketOrder of checkoutResponse.ticket_order._data) {
    const tickets = ticketsByOrder[ticketOrder.id]

    payloads.push(
      getPurchasePayload(
        currency,
        ticketOrder.order_number,
        tickets,
        checkoutResponse.ticket_group._data,
        templates,
        types,
        cartmods,
      ),
    )
  }

  return payloads
}

export function getPendingPurchasePayloads(
  currency: string,
  transactionId: string,
  tickets: Ticket[],
  ticketGroups: TicketGroup[],
  templates: EventTemplate[],
  types: TicketType[],
  cartmods: CartMod[],
): Array<PurchasePayload> {
  return [getPurchasePayload(currency, transactionId, tickets, ticketGroups, templates, types, cartmods)]
}

function getPurchasePayload(
  currency: string,
  transactionId: string,
  tickets: Ticket[],
  ticketGroups: TicketGroup[],
  templates: EventTemplate[],
  types: TicketType[],
  cartmods: CartMod[],
): PurchasePayload {
  const items = getItemsPayload(
    tickets.map((t) => t.id),
    tickets,
    templates,
    types,
    ticketGroups,
    cartmods,
  )

  // TODO sumTicketPrices is available but it uses face_value instead
  const revenue = sum(tickets.map(({ adjusted_value }) => parseFloat(adjusted_value)))

  return {
    currency: currency,
    transaction_id: transactionId,
    value: revenue,
    // Use the first cartmod in the whole order for the 'Order Coupon' instead of the 'Product Coupon'
    coupon: cartmods.length ? cartmods[0].name : '',
    items,
  }
}

/**
 * @deprecated TODO Replace the last ~5 parameters with `entities: ReturnType<typeof apiEntities>`.
 * Callers can conveniently provide the value from VueX with apiEntities(store.state.Cart.apiResponse).
 */
export function getCartPayload(
  currency: string,
  ticketTypeIDs: string[],
  // entities: ReturnType<typeof apiEntities>,
  tickets: Ticket[],
  templates: EventTemplate[],
  types: TicketType[],
  ticketGroups: TicketGroup[],
  cartmods: CartMod[],
): CartPayload {
  const items = getItemsPayload(ticketTypeIDs, tickets, templates, types, ticketGroups, cartmods)
  const total = items.reduce((prev, item) => {
    return prev + (item.price ?? 0) * (item.quantity ?? 0)
  }, 0)

  return {
    currency,
    value: total,
    items,
  }
}

export function getEventPayload(event: EventTemplate): Pick<ItemPayload, 'item_id' | 'item_name' | 'item_category'> {
  return {
    item_id: event.id,
    item_name: event.name,
    item_category: event.category,
  }
}

/**
 * @deprecated TODO Replace the last ~4 parameters with `entities: ReturnType<typeof apiEntities>`.
 * Callers can conveniently provide the value from VueX with apiEntities(store.state.Cart.apiResponse).
 */
export function getItemsPayload(
  ticketIDs: string[],
  tickets: Ticket[],
  // entities: ReturnType<typeof apiEntities>,
  templates: EventTemplate[],
  types: TicketType[],
  ticketGroups: TicketGroup[],
  cartmods: CartMod[],
): Array<ItemPayload> {
  const eventTemplates = indexItemsById(templates)
  const ticketTypes = indexItemsById(types)
  const itemsMap: Dict<ItemPayload> = {}
  const groups = indexItemsById(ticketGroups)

  for (const ticketID of ticketIDs) {
    const ticket = tickets.find((t) => t.id === ticketID)
    if (!ticket) {
      continue
    }

    let item: ItemPayload | undefined = itemsMap[ticket.ticket_type_id]
    if (!item) {
      const eventTemplate = eventTemplates[ticket.event_template_id]
      // For 'Product Coupon', search cartmods which have affected this ticket. Only use the
      // first. Also use the cartmod name instead of the code which may activated it - it is
      // not easy to map a cartmod back to the code that activated it (if a code did, at all.)
      // This is still imperfect as only the first ticket of this ticket type in the order is
      // going to trigger the cartmod search.
      const cartmod = cartmods.find(({ tickets_affected }) => tickets_affected!.includes(ticket.id))
      const eventPayload = getEventPayload(eventTemplate)
      item = {
        ...eventPayload,
        item_category2: groups[ticket.ticket_group_id].name,
        item_category3: ticketTypes[ticket.ticket_type_id].name,
        item_category4: ticket.handler,
        price: 0,
        coupon: cartmod ? cartmod.name : '',
        quantity: 0,
        discount: Number(ticket.face_value) - Number(ticket.adjusted_value),
      }
      itemsMap[ticket.ticket_type_id] = item
    }
    // Collect the values of each purchased product to be used for an average
    item.price = (item.price ?? 0) + parseFloat(ticket.adjusted_value)
    if (!item.quantity) {
      item.quantity = 0
    }
    item.quantity++
  }

  // Use the values of the product map as the products array for GA
  const items = Object.values(itemsMap)

  // Calculate average prices
  items.forEach((tt: ItemPayload) => {
    tt.price = (tt.price ?? 0) / (tt.quantity ?? 1)
  })

  return items
}
