
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { DiscountedTG } from '@/api/types/processedEntities'
import RadioGroup from '@/components/forms/RadioGroup.vue'

export interface MembershipLevelHeading {
  label: string
  description: string
}

@Component({
  name: 'MembershipLevel',
  components: { RadioGroup },
})
export default class extends Vue {
  @Prop({ required: true })
  value: string

  @Prop({ required: true })
  ticketGroups: DiscountedTG[]

  @Prop()
  heading: MembershipLevelHeading | null

  get selected(): string {
    return this.value
  }

  set selected(value: string) {
    this.$emit('input', value)
  }

  created() {
    // Auto select if there's only one option
    if (this.levels.length === 1) {
      this.selected = this.levels[0].value as string
    }
  }

  get levels(): RadioGroupOption[] {
    return this.ticketGroups
      .filter((group) => group.handler === 'membership')
      .map((group) => ({
        value: group.id,
        label: group.name,
        price: group.price,
        originalPrice: group.originalPrice,
        description: group.description,
        summary: group.summary,
      }))
  }

  get label(): string {
    if (this.heading) {
      return this.heading.label
    } else {
      return this.$t('membershipLevel.membershipLevel') as string
    }
  }

  get caption(): string | undefined {
    return this.heading?.description
  }
}
