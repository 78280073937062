import type { ButtonTheme, ButtonType, WalletType } from '@/checkout/helpers/wallets'
import type { FormInputField } from '@/components/forms/types'
import ValidationError from '@/errors/ValidationError'
import { portal } from '@/helpers/Environment'
import { IdentityFormData } from '@/helpers/IdentityHelpers'
import { csvToArray, splitFullName } from '@/helpers/StringHelpers'
import type {
  PaymentMethod,
  PaymentRequest,
  PaymentRequestOptions,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestUpdateOptions,
} from '@stripe/stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { TrackJS } from 'trackjs'
import { addressFieldNames } from '../../helpers/AddressHelpers'

/**
 * Integrates digital wallets with identities and checkout, via Stripe's PaymentRequest button API.
 *
 * Exposes what type of wallet is supported. Stripe does the hard work here.
 *
 * @see https://stripe.com/docs/stripe-js/elements/payment-request-button
 *
 * Encapsulates most of the logic relating to payment with digital "wallets"; Google Pay, Apple Pay.
 *
 * Stripe abstracts them all into an API that appears to be compatible with the standard PaymentRequest API.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Payment_Request_API
 * @see https://stripe.com/docs/js/payment_request
 */
export default class StripeWalletPayment {
  /**
   * Resolves to the name of the type of wallet that this browser supports; 'none' if no wallet/card is configured.
   */
  enabled: Promise<WalletType>

  private readonly includePhoneNumber: boolean
  /**
   * @deprecated Stripe seems to provide the billing address all the time with Apple Pay.
   * TODO Confirm it is included reliably and use it instead of requesting a shipping address.
   */
  private readonly includeAddress: boolean

  private readonly paymentRequest: PaymentRequest
  private ev?: PaymentRequestPaymentMethodEvent

  constructor(private stripe: Stripe, initialAmount: number, identityFields: FormInputField[]) {
    const identityKeys = new Set(identityFields.map((field) => field.key))
    // TODO Only include required fields?
    this.includePhoneNumber = identityKeys.has('phone')
    this.includeAddress = addressFieldNames.some((name) => identityKeys.has(name))

    this.paymentRequest = this.initializeStripePaymentRequest(initialAmount)
    this.enabled = this.initializePaymentEnabledPromise(this.paymentRequest)
  }

  get identityFieldNames(): string[] {
    const result = csvToArray('first_name, last_name, email, email_confirm')
    if (this.includePhoneNumber) {
      result.push('phone')
    }
    if (this.includeAddress) {
      result.push(...addressFieldNames)
    }
    return result
  }

  get identityFormData(): IdentityFormData {
    const ev = this.ev
    if (!ev) {
      return {}
    }

    const [first, last] = splitFullName(ev.payerName!)

    const result: IdentityFormData = {
      first_name: first,
      last_name: last,
      // Email address is always requested
      email: ev.payerEmail!,
    }

    if (this.includePhoneNumber) {
      result.phone = ev.payerPhone!
    }

    if (this.includeAddress) {
      const address = ev.shippingAddress!
      // TODO Only include address fields that have corresponding identity fields?
      // TODO Are these properties always supplied by all platforms? The documentation suggests so.
      // @see https://stripe.com/docs/js/appendix/shipping_address
      result.address = address.addressLine!.join(', ')
      result.city = address.city!
      result.state = address.region!.toLowerCase()
      result.country = address.country!.toLowerCase()
      result.zip_code = address.postalCode!
    }

    return result
  }

  openPaymentDialog(finalAmount: number): Promise<PaymentMethod> {
    return new Promise((resolve, reject) => {
      const options: PaymentRequestUpdateOptions = this.paymentRequestOptions(finalAmount)

      // @see https://stripe.com/docs/js/payment_request/update
      this.paymentRequest.update(options)

      // This emits a warning;
      // "Do not call show() yourself if you are using the paymentRequestButton Element. The Element handles showing the payment sheet."
      //
      // That is because <PayButton> initializes the PaymentRequestButton with a click handler that _always_ calls
      // e.preventDefault()
      // @see https://github.com/stqry/tix-web-client/blob/1023aba/src/components/cart/PayButton.vue#L62
      // @see https://github.com/stripe-archive/react-stripe-elements/issues/310
      this.paymentRequest.show()

      this.paymentRequest.on('paymentmethod', (ev) => {
        this.ev = ev
        ev.complete('success')
        resolve(ev.paymentMethod)
      })

      this.paymentRequest.on('cancel', () => {
        // The user probably dismissed the payment dialog.
        reject(new ValidationError())
      })
    })
  }

  paymentRequestButton(theme: ButtonTheme, type: ButtonType) {
    const elements = this.stripe.elements()
    return elements.create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
      style: { paymentRequestButton: { theme, type, height: '48px' } },
    })
  }

  private initializeStripePaymentRequest(initialAmount: number) {
    const options: PaymentRequestOptions = {
      ...this.paymentRequestOptions(initialAmount),

      // This only works for some currencies, like US for USD and GB for GBP.
      // TODO How to get the country code?
      // From identity form? We need to load payment methods first.
      country: portal.default_currency_code.substring(0, 2),

      // Collect payer details to use as guest identity details. Also;
      // Stripe highly recommends collecting either name, email, or phone as this also
      // results in collection of billing address for Apple Pay. The billing address
      // can be used to perform address verification and block fraudulent payments.
      // @see https://stripe.com/docs/js/payment_request/create#stripe_payment_request-options-requestPayerName
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: this.includePhoneNumber,
      requestShipping: this.includeAddress,
      // A valid `shippingOptions` must be provided when `requestShipping` is `true`.
      // @see https://stripe.com/docs/js/payment_request/create#stripe_payment_request-options-requestShipping
      // Also, it must be empty or not present when `requestShipping` is `false` or an integration error will occur.
      // @see https://my.trackjs.com/details/2b453e43cd0f494ebcf2105dabd60e5c
      shippingOptions: this.includeAddress
        ? [
            {
              id: 'email',
              label: 'Email',
              detail: 'Your order will be sent by email.',
              amount: 0,
            },
          ]
        : [],
    }

    return this.stripe!.paymentRequest(options)
  }

  private paymentRequestOptions(totalAmount: number) {
    if (!Number.isFinite(totalAmount)) {
      // TODO Remove this once scenarios where it is not a number have been corrected.
      TrackJS?.track('class StripeWalletPayment requires that amount is a number')
    }

    return {
      currency: portal.default_currency_code.toLowerCase(),

      total: {
        label: `${portal.name} payment`,
        // Round due to floating point precision errors.
        // @see https://floating-point-gui.de/
        amount: Math.round(100 * totalAmount),
      },

      // Cart items can be shown in the digital wallet dialog. But this is probably a distraction from the payment UI.
      // displayItems: [
      //   { label: 'Entry, 2 Adults, 1 Child', amount: 2500, },
      // ],
    }
  }

  private initializePaymentEnabledPromise(paymentRequest: PaymentRequest): Promise<WalletType> {
    return paymentRequest.canMakePayment().then((value) => {
      if (value?.applePay) {
        return 'apple'
      } else if (value?.googlePay) {
        return 'google'
      } else {
        return 'none'
      }
    })
  }
}
