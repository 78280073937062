
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AddressSearchResult } from '@/helpers/AddressHelpers'

@Component({
  name: 'AutocompleteInput',
})
export default class extends Vue {
  @Prop({ default: [] })
  items: AddressSearchResult[] | null

  @Prop({ default: 'No matches found' })
  noMatchesText: string

  isOpen = false
  index: number | null = null

  mounted() {
    document.addEventListener('keydown', this.handleKeypress)
  }

  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress)
  }

  formattedItem(item: AddressSearchResult): string {
    return item.Text + item.Description
  }

  setResult(result) {
    this.$emit('input', result)
    this.isOpen = false
  }

  @Watch('items', { immediate: true })
  onChange() {
    this.isOpen = Boolean(this.items)
    this.index = null
  }

  handleClickOutside() {
    if (this.isOpen) {
      this.isOpen = false
      this.$emit('input', null)
    }
  }

  handleKeypress(event: KeyboardEvent) {
    if (this.isOpen) {
      if (event.key === 'Enter' && this.index !== null) {
        document.getElementById(`result-${this.index}`)?.click()
      } else if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
        if (this.index === null) {
          this.index = 0
        } else if (this.index < this.items!.length) {
          this.index++
        }
      } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
        if (this.index !== null && this.index > 0) {
          this.index--
        }
      } else {
        return
      }

      // Prevent default behavior for tab and arrow keys.
      event.preventDefault()
    }
  }

  @Watch('isOpen', { immediate: true })
  onOpen() {
    if (!this.isOpen) {
      this.index = null
    }
  }

  @Watch('index')
  onIndexChange() {
    if (this.isOpen && this.index !== null) {
      document.getElementById(`result-${this.index}`)?.focus()
    }
  }
}
