import type { FormInputField } from '@/components/forms/types'
import { environment } from '@/helpers/Environment'
import type { AutocompletedAddressData } from '@/helpers/IdentityHelpers'

// @see https://www.iban.com/country-codes
type CountryISO3 = string

// TODO Enable for more countries.
export const supportedCountries = new Set<CountryISO3>(['USA', 'GBR', 'CAN', 'AUS', 'NZL'])

export const addressFieldNames = ['address', 'city', 'state', 'zip_code', 'country']

export type LookupFunction = (input: string, country: CountryISO3) => Promise<any>

export interface AddressSearchResult {
  Description: string
  Highlight: string
  Id: string
  Text: string
  Type: string
}

export interface AddressDetailsResult {
  Line1: string
  Line2: string
  Line3: string
  Line4: string
  Line5: string
  City: string
  Province: string
  ProvinceCode: string
  PostalCode: string
  CountryIso2: string
}

type AddressResult = AddressSearchResult | AddressDetailsResult

export function getAddressAutocompleteApiKey(): string | undefined {
  return environment.config.loqate_api_key
}

export function alterAddressFieldsForAutocomplete(fields: FormInputField[]): FormInputField[] {
  const result = fields

  // Extract `address` separately as it should always come immediately after `country`.
  // The other address fields should be in their original order.
  const addressField = extractFields(result, ['address'])
  const otherAddressFields = extractFields(result, ['zip_code', 'city', 'state'])

  // Find the position of the country field.
  // This must be done after the splice above as that can change the position of the country field.
  const country = result.findIndex((field) => field.key === 'country')

  // Position the address fields immediately after the country field.
  result.splice(country + 1, 0, ...addressField, ...otherAddressFields)

  return result
}

function extractFields(fields: FormInputField[], extract: string[], result: FormInputField[] = []): FormInputField[] {
  const index = fields.findIndex((field) => extract.includes(field.key))
  if (index !== -1) {
    const formInputField = fields.splice(index, 1)[0]
    // ❗️Recursion!
    return extractFields(fields, extract, result.concat(formInputField))
  } else {
    return result
  }
}

export function addressSearch(
  country: string,
  search: { Text: string } | { Container: string },
): Promise<AddressSearchResult[]> {
  return loqateRequest('https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws', {
    Countries: country,
    ...search,
  })
}

export function retrieveAddress(id: string): Promise<AddressDetailsResult[]> {
  return loqateRequest('https://api.addressy.com/Capture/Interactive/Retrieve/v1.20/json3.ws', { Id: id })
}

function loqateRequest<T extends AddressResult>(url: string, params: Dictionary): Promise<T[]> {
  const key = getAddressAutocompleteApiKey()
  if (!key) {
    return Promise.reject('No Loqate API Key')
  }

  const urlWithParams = addParamsToUrl(url, {
    Key: key,
    ...params,
  })
  return fetch(urlWithParams, {
    method: 'GET',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  }).then((r) => handleLoqateResponse<T>(r))
}

function addParamsToUrl(url: string, params: Dictionary): string {
  if (Object.values(params).length === 0) {
    return url
  }

  const urlParams = Object.entries(params)
  return `${url}?${new URLSearchParams(urlParams)}`
}

function handleLoqateResponse<T extends AddressResult>(response: Response): Promise<T[]> {
  if (response.ok && response.status === 200) {
    return response.json().then((data) => {
      if (data.Items.length == 1 && typeof data.Items[0].Error != 'undefined') {
        throw data.Items[0]
      } else {
        return data.Items
      }
    })
  } else {
    throw response
  }
}

function addressLine(address: AddressDetailsResult) {
  return [address.Line1, address.Line2, address.Line3, address.Line4, address.Line5].filter((a) => a !== '').join(', ')
}

export function getAddressData(address: AddressDetailsResult): AutocompletedAddressData {
  return {
    address: addressLine(address),
    city: address.City,
    state: address.ProvinceCode && address.Province,
    zipCode: address.PostalCode,
  }
}
