import { environment } from '@/helpers/Environment'
import { buildQueryString, QueryParams } from '@/helpers/QueryStringParameters'
import { csvToArray } from '@/helpers/StringHelpers'
import axios, { AxiosRequestConfig } from 'axios'

export interface ApiArgs extends Partial<ArgsWithBody> {}

interface ArgsWithoutBody {
  embed?: string | string[]
  query?: QueryParams
  limit?: number
}

interface ArgsWithBody extends ArgsWithoutBody {
  body: Dict<any>
}

type Type = EntityTypeName

export const API = {
  getCached<T extends Type>(endpoint: string, args?: ArgsWithoutBody) {
    return request<T>('GET', endpoint, args, { retry: true, cached: true })
  },

  getUncached<T extends Type>(endpoint: string, args?: ArgsWithoutBody) {
    return request<T>('GET', endpoint, args, { retry: true, cached: false })
  },

  post<T extends Type>(endpoint: string, args?: ApiArgs) {
    return request<T>('POST', endpoint, args)
  },

  patch<T extends Type>(endpoint: string, args: ArgsWithBody) {
    return request<T>('PATCH', endpoint, args)
  },

  put<T extends Type>(endpoint: string, args: ArgsWithBody) {
    return request<T>('PUT', endpoint, args)
  },

  delete<T extends Type>(endpoint: string, args?: ApiArgs) {
    return request<T>('DELETE', endpoint, args)
  },
}

function request<T extends Type>(
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  endpoint: string,
  args?: ApiArgs,
  config?: {
    cached?: boolean
    retry?: boolean
  },
): Promise<ApiResponse<T>> {
  const retries = config?.retry ? 3 : 1
  return requestAndRetryWithAxios<T>(retries, 250, {
    method: method,
    url: buildUrl(endpoint, args, config?.cached, environment.seller?.id),
    data: args?.body,
    headers: {
      'Tix-App': 'ecomm',
    },
  })
}

export function buildUrl(endpoint: string, args?: ApiArgs, cached?: boolean, sellerId?: string) {
  const prefix = cached ? 'cached_api' : 'api'
  // Any part of the resource may be provided by the user. Encode all parts.
  const resource = endpoint.split('/').map(encodeURIComponent).join('/')
  const query = normalizeQueryParams(args, sellerId)
  const queryString = query.length > 0 ? `?${query}` : ''
  return '/' + prefix + '/' + resource + queryString
}

function normalizeQueryParams(args: ApiArgs = {}, sellerId?: string): string {
  const query = args.query ?? {}
  const embeds = args.embed ?? []
  const limit = args.limit ? String(args.limit) : undefined

  return buildQueryString({
    ...query,
    _seller: sellerId,
    // Convert string embeds to an array so that it is made unique and sorted.
    // This optimizes the URL for cache hits.
    _embed: Array.isArray(embeds) ? embeds : csvToArray(embeds),
    _limit: limit,
  })
}

function requestAndRetryWithAxios<T extends Type>(
  times: number,
  delay: number,
  config: AxiosRequestConfig,
): Promise<ApiResponse<T>> {
  return new Promise((resolve, reject) => {
    function attempt() {
      // TODO Reimplement with fetch() and remove axios. IE11 does not support fetch().
      axios
        .request(config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((e) => {
          times = times - 1

          if (times > 0) {
            // Retry if cancelled or a retryable status code.
            if (isCancelled(e) || e?.response?.status === 502) {
              setTimeout(attempt, delay)
              delay *= 2
              return
            }
          }

          reject(e)
        })
    }

    attempt()
  })
}

export function isCancelled(e): boolean {
  // Not an Axios error
  if (!e.isAxiosError) {
    return false
  }

  // Firefox, Chromium
  if (e?.code === 'ECONNABORTED') {
    return true
  }

  // Safari
  if (e?.message === 'Network Error') {
    return true
  }

  return false
}
