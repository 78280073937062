import '@/app/routeComponentHooks'
import { createVueApp } from '@/app/vueApp'
import { defaultLanguage, logTicketureVersion } from '@/helpers/Environment'
import { preloadPromoCodes } from '@/helpers/PreloadPromoCodes'
import { initializeTrackJS } from '@/helpers/TrackJS'
import { initializeStores } from '@/state/Initialization'
import { initializeThemeVariables } from '@/themeConfig/processing'
import { setLocale } from './TixTime/helpers'

// Expose TrackJS to window so it can be used externally by Ghost Inspector to add metadata.
window.TrackJS = initializeTrackJS()

if (window.Cypress && window.__mockStripeMethods && window.Stripe) {
  const origStripe = window.Stripe
  window.Stripe = (...args) => {
    return {
      ...origStripe(...args),
      ...window.__mockStripeMethods,
    }
  }
}

setLocale(defaultLanguage)

initializeStores()

const urlSearchParams = new URLSearchParams(window.location.search)
window.tixAppState = {
  queryParams: Object.fromEntries(urlSearchParams.entries()),
}

// Consume discount codes injected with the `code` query string parameter or cookie.
preloadPromoCodes()

// Set custom CSS properties in the document <head>.
initializeThemeVariables(window.tix.theme)

logTicketureVersion()

const app = createVueApp()

// Expose app to Cypress for test cases.
if (window.Cypress) {
  window['vueApp'] = app
}

export default app
