<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.0509 9.20722L6.78812 13.9457C6.91084 14.0686 7.05658 14.1661 7.21699 14.2326C7.37741 14.2991 7.54936 14.3333 7.72301 14.3333C7.89666 14.3333 8.06861 14.2991 8.22903 14.2326C8.38944 14.1661 8.53518 14.0686 8.6579 13.9457L14.3333 8.27539V1.66666H7.72631L2.0509 7.34356C1.80479 7.59121 1.66665 7.92621 1.66665 8.27539C1.66665 8.62458 1.80479 8.95958 2.0509 9.20722V9.20722Z"
      stroke="#525A6C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M11 5H10.9914" stroke="#525A6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
  import { Component } from 'vue-property-decorator'
  import BaseIcon from './BaseIcon.vue'

  @Component({})
  export default class extends BaseIcon {}
</script>
