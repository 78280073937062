import { API } from '@/api/API'
import { apiEntities } from '@/api/Helpers'
import type { EventDetails } from '@/api/types/processedEntities'
import type { TimeInterval } from '@/helpers/Intervals'
import { normalizeSession, normalizeSessions } from '@/helpers/SessionHelpers'
import { quantitiesPayload } from '@/helpers/TicketTypeQuantities'
import { TixTime } from '@/TixTime/TixTime'
import type { Session, SessionsAndPriceSchedules } from '@/types/Sessions'

export function fetchFirstAvailableSession(event: EventDetails, tgIds?: string[]): Promise<Session | null> {
  const args = {
    limit: 1,
    query: {
      sold_out: 'F',
      _sort: 'start_datetime',
    },
  }

  if (tgIds) {
    args.query['ticket_group_id._in'] = tgIds.join(',')
  }

  return API.getCached<'event_session'>(`events/${event.id}/sessions`, args).then((response) => {
    if (response.event_session._data.length < 1) return null
    else return normalizeSession(response.event_session._data[0], event.venue)
  })
}

function fetchNormalizedSessions(event: EventDetails, params: Dictionary): Promise<Session[]> {
  return API.getCached<'event_session'>(`events/${event.id}/sessions`, { query: params }).then((response) => {
    return normalizeSessions(response.event_session._data, event.venue)
  })
}

export function fetchSessions(event: EventDetails, dates: TixTime | TimeInterval, tgIds: string[]): Promise<Session[]> {
  const params = {
    'ticket_group.id._in': tgIds.join(','),
    ...toQueryParams(dates),
  }

  return fetchNormalizedSessions(event, params)
}

function isDateRange(dates: TixTime | TimeInterval): dates is TimeInterval {
  const interval = dates as TimeInterval
  return interval.start != undefined && interval.end != undefined
}

function toQueryParams(
  dates: TixTime | TimeInterval,
): { _ondate: string } | { 'start_datetime._gte': string; 'start_datetime._lt': string } {
  if (isDateRange(dates)) {
    return {
      'start_datetime._gte': dates.start.format('iso'),
      'start_datetime._lt': dates.end.format('iso'),
    }
  } else {
    return {
      // Prefer event_session.start_datetime filters over _ondate. The API response includes;
      //   - All untimed events, such as memberships
      //   - Timed events that have a session in the specified range
      //   - Sessions that start in the specified range and finish the day after. E.g. 11pm to 1am.
      // TODO Support a configurable threshold for day's end. E.g. 3am instead of 12am. Meow Wolf wants this.
      // @see fetchAvailableEvents()
      _ondate: dates.format('DATE'),
    }
  }
}

export function fetchSessionsAndPrices(
  event: EventDetails,
  dates: TixTime | TimeInterval,
  quantities: TicketTypeQuantities,
): Promise<SessionsAndPriceSchedules> {
  const args = {
    body: quantitiesPayload(quantities),
    query: {
      _sort: 'start_datetime',
      _include_sold_out: true,
      ...toQueryParams(dates),
    },
  }

  return API.post<'event_session' | 'price_schedule'>(`events/${event.id}/sessions`, args).then((response) => {
    return {
      sessions: normalizeSessions(response.event_session._data, event.venue),
      // price_schedule is only absent when ticket_types_required is empty/absent.
      // @see https://tixtrackteam.slack.com/archives/C05RQRM9JUA/p1708569368573789
      priceSchedules: response.price_schedule._data,
    }
  })
}

export function fetchSessionsAvailability(event: EventDetails): Promise<boolean> {
  const args = {
    limit: 1,
    query: {
      sold_out: 'F',
    },
  }

  return API.getUncached<'event_session'>(`events/${event.id}/sessions`, args).then((response) => {
    return apiEntities(response).event_session.length > 0
  })
}
