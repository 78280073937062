import { API, ApiArgs } from '@/api/API'
import type { ApplyCodePayload } from '@/api/types/payloads'
import type { QueryParams } from '@/helpers/QueryStringParameters'

export const fetchCartEmbeds =
  'identity,ticket,ticket.ticket_type,ticket.ticket_group,ticket_group.meta,ticket.event_session,ticket.event_template,event_template.venue,event_template.config,event_template.meta'
export type CartApiResponse = ApiResponse<
  | 'cart'
  | 'cart_fees'
  | 'cartmod'
  | 'event_session'
  | 'event_template'
  | 'identity'
  | 'meta'
  | 'ticket'
  | 'ticket_group'
  | 'ticket_type'
  | 'venue'
  | 'config'
>

export function fetchOwnCart(id: string): Promise<CartApiResponse> {
  return API.getUncached(`my/cart/${id}`, cartApiParams())
}

export function fetchCart(id: string, query?: QueryParams): Promise<CartApiResponse> {
  return API.getUncached(`cart/${id}`, cartApiParams(query))
}

export function applyCartCodes(id: string, body: ApplyCodePayload) {
  return API.post(`my/cart/${id}/apply_code`, { body, ...cartApiParams() })
}

export function cartApiParams(query?: QueryParams): ApiArgs {
  return {
    limit: 1000,
    embed: fetchCartEmbeds,
    query: {
      ...query,
      // TODO Why do we need the session_picker_tabs configuration for cart data?
      'config.key._in': 'config.image,web.session_picker_tabs',
    },
  }
}
