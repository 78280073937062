import type { EventWithConfig, MetaData } from '@/api/types/processedEntities'
import type { TixImage } from '@/helpers/ImageHelpers'
import { TixTime } from '@/TixTime/TixTime'

export interface CartItemType {
  type: TicketType
  name: string
  ticketPrice: number
  ticketCount: number
  totalPrice: number
  group: TicketGroup
}

export type CartItem = TimedItem | UntimedItem

export function isTimedItem(item: CartItem): item is TimedItem {
  return Boolean((item as TimedItem).sessionId)
}

export interface TimedItem extends BaseItem {
  sessionId: string
  startTime: TixTime
  endTime: TixTime
  allDay: boolean
}

export interface UntimedItem extends BaseItem {
  validTo: TixTime
  validFrom: TixTime
  allDay: false
}

interface BaseItem {
  event: EventWithConfig

  /**
   * @deprecated Use event.id
   */
  templateId: string

  /**
   * @deprecated Use event.name
   */
  name: string

  /**
   * @deprecated Use event.meta
   */
  meta: MetaData

  image: TixImage

  faceValue: number
  isAnchor: boolean
  isDependency: boolean
  isMembershipEvent: boolean
  ticketIds: string[]
  // TODO Include a middle level for ticket groups for session-cart items that include
  // ticket types from multiple different ticket groups? Maybe;
  //   1. `.types[].group` should be in a top level property of the cart item; `.groups`.
  //   2. Rename `.types` to something more general. Maybe "line items"?
  //   2. Remove `.types[].id`, or abstract it to a generic identifier.
  types: CartItemType[]
}
