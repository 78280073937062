
import FormInput2 from '@/components/forms/FormInput2.vue'
import PromoCodesModal from '@/modals/PromoCodesModal.vue'
import { openModal } from '@/modals/helpers/api'
import store from '@/store/store'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'ApplyPromoCodesCheckout',
  components: { FormInput2 },
})
export default class extends Vue {
  @Prop({ default: false })
  submitDisabled: boolean

  rawValue: string = ''
  applying: boolean = false
  error: string | null = null

  get value() {
    return this.rawValue.trim()
  }

  get appliedCodes() {
    return store.getters['Cart/cartMods']
  }

  openPromoCodeModal() {
    openModal({
      name: 'promo-codes-modal',
      component: PromoCodesModal,
      title: this.$t('applyPromoCodes.applyPromoCodeLabel') as string,
      props: { submitDisabled: this.submitDisabled },
    })
  }

  @Watch('submitDisabled')
  updateModal() {
    this.$modals.$emit('update:modal', {
      name: 'promo-codes-modal',
      props: { submitDisabled: this.submitDisabled },
    })
  }
}
