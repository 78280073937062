import { API } from '@/api/API'
import { apiEntities } from '@/api/Helpers'
import { indexItemsById } from '@/helpers/IndexHelpers'

export interface MembershipRules {
  rules: MembershipRuleEntity[]
  ticketTypes: TicketType[]
  indexedTypes: Dict<TicketType>
}

export function getMembershipRules(): Promise<MembershipRules> {
  return API.getCached<'membership_rules' | 'ticket_type'>('membership_rules', {
    embed: 'ticket_type',
    limit: 1000,
  }).then((response) => {
    const entities = apiEntities(response)
    return {
      rules: entities.membership_rules,
      ticketTypes: entities.ticket_type,
      indexedTypes: indexItemsById(entities.ticket_type),
    }
  })
}
