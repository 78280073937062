import type { EventWithConfig, LinkedTG, LinkedTT } from '@/api/types/processedEntities'
import { cartChangesToPayload } from '@/helpers/CartHelpers'
import type { CartChanges, ModifyCartPayload } from '@/helpers/Reserve'

export default class CartChangesNormalizer {
  private readonly ticketsById: Dict<Ticket>
  private readonly typesById: Dict<LinkedTT>
  private readonly groupsById: Dict<LinkedTG>
  private readonly eventsById: Dict<EventWithConfig>

  constructor(private tickets: Ticket[], private ticketGroups: LinkedTG[], private eventTemplates: EventWithConfig[]) {
    const eventTTs = ticketGroups.flatMap((g) => g.types)
    this.ticketsById = this.indexById(tickets)
    this.typesById = this.indexById(eventTTs)
    this.groupsById = this.indexById(ticketGroups)
    this.eventsById = this.indexById(eventTemplates)
  }

  normalize(changes: CartChanges, preloadedCodes?: string[]): ModifyCartPayload {
    if (preloadedCodes) {
      changes.preloadedPromoCodes = preloadedCodes
    }

    const result = changes.ignoreFeeTickets ? changes : this.normalizeFeeTickets(changes)

    return cartChangesToPayload(result)
  }

  private normalizeFeeTickets(changes: CartChanges): CartChanges {
    // Remove fee-tickets from the add & remove payloads to make this idempotent.
    const result = this.filterOutFeeTickets(changes)

    // Find the required fee TTs that for the non-fee tickets that will be reserved when this transaction completes.
    const required = new Set(this.requiredFeeTicketTypesIDs(result))

    // Find the fee TTs that are already in the cart.
    const current = this.currentFeeTicketTypesIDs()

    // Add tickets to reserve payload for fee TTs that are in $required but not $current.
    for (const type of Array.from(required)) {
      if (!current[type]) {
        result.add!.push({ ticket_type_id: type })
      }
    }

    // Add tickets to remove payload for fee TTs that are in $current but not $required
    for (const type in current) {
      if (!required.has(type)) {
        const ticketId = current[type]
        result.remove!.push(ticketId)
      }
    }

    return result
  }

  private requiredFeeTicketTypesIDs(changes: CartChanges): string[] {
    const remove = new Set(changes.remove)
    const inCart: TicketType[] = this.tickets
      .filter((ticket) => !remove.has(ticket.id))
      .map((ticket) => this.typesById[ticket.ticket_type_id])

    const inPayload: TicketType[] = changes.add?.map((ticket) => this.typesById[ticket.ticket_type_id]) ?? []

    return [...inCart, ...inPayload]
      .filter((type) => !this.isFeeGroup(type.ticket_group_id))
      .map((type) => this.getFeeTicketType(type.ticket_group_id))
      .filter((type) => type != undefined)
  }

  private currentFeeTicketTypesIDs(): Dictionary {
    const result: Dictionary = {}

    for (const ticket of this.tickets) {
      const type = this.typesById[ticket.ticket_type_id]
      if (this.isFeeGroup(type.ticket_group_id)) {
        if (result[type.id]) {
          throw new Error(`Multiple tickets for fee ticket type "${type.name}" (${type.id}) were found`)
        }
        result[type.id] = ticket.id
      }
    }

    return result
  }

  private getFeeTicketType(groupID: string): string {
    const group = this.groupsById[groupID]
    const event = this.eventsById[group.event_template_id]
    // Support for per-order fees were implemented via meta.fee_ticket_type_id.
    // The feature is disabled temporarily by renaming the expected metadata key.
    // TODO Either restore or remove support for per-order fee tickets in the year 2025.
    // @see https://tixtrack.atlassian.net/browse/TIC-2433
    return group.meta.obsolete_msichicago_fee_ticket_type_id ?? event.meta.obsolete_msichicago_fee_ticket_type_id
  }

  private isFeeGroup(ticketGroupID: string): boolean {
    return this.groupsById[ticketGroupID].handler === 'fee'
  }

  private isFeeTicket(ticketID: string): boolean {
    return this.isFeeGroup(this.ticketsById[ticketID].ticket_group_id)
  }

  private isFeeType(typeID: string): boolean {
    const type = this.typesById[typeID]
    if (!type) {
      throw new Error(
        `Ticket type ID ${typeID} is missing. CartChanges.event is required if CartChanges.add is not empty.`,
      )
    }
    return this.isFeeGroup(type.ticket_group_id)
  }

  private filterOutFeeTickets(changes: CartChanges): CartChanges {
    const result = { ...changes }
    result.add = changes.add?.filter((ticket) => !this.isFeeType(ticket.ticket_type_id)) ?? []
    result.remove = changes.remove?.filter((ticket) => !this.isFeeTicket(ticket)) ?? []
    return result
  }

  private indexById<T extends ApiEntity>(items: T[]): Dict<T> {
    // This is like indexItemsById(), except that it doesn't complain about duplicate entities.
    const result: Dict<T> = {}
    for (const item of items) {
      result[item.id] = item
    }
    return result
  }
}
