
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormInput from '@/components/forms/FormInput2.vue'
import SelectDate from '@/components/events/SelectDate.vue'
import { TixTime } from '@/TixTime/TixTime'
import type { EventDetails } from '@/api/types/processedEntities'
import DateSelector from '@/components/elements/DateSelector.vue'
import { languageItem } from '@/language/helpers'
import type { CalendarApiResponse } from '@/api/calendar'
import type { LanguageStrings } from '@/language/types'

// TODO Use TixDate here instead of TixTime.
export type DateAnyOrNull = TixTime | 'any' | null

/**
 *                  |                            value                                              |
 *                  |-------------------------------------------------------------------------------|
 *                  |  null                 | TixTime                   | 'any'                     |
 * ---------|-------|-----------------------|---------------------------|---------------------------|
 *          | false | Any Day & Single Date | N/a                       | Admission passes selected |
 *          |       | buttons unselected    |                           | ("Change" button visible) |
 * showDate |-------|-----------------------|---------------------------|---------------------------|
 *          | true  | Single Date button    | Date selected             | N/a                       |
 *          |       | is selected           | ("Change" button visible) |                           |
 *
 * There are 4 different UI states depending on the values of `value` and `showDates` (2 combinations are invalid)
 *
 * 1. "Any Day" & "Single Date" buttons are both unselected
 * 2. "Single Date" button is selected
 *    i. <SelectDate> is visible
 *    ii. "Any Day" button is visible but unselected
 * 3. Date Selected
 *    i. "Change" button shown
 *    ii. "Any Day" & "Single Date" buttons not visible
 *    iii. RDF will show <SelectSession>
 * 4. Admission Passes selected
 *    i. "Change" button shown
 *    ii. "Any Day" & "Single Date" buttons not visible
 *    iii. RDF will show <SelectQuantities>
 */
@Component({
  components: { DateSelector, SelectDate, FormInput },
})
export default class extends Vue {
  @Prop({ required: true })
  value: DateAnyOrNull

  @Prop({ required: true })
  apiResponse: CalendarApiResponse

  @Prop({ required: true })
  event: EventDetails

  @Prop({ required: true })
  showPrices: boolean

  showDates: boolean = false

  created() {
    // If the user is buying an upsell we default to the same date as the ticket in the cart.
    // Set `this.showDates` to true so that the "Change" button in the <SelectDate> component for the date is shown.
    if (this.value instanceof TixTime) {
      this.showDates = true
    }
  }

  get showButtons() {
    return this.value === null
  }

  get isSingle() {
    return this.value !== 'any' && this.showDates
  }

  get isAny() {
    return this.value === 'any'
  }

  selectDate(date: TixTime) {
    this.$emit('input', date)
  }

  useFlexibleTickets(value: boolean) {
    this.showDates = !value
    this.$emit('input', value ? 'any' : null)
  }

  reset() {
    this.showDates = false
    this.$emit('input', null)
  }

  get l(): LanguageStrings['reserveDateFirst'] {
    return languageItem('reserveDateFirst')
  }
}
