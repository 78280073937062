import { IterableDict } from '@/helpers/IterableDict'
import { prefixedKeys } from '@/helpers/QueryStringParameters'

export function iterableDict<T>(data: Dict<T>) {
  return new IterableDict(Object.entries(data))
}

/**
 * TODO Replace uses of Object.entries() ... fromPairs() with this.
 */
export function mapEntries<T, U>(data: Dict<T>, callback: (value: T, key: string) => U): Dict<U> {
  return iterableDict(data).map(callback).toObject()
}

/**
 * TODO Replace uses of Object.entries() ... fromPairs() with this.
 */
export function filterEntries<T>(data: Dict<T>, callback: (value: T, key: string) => boolean): Dict<T> {
  return iterableDict(data).filter(callback).toObject()
}

export function filterNamespacedProperties(prefix: string, data: Dictionary): Dictionary {
  // TODO Use filterEntries and simplify;
  // const keys = new Set(prefixedKeys(prefix, data))
  // return filterEntries(data, (value, key) => keys.has(key))

  const result = {}

  prefixedKeys(prefix, data).forEach((key) => {
    const name = key.slice(prefix.length)
    result[name] = data[key]
  })

  return result
}

export function dictToMap<T, K>(data: Dict<T>, keys: Dict<K>): Map<K, T> {
  const entries = Object.entries(data).map(([id, value]) => {
    return [keys[id], value] as [K, T]
  })

  return new Map(entries)
}
