import { getCustomContentBlocks } from '@/helpers/Config'
import { configYml } from '@/helpers/Environment'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { mapGetters } from 'vuex'

/**
 * @deprecated Use portal strings
 *
 * Renders tenant-specific content for a specified region.
 *
 * Content is configured in tenant config as `customContent`. Tenant-specific content is a Vue template.
 *
 * Global data variables from plugins are available to templates. E.g. $portalString.
 *
 * SECURITY; Templates could be used to exploit viewers by injecting scripts or stealing data. Only trusted templates
 * should be permitted. Even though templates can be restricted to admin staff in the CMS, it should still be sanitized.
 *
 * Scripts and styles are intentionally unsupported. This allows templates enough power to use basic Vue components like
 * <router-link>, but not enough to make them unpredictable or difficult to reason about. Too much flexibility would
 * make it impossible to assure code quality deterministically and maintain confidence in test coverage.
 *
 * Prohibited in templates;
 *  - Scripts
 *  - Use of arbitrary high-level components
 *  - Function calls †
 *  - Setting variables †
 *  - Emitting events †
 *
 * † These can not easily be prevented.
 */
@Component({
  name: 'TenantContent',
  computed: mapGetters({
    user: 'Member/user',
  }),
})
export default class extends Vue {
  @Prop({ required: true })
  region: string

  customContent = getCustomContentBlocks(configYml?.customContent)

  private user
  private children: any[] = []

  mounted() {
    this.updateChildren()
  }

  @Watch('user')
  onLogin() {
    this.updateChildren()
  }

  @Watch('$route')
  onRouteChange(to, from) {
    this.updateChildren(to)
  }

  private updateChildren(route?: Route) {
    const blocks = this.customContent[this.region] || []
    this.children = this.getChildren(blocks, this.user, route || this.$router.currentRoute)
  }

  // TODO Move to a helper and add tests.
  private getChildren(blocks: CustomContentBlock[], user, route: Route) {
    return blocks
      .filter((block) => {
        if (route.name) {
          if (block.enabledRoutes) {
            return block.enabledRoutes.includes(route.name)
          } else if (block.disabledRoutes) {
            return !block.disabledRoutes.includes(route.name)
          } else {
            // Block is always visible if neither disabledRoutes nor enabledRoutes are configured.
            return true
          }
        }
      })
      .filter((block) => {
        // TODO Add support for other roles, like member, active-member, expired-member, staff.
        if (block.enabledRoles) {
          if (block.enabledRoles.includes('anonymous')) {
            return !user
          }
        } else if (block.disabledRoles) {
          if (block.disabledRoles.includes('anonymous')) {
            return Boolean(user)
          }
        } else {
          // Enable the block if there are no rules about roles.
          return true
        }
      })
      .map((block) => ({
        component: Vue.component(block.key, { template: block.template }),
        classes: 'block ' + block.key,
      }))
  }

  // @see https://vuejs.org/v2/guide/render-function.html
  render(createElement) {
    if (this.children.length > 0) {
      const attributes = { class: 'region ' + this.region }
      return createElement(
        'div',
        attributes,
        this.children.map((child) => {
          return createElement(child.component, { class: child.classes })
        }),
      )
    }
  }
}
