import { currentMembership } from '@/state/Membership'

export function availableTicketGroups(ticketGroups) {
  const groups = ticketGroups.filter((group) => group.types.length > 0)

  if (currentMembership()) {
    return groups
  } else {
    return groups.filter((group) => group.hidden_type !== 'public_member_only')
  }
  return []
}
