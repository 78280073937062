
import { openModal } from '@/modals/helpers/api'
import { deleteCart } from '@/state/Cart'
import store from '@/store/store'
import { Component, Prop, Vue } from 'vue-property-decorator'

export function openDeleteCartOrCheckoutDialog(options: { title: string; description: string }) {
  return new Promise<boolean>((resolve) => {
    openModal({
      name: 'delete-cart-or-checkout-modal',
      title: options.title,
      component: DeleteCartOrCheckoutDialog,
      props: options,
      size: 'md',
      closeable: false,
      onClose: () => resolve(true),
    })
  })
}

@Component({ name: 'DeleteCartOrCheckoutDialog' })
export default class DeleteCartOrCheckoutDialog extends Vue {
  @Prop()
  description: string

  processing = false

  deleteCart() {
    this.processing = true
    deleteCart(store.getters['Cart/cart'].id).then(() => {
      this.processing = false
      this.close()
    })
  }

  checkout() {
    this.$router.push('/checkout')
    this.close()
  }

  close() {
    this.$modals.close({ name: 'delete-cart-or-checkout-modal' })
  }
}
